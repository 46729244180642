import { useState, useEffect } from "react";
import useNotify from "hooks/useNotify";
import profileApi from "api/profile";
import { scrollIntoView } from "utils/helper";

function useBankDetails({ userDetails = {}, profileCompletionRef = {} }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditBankDetails, setIsEditBankDetails] = useState(false);

  const paypalDataRef = {
    paypal: userDetails?.bankDetails?.emailOrPhoneNumber ?? "",
  };

  const bankDetsailsDataRef = {
    companyName: userDetails?.bankDetails?.companyName ?? "",
    accountName: userDetails?.bankDetails?.accountName ?? "",
    bankName: userDetails?.bankDetails?.bankName ?? "",
    accountNumber: userDetails?.bankDetails?.accountNumber ?? "",
    accountCurrency: userDetails?.bankDetails?.accountCurrency ?? "",
    swiftCode: userDetails?.bankDetails?.swiftCode ?? "",
    iban: userDetails?.bankDetails?.iban ?? "",
    bankAddress: userDetails?.bankDetails?.bankAddress ?? "",
    routingCode: userDetails?.bankDetails?.routingCode ?? "",
    sortCode: userDetails?.bankDetails?.sortCode ?? "",
  };

  const cashExpressDataRef = {
    fullName: userDetails?.bankDetails?.fullName ?? "",
    cashExpressPhoneNumber:
      userDetails?.bankDetails?.cashExpressPhoneNumber ?? "",
  };

  const profileCompletionFinDetailsData = Object.values(paypalDataRef).some(
    (el) => !!el
  )
    ? paypalDataRef
    : Object.values(cashExpressDataRef).some((el) => !!el)
    ? cashExpressDataRef
    : bankDetsailsDataRef;

  profileCompletionRef.agencyBankDetails = {
    openEdit: setIsEditBankDetails,
    ...profileCompletionFinDetailsData,
    preferredCurrency: userDetails?.currency?.id,
  };

  const { notifyError, notifySuccess } = useNotify();

  const onEditBankDetails = async (values, setUserData = () => {}) => {
    try {
      const bankDetails = {
        companyName: values?.companyName || undefined,
        accountName: values?.accountName || undefined,
        bankName: values?.bankName || undefined,
        accountNumber: values?.accountNumber || undefined,
        accountCurrency: values?.accountCurrency || undefined,
        swiftCode: values?.swiftCode || undefined,
        iban: values?.iban || undefined,
        bankAddress: values?.bankAddress || undefined,
        routingCode: values?.routingCode || undefined,
        sortCode: values?.sortCode || undefined,
      };

      const paypalDetails = {
        emailOrPhoneNumber: values?.paypal || undefined,
      };

      const cashExpress = {
        fullName: values?.fullName || undefined,
        cashExpressPhoneNumber: values?.cashExpressPhoneNumber || undefined,
      };

      setIsLoading(true);
      const payload = {
        bankDetails,
        preferredCurrency: values?.preferredCurrency || undefined,
        paypalDetails,
        cashExpress,
      };
      await profileApi.updateAgencyBankDetails(userDetails?.agencyId, payload);
      const userData = await profileApi.getUserProfileByIdApi(userDetails?.id);
      setUserData(userData);
      setIsEditBankDetails(false);
      notifySuccess("Has been updated successfully");
      scrollIntoView("bankDetailsScrollId");
    } catch (err) {
      console.error(err);
      notifyError(err?.data?.error ?? err?.message ?? "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  const resetOnUnmount = () => {};
  useEffect(() => {
    return () => {
      resetOnUnmount();
    };
  }, []);

  return {
    isEditBankDetails,
    setIsEditBankDetails,
    onEditBankDetails,
    isBankDetailsLoading: isLoading,
  };
}

export default useBankDetails;
