/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  defaultVendorValues,
  defaultAgencyValues,
  steps,
} from "pages/signup/constants";
import authApi from "api/auth";
import useNotify from "hooks/useNotify";
import { useNavigate, useParams } from "react-router-dom";

function useSignup() {
  const [t] = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [accountType, setAccountType] = useState(params.accountType || null);
  const { notifyError, notifySuccess } = useNotify();

  const isVendor = accountType === t("vendorFreelancer");

  const defaultsValues = () => {
    if (!steps.includes(params.accountType)) {
      return {};
    }
    const commonDefaultValues = {
      email: "",
      country: "",
      timezone: "",
      phoneNumber: "",
      serviceRates: [],
      services: [],
      availability: {
        days: [],
        time: {
          from: "",
          to: "",
        },
      },

      certifications: [],
      domains: [],
      subDomains: [],
      city: "",
    };

    return params.accountType === t("vendorFreelancer")
      ? { ...commonDefaultValues, ...defaultVendorValues }
      : { ...commonDefaultValues, ...defaultAgencyValues };
  };

  const resetOnUnmount = () => {
    setAccountType(null);
  };

  const onSubmit = async ({
    /** Common values */
    email = "",
    country = "",
    timezone = "",
    phoneNumber = "",
    serviceRates = [],
    services = [],
    availability = {},
    certifications = [],
    domains = [],
    subDomains = [],
    city = "",

    /** Vendor values */
    firstName = "",
    lastName = "",
    resume = "",
    yearsOfExperience = "",
    isWillingToTravel = true,
    vendorAdditionalInfo = { profile: [] },
    sampleOfWorks = [],
    nationalities = [],

    // Agency values
    companyName = "",
    representativeName = "",
    rateCard = [],
    companyWebsite = "",
    portfolio = [],
  }) => {
    try {
      setIsLoading(true);
      const mainInfo = isVendor
        ? {
            firstName,
            lastName,
          }
        : { companyName, representativeName };

      const additionalInfo = isVendor
        ? {
            resume,
            vendorAdditionalInfo: {
              ...vendorAdditionalInfo,
              profile: vendorAdditionalInfo.profile.filter(Boolean),
            },
          }
        : {
            portfolio: portfolio?.map?.((file) => file.fileId) ?? [],
            companyWebsite,
          };

      const ternaryInfo = isVendor
        ? {
            yearsOfExperience,
            sampleOfWorks: sampleOfWorks?.map?.((file) => file.fileId) ?? [],
            isWillingToTravel,
            nationalities,
          }
        : {
            rateCard: rateCard?.map?.((file) => file.fileId) ?? [],
          };

      const data = {
        mainInfo: { email, ...mainInfo },
        additionalInfo: {
          country,
          timezone,
          phoneNumber,
          serviceRates,
          services,
          availability,
          ...additionalInfo,
        },
        ternaryInfo: {
          city,
          domains,
          subDomains,
          certifications: certifications?.map?.((file) => file.fileId) ?? [],
          ...ternaryInfo,
        },
      };
      isVendor
        ? await authApi.vendorRegisteredApi(data)
        : await authApi.agencyRegisteredApi(data);

      notifySuccess(t("emailSetPass"));
      navigate("/login");
    } catch (err) {
      console.error(err);
      notifyError(
        err?.data?.message ?? err?.message ?? "Something went wrong!"
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      resetOnUnmount();
    };
  }, []);

  return {
    onSubmit,
    isLoading,
    accountType,
    setAccountType,
    defaultsValues: defaultsValues(),
    isVendor,
  };
}

export default useSignup;
