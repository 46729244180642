import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { publicRoutes } from "utils/constantValues";
import { useAppContext } from "hooks/useContext";

// axios instance
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "",
  withCredentials: true,
});

const createCancelRequest = () => {
  const cancelTokenSource = axios.CancelToken.source();
  return Promise.resolve(cancelTokenSource);
};

const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate();
  const { resetUser } = useAppContext();

  useEffect(() => {
    const resInterceptor = (response) => {
      console.info("Response interceptor");
      return response.data;
    };

    const errInterceptor = (error) => {
      if (error.response && [401, 403].includes(error.response.status)) {
        const path = window.location.pathname.split("/")[1];
        if (!publicRoutes.includes(path)) {
          resetUser();
          navigate("/login");
        }
      }
      return Promise.reject(error.response);
    };

    const interceptor = instance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    return () => instance.interceptors.response.eject(interceptor);
  }, []);

  return children;
};

export default instance;
export { AxiosInterceptor, createCancelRequest };
