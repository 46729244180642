import { useState, useEffect } from "react";
import { certificationType } from "utils/constantValues";
import useNotify from "hooks/useNotify";

import profileApi from "api/profile";
import { scrollIntoView } from "utils/helper";

function useProfileCertifications({
  userDetails = {},
  profileCompletionRef = {},
}) {
  const currentCertificationIds =
    userDetails?.userFiles
      ?.filter((file) => file.type === certificationType)
      ?.map((file) => file.id) ?? [];

  const { notifyError, notifySuccess } = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditCertificationFiles, setIsEditCertificationFiles] =
    useState(false);

  profileCompletionRef.certification = {
    openEdit: setIsEditCertificationFiles,
    certifications: currentCertificationIds,
  };

  const onEditCertificationsFiles = async (values, setUserData = () => {}) => {
    try {
      setIsLoading(true);
      const vendorId = userDetails?.vendorId;
      const agencyId = userDetails?.agencyId;
      const { newCreated, deleted } = values;

      const deletedIds = deleted?.map((el) => el.fileId) ?? [];

      const payload = {
        newCreated:
          newCreated
            ?.filter(
              (el) =>
                !deletedIds.includes(el.fileId) &&
                !currentCertificationIds.includes(el.fileId)
            )
            ?.map((el) => el.fileId) ?? [],
        deleted: deletedIds,
      };

      if (vendorId) {
        await profileApi.updateVendorCertificationApi(vendorId, payload);
      } else {
        await profileApi.updateAgencyFiles(agencyId, {
          ...payload,
          type: certificationType,
        });
      }

      const userData = await profileApi.getUserProfileByIdApi(userDetails?.id);
      setUserData(userData);
      setIsEditCertificationFiles(false);
      notifySuccess("Has been updated successfully");

      scrollIntoView("certificationScrollId");
    } catch (err) {
      console.error(err);
      notifyError(err?.data?.error ?? err?.message ?? "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  const resetOnUnmount = () => {};
  useEffect(() => {
    return () => {
      resetOnUnmount();
    };
  }, []);

  return {
    isEditCertificationFiles,
    setIsEditCertificationFiles,
    onEditCertificationsFiles,
    isCertificationLoading: isLoading,
  };
}

export default useProfileCertifications;
