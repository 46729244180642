import { SET_USER, IS_AUTH_USER, REST_USER } from "context/types";

export const initialState = {
  user: {},
  isAuth: false,
};

const AppReducer = (state, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action?.payload ?? {},
      };

    case IS_AUTH_USER:
      return {
        ...state,
        isAuth: action?.isAuth ?? false,
      };

    case REST_USER:
      return {
        user: {},
        isAuth: false,
      };

    default:
      return state;
  }
};

export default AppReducer;
