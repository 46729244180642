import { useLookupsContext } from "hooks/useContext";
import { useState, useEffect } from "react";

function usePersonalDetailsForm({
  defaultDateOfBirth = null,
  defaultExpiryDate = null,
}) {
  const [dateOfBirth, setDateOfBirth] = useState(
    defaultDateOfBirth ? new Date(defaultDateOfBirth) : null
  );
  const [expiryDate, setExpiryDate] = useState(
    defaultExpiryDate ? new Date(defaultExpiryDate) : null
  );

  const GENDER_OPTIONS = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];

  const MATERIAL_STATUS_OPTIONS = [
    { label: "Single", value: "Single" },
    { label: "Married", value: "Married" },
    { label: "Divorced", value: "Divorced" },
    { label: "Widowed", value: "Widowed" },
    { label: "Engaged", value: "Engaged" },
  ];

  const { countries } = useLookupsContext(false);

  const countriesOptions = countries.map((elem) => ({
    ...elem,
    label: elem.name,
    value: elem.id,
  }));

  const resetOnUnmount = () => {
    setDateOfBirth(null);
    setExpiryDate(null);
  };
  useEffect(() => {
    return () => {
      resetOnUnmount();
    };
  }, []);

  return {
    GENDER_OPTIONS,
    MATERIAL_STATUS_OPTIONS,
    dateOfBirth,
    setDateOfBirth,
    countriesOptions,
    expiryDate,
    setExpiryDate,
  };
}

export default usePersonalDetailsForm;
