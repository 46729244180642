import React from "react";
import PropTypes from "prop-types";

function AccountTypeCard({
  label,
  title,
  subTitle,
  icon,
  isActive,
  onClick = () => {},
}) {
  return (
    <div
      className={`flex py-[10px] pr-[14px] pl-[20px] items-center gap-[20px] min-w-[330px] self-stretch rounded-[6px] cursor-pointer ${
        isActive ? "" : "border-[1px] border-solid border-default-color"
      }`}
      style={isActive ? { background: "rgba(235, 249, 255, 0.50)" } : {}}
      onClick={onClick}
    >
      {icon && <img alt="icon" src={icon} />}
      <div className="flex flex-col gap-[4px]">
        {label && (
          <div className="capitalize self-stretch font-bold text-[18px] text-light-primary not-italic">
            {label}
          </div>
        )}
        {title && (
          <div className="self-stretch font-medium text-[16px] text-light-primary not-italic">
            {title}
          </div>
        )}

        {subTitle && (
          <div className="self-stretch font-regular text-[12px] text-light-primary not-italic">
            {subTitle}
          </div>
        )}
      </div>
    </div>
  );
}

AccountTypeCard.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  isActive: PropTypes.bool,
  icon: PropTypes.any,
  onClick: PropTypes.func,
};

export default AccountTypeCard;
