import { useState } from "react";
import authApi from "api/auth";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "./useContext";

function useLogout() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { resetUser } = useAppContext();

  const onLogoutSubmit = async () => {
    try {
      setIsLoading(true);
      await authApi.logout();
      resetUser();
      navigate("/login");
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  return { onLogoutSubmit, isLoading };
}

export default useLogout;
