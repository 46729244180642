import axiosInstance from "api/setupAxios";

const lookupsApi = {
  getCountriesApi: async () => {
    const { data } = await axiosInstance.get("/lookups/countries");
    return data;
  },

  getCitiesApi: async (countryId) => {
    const { data } = await axiosInstance.get(
      `/lookups/country/${countryId}/cities`
    );
    return data;
  },
  getDomainsApi: async () => {
    const { data } = await axiosInstance.get("/lookups/domains");
    return data;
  },

  getSubDomains: async (domainIds = []) => {
    const query = {
      filter: JSON.stringify({
        op: "and",
        value: [{ op: "in", key: "domain_id", value: [...domainIds] }],
      }),
    };
    const { data } = await axiosInstance.get(`/lookups/subDomains`, {
      params: {
        ...query,
      },
    });
    return data;
  },

  getLanguagesAPi: async () => {
    const { data } = await axiosInstance.get("/lookups/languages");
    return data;
  },

  getNationalitiesApi: async () => {
    const { data } = await axiosInstance.get("/lookups/nationalities");
    return data;
  },

  getServicesApi: async () => {
    const { data } = await axiosInstance.get("/lookups/services");
    return data;
  },
  getTimezonesApi: async (countryId) => {
    const { data } = await axiosInstance.get(
      `/lookups/country/${countryId}/timezones`
    );
    return data;
  },

  getCurrencies: async () => {
    const response = await axiosInstance.get(`/lookups/currency`);
    return response;
  },
};

export default lookupsApi;
