import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import BGShutterstock from "images/auth/bg-shutterstock.png";
import { ReactComponent as TVendorIcon } from "images/auth/t-vendors.svg";
import useNotify from "hooks/useNotify";
import TypeCard from "./typeCard";

function AccountType({
  steps = [],
  stepsContent = [],
  setAccountType = () => {},
  accountType,
}) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { notifyError } = useNotify();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="w-full h-full bg-no-repeat flex-1 bg-cover min-h-[calc(100%-50px)] bg-center flex items-center justify-center md-max:justify-start  py-[60px] px-[120px] md-max:px-[1rem]"
      style={{
        backgroundImage: `url(${BGShutterstock})`,
      }}
    >
      <div
        className="w-full max-w-[700px] md-max:max-w-full h-full min-h-[650px] md-max:max-h-full  rounded-[20px] flex-shrink-0 flex items-center justify-center p-[1rem]"
        style={{ background: "rgb(2,23,51, 0.65)" }}
      >
        <div className="flex flex-col items-start gap-[40px] w-[330px]">
          <div className="flex flex-col items-start gap-[10px]">
            <TVendorIcon />
            <div className="text-white font-medium text-[36px] not-italic font-normal">
              {t("signUpTitle")}
            </div>
          </div>

          <div className="flex flex-col gap-[18px] self-stretch">
            <div className="text-white opacity-[0.65] font-light text-[18px] not-italic">
              {t("chooseAccountType")}
            </div>
            {steps.map((stepLabel, index) => {
              return (
                <Fragment key={stepLabel}>
                  <TypeCard
                    label={stepLabel}
                    title={stepsContent[index].title}
                    subTitle={stepsContent[index].subTitle}
                    icon={stepsContent[index].icon}
                    onClick={() => setAccountType(stepLabel)}
                    isActive={accountType === stepLabel}
                  />
                </Fragment>
              );
            })}
            <button
              className="flex py-[18px] px-[20px] justify-center items-center gap-[8px] rounded-[6px] border-[1px] border-solid border-light-primary bg-light-primary h-[48px] mt-[12px]"
              type="button"
              onClick={() => {
                if (accountType) {
                  navigate(`/signup/${accountType}`);
                } else {
                  notifyError(t("chooseAccountTypeError"));
                }
              }}
            >
              <div className="text-primary font-bold text-[18px] not-italic font-normal">
                {t("continueCta")}
              </div>
            </button>
            <div className="text-white text-center text-[18px] font-regular not-italic font-normal opacity-[0.65]">
              {t("or")}{" "}
              <span
                className="text-secondary font-bold text-[18px] underline not-italic font-normal cursor-pointer"
                onClick={() => navigate("/login")}
              >
                {t("login")}
              </span>{" "}
              {t("existAccountExtension")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

AccountType.propTypes = {
  steps: PropTypes.array,
  stepsContent: PropTypes.array,
  setAccountType: PropTypes.func,
  accountType: PropTypes.string,
};

export default AccountType;
