import { isValidEmail } from "../utils/helper";
import { isNumber } from "utils/isNumber";
import { useLookupsContext } from "./useContext";

import {
  LANGUAGE_MODEL_NONE,
  LANGUAGE_MODEL_PAIR,
  LANGUAGE_MODEL_SINGLE,
} from "utils/constantValues";
export default function useCountFilledFields(isVendor) {
  const checkValidationKeys = ["email"];

  const excludeFields = ["serviceRates"];

  const { services } = useLookupsContext(isVendor);

  const handleCountWithRate = (serviceRate = {}) => {
    let count = 0;
    let rate = Number(serviceRate.rate);
    if (!rate || (isNumber(rate) && rate === 0)) {
      return 0;
    } else {
      return (count += 1);
    }
  };

  const handleCountWithCapacity = (serviceRate = {}) => {
    let count = 0;
    let capacity = Number(serviceRate.capacity);
    if (!capacity || (isNumber(capacity) && capacity === 0)) {
      return 0;
    } else {
      return (count += 1);
    }
  };

  const handleCountSingleLanguage = (serviceRate = {}) => {
    let count = 0;
    if (serviceRate.toLanguage || serviceRate?.toLang?.id) {
      count += 1;
    }

    return count;
  };

  const handleCountWithPairLanguages = (serviceRate = {}) => {
    let count = 0;

    if (serviceRate.fromLanguage || serviceRate?.fromLang?.id) {
      count += 1;
    }

    if (serviceRate.toLanguage || serviceRate?.toLang?.id) {
      count += 1;
    }

    return count;
  };

  const handleServiceLogic = (serviceId, serviceRates = []) => {
    let count = 0;

    if (!serviceId || serviceRates.length <= 0) {
      return 0;
    }

    const service = services.find((elem) => elem.id === serviceId);
    const serviceRateId = service?.type[0]?.id;
    const serviceRate = serviceRates.find(
      (elem) => elem.rateId === serviceRateId || elem.id === serviceRateId
    );

    let isPair = service?.languageModel === LANGUAGE_MODEL_PAIR;
    let isSingle = service?.languageModel === LANGUAGE_MODEL_SINGLE;
    let hasLanguages = service?.languageModel !== LANGUAGE_MODEL_NONE;
    let withCapacity = service?.withCapacity;
    let withRate = service?.withRate;

    if (!hasLanguages && !withRate && !withCapacity) {
      return 4; //all of them!
    }

    if (isPair) {
      count += handleCountWithPairLanguages(serviceRate);
    }

    if (isSingle) {
      count += handleCountSingleLanguage(serviceRate);
    }

    if (withCapacity) {
      count += handleCountWithCapacity(serviceRate);
    }
    if (withRate) {
      let rateCount = handleCountWithRate(serviceRate);

      let calcRate =
        rateCount <= 0
          ? rateCount
          : !hasLanguages
          ? (rateCount += 2)
          : isSingle
          ? (rateCount += 1)
          : !withCapacity
          ? (rateCount += 1)
          : rateCount;
      count += calcRate;
    }

    return count;
  };

  const handleAllFields = (fields = {}) => {
    let results = [];

    for (const [fieldName, field] of Object.entries(fields)) {
      if (excludeFields.includes(fieldName)) {
        continue;
      }
      if (field && typeof field === "object" && !Array.isArray(field)) {
        for (const [key, el] of Object.entries(field)) {
          if (
            el &&
            typeof el === "object" &&
            !Array.isArray(el) &&
            el.hasOwnProperty("from")
          ) {
            results.push({ timeFrom: el?.from });
            results.push({ timeTo: el?.to });
          } else {
            results.push({ [key]: el });
          }
        }
      } else {
        results.push({ [fieldName]: field || "" });
      }
    }

    return results;
  };

  function countFilledFields(fields = [], serviceRates = []) {
    let count = 0;
    const isFilled = (key, value) => {
      if (typeof value === "boolean") {
        return true; // Count booleans
      }
      if (typeof value === "number" && !Number.isNaN(Number(value))) {
        // Count number even if its 0
        return true;
      }
      if (typeof value === "string") {
        if (key === "email")
          return checkValidationKeys.includes(key)
            ? isValidEmail(value)
            : !!value; // Check strings
      }
      if (Array.isArray(value)) {
        return value.filter((el) => !!el).length > 0;
      }
      if (typeof value === "object" && value) {
        return Object.values(value).some((val) => isFilled(val));
      }

      return !!value || false;
    };

    fields.forEach((element) => {
      for (const [fieldName, field] of Object.entries(element)) {
        if (fieldName === "services") {
          const increaseCountService = handleServiceLogic(
            field[0]?.id ? field[0]?.id : field[0],
            serviceRates
          );

          count += increaseCountService;
        } else if (isFilled(fieldName, field)) {
          count++;
        }
      }
    });

    return count;
  }

  const calcFilledPercentages = (numberOfFilledFields = 0, totalFields) => {
    return (numberOfFilledFields / totalFields) * 100;
  };
  return { countFilledFields, calcFilledPercentages, handleAllFields };
}
