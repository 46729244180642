import React from "react";
import { useTranslation } from "react-i18next";
import Input from "components/input";
import PropTypes from "prop-types";
import useAgencyContacts from "./useAgencyContacts";
import { ReactComponent as AddCircleIcon } from "images/auth/add-circle.svg";
import { ReactComponent as TrashIcon } from "images/auth/trash.svg";
import { validateEmail } from "utils/helper";

function AgencyContactsForm({
  register = () => {},
  unregister = () => {},
  setValue = () => {},
  errors = {},
  watch = () => {},
  getValues = () => {},
  title = "",
  containerClassName = "flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]",
}) {
  const [t] = useTranslation();

  const defaultContacts = watch("contacts");
  const {
    agencyContacts,
    onHandleAgencyContacts,
    addNewSection,
    deleteSection,
  } = useAgencyContacts({ defaultContacts });

  return (
    <div className={containerClassName}>
      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        <div className="text-primary font-semibold text-[30px] not-italic">
          {title || t("contactDetailsTitle")}
        </div>
      </div>

      <div className="flex flex-wrap gap-[20px]">
        {agencyContacts.map((contact, index) => {
          return (
            <div className="grid w-full gap-[10px]" key={`${index}`}>
              <div className="grid grid-cols-2 sm-max:grid-cols-1  w-full gap-[16px] rounded-[12px] border-[1px] border-solid border-default-color p-[20px]">
                <Input
                  label={t("name")}
                  isRequired={true}
                  placeholder={t("placeholderAgency", {
                    title: t("name").toLowerCase(),
                  })}
                  name={`name-${index}`}
                  value={contact.name}
                  onChange={(e) => {
                    const { value } = e.target;
                    onHandleAgencyContacts("name", value, index, setValue);
                  }}
                  register={register}
                  registerArgs={{
                    required: !contact.name
                      ? t("errorField", { field: t("name") })
                      : false,
                  }}
                  errorMessage={
                    errors[`name-${index}`] && !contact.name
                      ? errors[`name-${index}`].message
                      : ""
                  }
                  containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
                  labelClassName="text-primary font-semibold text-[16px]"
                  inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
                    errors[`name-${index}`] && !contact.name
                      ? "border-red"
                      : "border-default-color"
                  } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
                />

                <Input
                  label={t("email")}
                  isRequired={true}
                  placeholder={t("placeholderAgency", {
                    title: t("email").toLowerCase(),
                  })}
                  name={`email-${index}`}
                  type={"email"}
                  value={contact.email}
                  onChange={(e) => {
                    const { value } = e.target;
                    onHandleAgencyContacts("email", value, index, setValue);
                  }}
                  register={register}
                  registerArgs={{
                    required: !contact.email
                      ? t("errorField", {
                          field: t("email"),
                        })
                      : false,
                    validate: validateEmail,
                  }}
                  errorMessage={
                    errors[`email-${index}`] && validateEmail(contact.email)
                      ? errors[`email-${index}`].message
                      : ""
                  }
                  containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
                  labelClassName="text-primary font-semibold text-[16px]"
                  inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
                    errors[`email-${index}`] && !contact.email
                      ? "border-red"
                      : "border-default-color"
                  } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
                />

                <div className="grid w-full gap-[5px]">
                  <Input
                    label={t("department")}
                    isRequired={true}
                    placeholder={t("placeholderAgency", {
                      title: t("department").toLowerCase(),
                    })}
                    name={`department-${index}`}
                    value={contact.department}
                    onChange={(e) => {
                      const { value } = e.target;
                      onHandleAgencyContacts(
                        "department",
                        value,
                        index,
                        setValue
                      );
                    }}
                    register={register}
                    registerArgs={{
                      required: contact.department
                        ? false
                        : t("errorField", {
                            field: t("department"),
                          }),
                    }}
                    errorMessage={
                      errors[`department-${index}`] && !contact.department
                        ? errors[`department-${index}`].message
                        : ""
                    }
                    containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
                    labelClassName="text-primary font-semibold text-[16px]"
                    inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
                      errors[`department-${index}`] && !contact.department
                        ? "border-red"
                        : "border-default-color"
                    } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
                  />

                  {index >= 1 && (
                    <div className="grid mt-[10px]">
                      <div
                        className="flex gap-[8px] cursor-pointer"
                        onClick={() => {
                          deleteSection(index, setValue);
                        }}
                      >
                        <TrashIcon />
                        <div className="text-primary font-bold text-[16px] not-italic mt-[2px]">
                          {t("removeSections")}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <Input
                  label={t("position")}
                  isRequired={true}
                  placeholder={t("placeholderAgency", {
                    title: t("position").toLowerCase(),
                  })}
                  name={`position-${index}`}
                  value={contact.position}
                  onChange={(e) => {
                    const { value } = e.target;
                    onHandleAgencyContacts("position", value, index, setValue);
                  }}
                  register={register}
                  registerArgs={{
                    required: contact.position
                      ? false
                      : t("errorField", { field: t("position") }),
                  }}
                  errorMessage={
                    errors[`position-${index}`] && !contact.position
                      ? errors[`position-${index}`].message
                      : ""
                  }
                  containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
                  labelClassName="text-primary font-semibold text-[16px]"
                  inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
                    errors[`position-${index}`] && !contact.position
                      ? "border-red"
                      : "border-default-color"
                  } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
                />
              </div>

              {index === agencyContacts.length - 1 && (
                <div className="grid mt-[10px]">
                  <div
                    className="flex gap-[8px] cursor-pointer"
                    onClick={() => {
                      ["name", "email", "department", "position"].map((el) =>
                        unregister(`el-${index}`)
                      );

                      addNewSection(setValue);
                    }}
                  >
                    <AddCircleIcon />
                    <div className="text-primary font-bold text-[16px] not-italic">
                      {t("addNewContacts")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
AgencyContactsForm.propTypes = {
  register: PropTypes.func,
  unregister: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  watch: PropTypes.func,
  getValues: PropTypes.func,
  title: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default AgencyContactsForm;
