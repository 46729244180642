import profileApi from "api/profile";
import { useEffect, useState, useCallback } from "react";
import { useAppContext, useLookupsContext } from "hooks/useContext";

function useProfile() {
  const [initLoading, setInitLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [userDomains, setUserDomains] = useState([]);
  const [userSubDomains, setUserSubDomains] = useState([]);

  const { user } = useAppContext();
  const { getCurrencies } = useLookupsContext(
    userDetails.vendorId || userDetails.agencyId
  );
  const userId = user?.id;

  const fetchUserDetails = useCallback(async () => {
    try {
      setInitLoading(true);
      const userData = await profileApi.getUserProfileByIdApi(userId);
      const domains = await profileApi.getUserDomainsByIdApi(userId);
      const subDomains = await profileApi.getUserSubDomainsByIdApi(userId);
      setUserDomains(domains);
      setUserSubDomains(subDomains);
      setUserDetails(userData);
    } catch (err) {
      console.error(err);
      setUserDetails({});
      setUserDomains([]);
      setUserSubDomains([]);
    } finally {
      setInitLoading(false);
    }
  }, [userId]); // need to add the status in future

  const resetOnUnmount = () => {
    setUserDetails({});
    setUserDomains([]);
    setUserSubDomains([]);
  };

  useEffect(() => {
    if (userId) {
      fetchUserDetails();
      getCurrencies();
    }
  }, [userId]);

  useEffect(() => {
    return () => {
      resetOnUnmount();
    };
  }, []);

  return {
    initLoading,
    userDetails,
    setUserDetails,
    userDomains,
    setUserDomains,
    userSubDomains,
    setUserSubDomains,
  };
}

export default useProfile;
