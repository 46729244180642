import React from "react";
import PropTypes from "prop-types";
function Input({
  label = "",
  isRequired = false,
  type = "text",
  placeholder = "",
  errorMessage = "",
  name = "",
  register = () => {},
  registerArgs = {},
  containerClassName = "",
  labelClassName = "",
  inputClassName = "",
  autoFocus = false,
  inputMode,
  value = "",
  onChange,
  min = 0,
  step = 0.001,
}) {
  const handleWheel = (event) => {
    //disable the increase and/or decrease of the value in an input type="number" element when scrolling
    event.target.blur();
  };
  const inputModeProps = inputMode ? { inputMode } : {};
  const valueProps =
    value || onChange ? { value, onChange, onWheel: handleWheel } : {};
  const minValueProps = !Number.isNaN(Number(min)) ? { min } : {};
  const stepValueProps = step ? { step } : {};

  return (
    <div
      className={
        containerClassName
          ? containerClassName
          : "flex flex-col sm-max:grid sm-max:w-full gap-[10px]"
      }
    >
      {label && (
        <div
          className={
            labelClassName
              ? labelClassName
              : "text-white font-semibold text-[16px] not-italic font-normal"
          }
        >
          {label} {isRequired && <span className="text-red">*</span>}
        </div>
      )}
      <input
        autoFocus={autoFocus}
        name={name}
        {...register(name, { ...registerArgs })}
        {...inputModeProps}
        {...valueProps}
        {...minValueProps}
        {...stepValueProps}
        className={
          inputClassName
            ? inputClassName
            : `flex h-[43px] items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
                errorMessage && isRequired
                  ? "border-red"
                  : "border-default-color"
              } border-default-color bg-transparent outline-none text-white font-regular text-[16px] not-italic font-normal  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px] opacity-[0.65]`
        }
        placeholder={placeholder}
        type={type}
      />
      {errorMessage && isRequired && (
        <span className="text-[12px] text-red font-regular">
          {errorMessage}
        </span>
      )}
    </div>
  );
}

Input.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  register: PropTypes.func,
  registerArgs: PropTypes.object,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  autoFocus: PropTypes.bool,
  inputMode: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  min: PropTypes.number,
  step: PropTypes.number,
};

export default Input;
