import React from "react";
import PropTypes from "prop-types";
import LabelComponent from "pages/profile/common/label";
import { ReactComponent as EditIcon } from "images/profile/edit-icon.svg";
import { useTranslation } from "react-i18next";

function Qualifications({
  userDetails = {},
  onEditClick = () => {},
  isShowEditIcon = false,
}) {
  const [t] = useTranslation();

  return (
    <div className="flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]">
      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        <div className="text-primary font-semibold text-[30px] not-italic">
          {t("qualificationsTitle")}
        </div>
        {isShowEditIcon && (
          <EditIcon className="cursor-pointer" onClick={onEditClick} />
        )}
      </div>

      <div className="flex flex-wrap gap-[20px]">
        <LabelComponent
          title={t("university")}
          subTitle={`${
            userDetails?.extraInformation?.qualifications?.university ?? "-"
          }`}
        />

        <LabelComponent
          title={t("qualifications")}
          subTitle={`${
            userDetails?.extraInformation?.qualifications?.qualification ?? "-"
          }`}
        />
        <LabelComponent
          title={t("major")}
          subTitle={`${
            userDetails?.extraInformation?.qualifications?.major ?? "-"
          }`}
        />
      </div>
    </div>
  );
}

Qualifications.propTypes = {
  userDetails: PropTypes.object,
  onEditClick: PropTypes.func,
  isShowEditIcon: PropTypes.bool,
};

export default Qualifications;
