export const authentication = {
  loginTitle: "Login to your account",
  emailTitle: "Email address",
  errorField: "{{field}} is required",
  placeholder: "Enter your {{field}}",
  passwordTitle: "Password",
  errorMinCharacter: "{{field}} must be at least {{value}} characters",
  forgerPasswordTitle: "Forget password?",
  loginSubmit: "Login",
  or: "or",
  signUp: "Sign Up",
  login: "Login",
  newAccountExtension: "with a new account",
  existAccountExtension: "with you account",
  forgetPasswordTitle: "Forget password",
  forgetPasswordSubTitle:
    "Please enter the email address registered on your account",

  send: "Send",

  resetPasswordTitle: "Reset password",
  setPasswordTitle: "Set your password",
  newPasswordTitle: "New password",
  newPasswordPlaceholder: "Write your new password",
  confirmPasswordPlaceholder: "Rewrite your new password",
  confirmPassword: "Confirm password",
  confirmPassErrorMsg: "Your confirmed password does not match",
  restCta: "Reset",
  setPasswordCta: "Confirm",
  signUpTitle: "Create a new account",
  continueCta: "Continue",
  chooseAccountType: "Choose your account type",
  agencyType: "agency",
  vendorFreelancer: "freelancer",
  vendorTitle: "I wont to work",
  vendorSubTitle:
    "Connect with clients & work on exciting freelance projects that match your skill.",
  agencyTitle: "I wont to hire",
  agencySubTitle:
    "Tab into a global talent pool of highly specialized freelance talent on demand.",
  invalidEmail: "Invalid email address",
  emailResetPass: "Password reset link was sent to your email",
  passSetted: "You can now login with the new password",
  sameFileName: "File was already uploaded, please choose another file",
  cvAllowedFiles: "Only pdf or docs is allowed",

  serviceTitle: "You rate(s) in USD for {{serviceTitle}}",
  serviceTypeSubTitle: "Please specify your rate in USD",
  word: "Word",
  character: "Character",

  "subTitle-per word": "Please specify your rate in USD/{{isCharacter}}",
  "subTitle-per hour": "Please specify your rate in USD/Hour",
  "subTitle-per page": "Please specify your rate in USD/Page",
  "subTitle-per slide": "Please specify your rate in USD/Slide",
  "subTitle-per minute": "Please specify your rate in USD/minute",
  "subTitle-online": "Your hourly rate for Online Interpreting in USD",
  "subTitle-on-site": "Your hourly rate for On-Site Interpreting in USD",
  "subTitle-on-online half a day":
    "Your rate for Online Interpreting for half a day (4h) in USD",
  "subTitle-on-site half a day":
    "Your rate for Online Interpreting for half a day (4h) in USD",
  "subTitle-online full day":
    "Your rate for Online Interpreting for a full day (8h) in USD",
  "subTitle-on site full day":
    "Your rate for On-Site Interpreting for a full day (8h) in USD",
  "subTitle-overtime online":
    "Overtime hourly rate for Online Interpreting in USD",
  "subTitle-overtime on-site":
    "Overtime hourly rate for On-Site Interpreting in USD ",

  "rate-per word": "Your rate in USD/{{isCharacter}}",
  "rate-per hour": "Your rate in USD/Hour",
  "rate-per page": "Your rate in USD/Page",
  "rate-per slide": "Your rate in USD/Slide",
  "rate-per minute": "Your rate in USD/minute",

  "per page": "Your capacity (page/day)",
  "per word": "Your capacity ({{isCharacter}}/day)",
  "per words": "Your capacity (words/day)",
  "per hour": "Your capacity (hour/day)",
  "per minutes": "Your capacity (minutes/day)",
  "per minute": "Your capacity (minute/day)",
  "per slide": "Your capacity (slide/day)",
  "/Day": "/Day",
  successLoginMessage: "Logged in successfully",
  chooseAccountTypeError: "Please select your account type",
  firstName: "First name",
  startJourneyLabel: "Start your Journey",
  placeHolderType: "Type your {{title}}",
  lastName: "Last name",
  yourCvLabel: "Your CV",
  attachUrCv: "Attach your CV",
  personalInformationLabel: "Personal information",
  country: "Country",
  city: "City",
  timeZone: "Timezone",
  placeholderSelect: "Select your {{field}}",
  phoneNumber: "Phone number",
  nationality: "Nationality",
  domains: "Domains",
  timeFromTitle: "Available time (From)",
  timeToTitle: "Available time (To)",
  ProfileLinkTitle: "Relevant profile links",
  addAnotherProfile: " Add another profile",
  sampleOfWorkTitle: "Sample of works",
  attachYourFiles: "Attach your file(s)",
  attachYourFile: "Attach your file",
  experienceTitle: "Experience",
  languages: "Languages",
  languagesPlaceholder: "Select your language(s)",
  yearsOfExperienceTitle: "Years of experience",
  willingToTravel: "Willing to travel",
  certificationsTitle: "Certifications",
  uploadCertification: "Upload certificates",
  applyingFor: "Applying for",
  servicesProvided: "Services provided",
  submit: "Submit",
  companyName: "Company name",
  representativeName: "Representative name",
  companyWebsite: "Company website",
  companyWebsitePlaceholder: "Website URL",
  rateCard: "Rate card",
  portfolio: "Portfolio",
  emailSetPass: "Link was sent to your email to set your password",
  Copyediting: "Copy Editing",
  availableDaysTitle: "Available days",
  languageFrom: "From language",
  languageTo: "To language",
  addAnotherLanguage: " Add another language",

  rateDisclaimer:
    "Disclaimer: The rate provided is higher than the industry average.",
  subDomains: "Sub domains",
  resume: "CV",
  cookieTitle: "Our website uses cookies",
  cookieMessage:
    "By clicking “Accept” you consent to the storage of cookies on your device to improve website navigation, analyze website usage, and support our marketing initiatives, as outlined in our",
  privacyNotice: "Privacy Notice",

  cookieCta: "Accept",
  userAlreadyLoggedInError:
    "You are already logged in, Kindly sign out first, then try again, this browser tab will be closed in {{countdown}}",
  maxLengthDomainsError: "Maximum number of domains should not exceed 5",

  maximumNumberLanguageError:
    "Maximum number of language pairs should not exceed 10",
};
