import { useState, useEffect } from "react";
import { rateCardType } from "utils/constantValues";
import useNotify from "hooks/useNotify";
import profileApi from "api/profile";
import { scrollIntoView } from "utils/helper";

function useRateCardFile({ userDetails = {}, profileCompletionRef = {} }) {
  const currentRateCardIds =
    userDetails?.userFiles
      ?.filter((file) => file.type === rateCardType)
      ?.map((file) => file.id) ?? [];

  const { notifyError, notifySuccess } = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditRateCard, setIsEditRateCard] = useState(false);

  profileCompletionRef.rateCard = {
    openEdit: setIsEditRateCard,
    rateCard: currentRateCardIds,
  };
  const onEditRateCardFile = async (values, setUserData = () => {}) => {
    try {
      setIsLoading(true);
      const agencyId = userDetails?.agencyId;
      const { newCreated, deleted } = values;
      const deletedIds = deleted?.map((el) => el.fileId) ?? [];

      const payload = {
        newCreated:
          newCreated
            ?.filter(
              (el) =>
                !deletedIds.includes(el.fileId) &&
                !currentRateCardIds.includes(el.fileId)
            )
            ?.map((el) => el.fileId) ?? [],
        deleted: deletedIds,
        type: rateCardType,
      };

      await profileApi.updateAgencyFiles(agencyId, payload);
      const userData = await profileApi.getUserProfileByIdApi(userDetails?.id);
      setUserData(userData);
      setIsEditRateCard(false);
      notifySuccess("Has been updated successfully");
      scrollIntoView("rateCardScrollId");
    } catch (err) {
      console.error(err);
      notifyError(err?.data?.error ?? err?.message ?? "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  const resetOnUnmount = () => {};
  useEffect(() => {
    return () => {
      resetOnUnmount();
    };
  }, []);

  return {
    isEditRateCard,
    setIsEditRateCard,
    onEditRateCardFile,
    isRateCardLoading: isLoading,
  };
}

export default useRateCardFile;
