/* eslint-disable no-undef */
export const publicRoutes = [
  "signup",
  "login",
  "set-password",
  "forget-password",
];

export const timesOptions = [
  { label: "12:00", value: "12:00" },
  { label: "12:30", value: "12:30" },
  { label: "1:00", value: "1:00" },
  { label: "1:30", value: "1:30" },
  { label: "2:00", value: "2:00" },
  { label: "2:30", value: "2:30" },
  { label: "3:00", value: "3:00" },
  { label: "3:30", value: "3:30" },
  { label: "4:00", value: "4:00" },
  { label: "4:30", value: "4:30" },
  { label: "5:00", value: "5:00" },
  { label: "5:30", value: "5:30" },
  { label: "6:00", value: "6:00" },
  { label: "6:30", value: "6:30" },
  { label: "7:00", value: "7:00" },
  { label: "7:30", value: "7:30" },
  { label: "8:00", value: "8:00" },
  { label: "8:30", value: "8:30" },
  { label: "9:00", value: "9:00" },
  { label: "9:30", value: "9:30" },
  { label: "10:00", value: "10:00" },
  { label: "10:30", value: "10:30" },
  { label: "11:00", value: "11:00" },
  { label: "11:30", value: "11:30" },
];

export const numberToAvailableDaysMapper = {
  6: "Saturday",
  7: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
};

export const resumeType = "resume";
export const sampleOfWorkType = "sampleOfWork";
export const certificationType = "certification";
export const rateCardType = "rateCard";
export const portfolioType = "portfolio";
export const vmFileType = "vmDocs";
export const multiCVType = "CV";

export const availableDaysOptions = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
];

export const availableDaysOptionsMapper = {
  Sat: 6,
  Sun: 7,
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
};

export const availableDaysNumberMapper = {
  6: "Sat",
  7: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
};

export const LANGUAGE_MODEL_NONE = "NONE";
export const LANGUAGE_MODEL_PAIR = "PAIR";
export const LANGUAGE_MODEL_SINGLE = "SINGLE";

export const allowedResumeFileTypes = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const FORMAT_DATE = "DD/MM/YYYY";

export const STATUS_REGISTERED = "registered";
export const STATUS_REJECTED = "rejected";
export const STATUS_SUSPENDED = "suspended";
export const STATUS_INREVIEW = "inreview";
export const STATUS_ACTIVE = "active";
export const STATUS_BLACKLIST = "blacklist";
export const STATUS_QUALIFIED = "qualified";

export const SUBSTATUS_INCOMPLETE = "incomplete";
export const SUBSTATUS_PARTIAL = "partial";
export const SUBSTATUS_COMPLETE = "complete";
export const SUBSTATUS_APPROVED = "approved";
export const SUBSTATUS_WAITING_FOR_OFFICIAL_NDA = "waitingForOfficialNda";
export const SUBSTATUS_UNDER_TESTING = "underTesting";
export const SUBSTATUS_DECLINED_OFFICIAL_NDA = "declinedOfficialNda";
export const SUBSTATUS_EXPIRED_OFFICIAL_NDA = "expiredOfficialNda";
export const SUBSTATUS_PASSED_TEST = "passedTest";
export const SUBSTATUS_FAILED_TEST = "failedTest";
export const SUBSTATUS_ONBOARDING_REQUEST = "onBoardingRequested";
export const SUBSTATUS_ONBOARDING_DONE = "onBoardingDone";
export const SUBSTATUS_READY_FOR_WORK = "readyForWork";
export const SUBSTATUS_DO_NOT_USE = "doNotUse";
export const SUBSTATUS_PENDING_INVESTIGATION = "pendingInvestigation";
export const SUBSTATUS_AGREEMENT_SENT = "agreementSent";
export const SUBSTATUS_DECLINED_AGREEMENT = "declinedAgreement";
export const SUBSTATUS_EXPIRED_AGREEMENT = "expiredAgreement";
export const SUBSTATUS_UNDER_VALIDATION = "underValidation";

export const VM_PORTAL_COOKIE_NAME = "AllowedCookie-t-vm-portal";
export const TARJAMA_PRIVACY_POLICY_LINK =
  "https://tarjama.com/privacy-policy/";
