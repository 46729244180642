import React from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import TruncatedOption from "components/truncatedOption";
import { customStyle } from "styles/reactSelectStyle";

const MenuList = ({ children, meridiem, onMeridiemClick, ...props }) => {
  return (
    <components.MenuList {...props}>
      <div className="flex gap-[10px] h-full">
        <div className="flex flex-col flex-1 h-[9rem] max-h-[200px]">
          {children}
        </div>
        <div className="flex gap-[10px] flex-col">
          {["AM", "PM"].map((el) => {
            return (
              <div
                key={el}
                className={
                  el === meridiem
                    ? "bg-primary text-white font-regular text-[16px] not-italic p-[10px]"
                    : "cursor-pointer font-regular text-[16px] not-italic p-[10px]"
                }
                onClick={() => {
                  if (el === meridiem) {
                    return;
                  }
                  onMeridiemClick(el);
                }}
              >
                {el}
              </div>
            );
          })}
        </div>
      </div>
    </components.MenuList>
  );
};

function TimePicker({
  label = "",
  isRequired = false,
  errorMessage = "",
  name = "",
  register = () => {},
  registerArgs = {},
  containerClassName = "",
  labelClassName = "",
  maxMenuHeight = "9rem",
  isDisabled = false,
  options = [],
  onChange = () => {},
  isMulti = false,
  value = null,
  placeholder = "Search...",
  closeMenuOnSelect = false,
  meridiem = "AM",
  onMeridiemClick = () => {},
}) {
  return (
    <div
      className={
        containerClassName ? containerClassName : "flex flex-col gap-[10px]"
      }
    >
      {label && (
        <div
          className={
            labelClassName
              ? labelClassName
              : "text-primary font-semibold text-[16px]"
          }
        >
          {label} {isRequired && <span className="text-red">*</span>}
        </div>
      )}

      <div
        className={`grid w-full ${
          errorMessage &&
          isRequired &&
          (!value || (Array.isArray(value) && value.length <= 0))
            ? "border-red"
            : "border-default-color"
        } border-solid border-[1px] rounded-[4px]`}
      >
        <Select
          name={name}
          {...register(name, { ...registerArgs })}
          value={value ? value : isMulti ? [] : ""}
          maxMenuHeight={maxMenuHeight}
          placeholder={placeholder}
          isDisabled={isDisabled}
          options={options}
          isOptionDisabled={(option) => option.isDisabled}
          components={{
            MenuList: (props) => (
              <MenuList
                {...props}
                meridiem={meridiem}
                onMeridiemClick={onMeridiemClick}
              />
            ),
          }}
          onChange={(e) => {
            const event = !Array.isArray(e) && !e ? "" : e;
            // eslint-disable-next-line no-unused-expressions
            typeof onChange === "function" ? onChange(event) : null;
          }}
          isMulti={isMulti}
          closeMenuOnSelect={closeMenuOnSelect}
          formatOptionLabel={({ label }) => <TruncatedOption label={label} />}
          styles={{
            ...customStyle,
            menuList: (provided, state) => ({
              ...provided,
              zIndex: 10,
            }),
          }}
        />
      </div>

      {errorMessage &&
        isRequired &&
        (!value || (Array.isArray(value) && value.length <= 0)) && (
          <span className="text-[12px] text-red font-regular">
            {errorMessage}
          </span>
        )}
    </div>
  );
}

TimePicker.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  register: PropTypes.func,
  registerArgs: PropTypes.object,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  maxMenuHeight: PropTypes.string,
  isDisabled: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func,
  selectClassName: PropTypes.string,
  isMulti: PropTypes.bool,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  closeMenuOnSelect: PropTypes.bool,
  meridiem: PropTypes.string,
  onMeridiemClick: PropTypes.func,
};

export default TimePicker;
