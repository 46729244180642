import { useState } from "react";
import { timesOptions } from "utils/constantValues";

export default function useTime(time = {}) {
  const defaultTimeFrom = time?.from
    ? { label: convertToAMPM(time.from, true), value: convertToAMPM(time.from) }
    : null;
  const defaultTimeTo = time?.from
    ? {
        label: convertToAMPM(time.to, true),
        value: convertToAMPM(time.to),
      }
    : null;
  const defaultMeridiemFrom = time?.from
    ? convertToAMPM(time.from).split(" ")[1]
    : "AM";
  const defaultMeridiemTo = time?.to
    ? convertToAMPM(time.to).split(" ")[1]
    : "AM";

  //meridiem means AM/PM => ante meridiem and post meridiem
  const [meridiem, setMeridiem] = useState({
    from: defaultMeridiemFrom,
    to: defaultMeridiemTo,
  });
  const [timeFrom, setTimeFrom] = useState(defaultTimeFrom);
  const [timeTo, setTimeTo] = useState(defaultTimeTo);

  const onMeridiemClick = (chosenMeridiem = "AM", type = "from") => {
    setMeridiem((prevState) => ({
      ...prevState,
      [type]: chosenMeridiem,
    }));
  };

  const convertTimeTo24h = (time) => {
    if (!time) return "";
    // Split the string into hours, minutes, and AM/PM parts
    var parts = time.split(" ");

    // Get the hours and minutes
    var timeParts = parts[0].split(":");
    var hours = parseInt(timeParts[0], 10);
    var minutes = parseInt(timeParts[1], 10);

    // Convert to 24-hour format
    if (parts[1].toLowerCase() === "pm" && hours < 12) {
      hours += 12;
    } else if (parts[1].toLowerCase() === "am" && hours === 12) {
      hours = 0;
    }

    // Format the time as HH:MM
    let outputString =
      hours.toString().padStart(2, "0") +
      ":" +
      minutes.toString().padStart(2, "0");

    return outputString;
  };

  const isTimeAfter = (fromTime, toTime) => {
    fromTime = convertTimeTo24h(fromTime);
    toTime = convertTimeTo24h(toTime);

    const parseFromTime = Number(fromTime.replace(":", "."));
    const parseToTime = Number(toTime.replace(":", "."));

    return parseToTime > parseFromTime;
  };

  function convertToAMPM(time24h, hidePeriod = false) {
    // Split the time string into hours and minutes
    const [hours, minutes] = time24h.split(":");

    // Convert the hours to a number
    const hoursNum = parseInt(hours, 10);

    // Determine whether it's AM or PM
    const period = hoursNum >= 12 ? "PM" : "AM";

    // Convert 24-hour format to 12-hour format
    const hours12h = hoursNum % 12 || 12;

    // Create the AM/PM time string
    const time12h = hidePeriod
      ? `${hours12h}:${minutes}`
      : `${hours12h}:${minutes} ${period}`;

    return time12h;
  }

  return {
    onMeridiemClick,
    meridiem,
    timeFrom,
    setTimeFrom,
    timeTo,
    setTimeTo,
    convertTimeTo24h,
    isTimeAfter,
    convertToAMPM,
  };
}
