import React, { createRef, forwardRef } from "react";
import PropTypes from "prop-types";

function LabelForm({ label = "", labelClassName = "" }, ref = createRef()) {
  return (
    label && (
      <div
        ref={ref}
        className={
          labelClassName
            ? labelClassName
            : "text-primary font-bold text-[24px] not-italic"
        }
      >
        {label}
      </div>
    )
  );
}

LabelForm.propTypes = {
  label: PropTypes.string,
};

export default forwardRef(LabelForm);
