import React from "react";
import PropTypes from "prop-types";
import { Oval } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import UploadFile from "components/uploadFile";
import { ReactComponent as AttachIcon } from "images/auth/attach.svg";
import { useTranslation } from "react-i18next";
import useUploadFiles from "pages/signup/hooks/useUploadFiles";
import { resumeType } from "utils/constantValues";

function EditPersonalExperience({
  userDetails = {},
  onDiscard = () => {},
  onSave = () => {},
  isLoading = false,
}) {
  const [t] = useTranslation();
  const resumeFile = userDetails?.userFiles?.find(
    (file) => file.type === resumeType
  );
  const defaultsValues = {
    resume: resumeFile || "",
  };

  const {
    resumeRef,
    uploadResume,
    isUploading,
    resumeFileName,
    removeResumeFile,
  } = useUploadFiles({ defaultResumeFile: resumeFile });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultsValues,
  });

  return (
    <form
      className="flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]"
      onSubmit={handleSubmit(onSave)}
    >
      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        <div className="text-primary font-semibold text-[30px] not-italic">
          {t("cvTitle")}
        </div>
      </div>

      <div className="flex flex-wrap gap-[20px]">
        <UploadFile
          name={"resume"}
          register={register}
          registerArgs={{
            required: resumeFileName
              ? false
              : t("errorField", { field: t("resume") }),
          }}
          label={t("yourCvLabel")}
          placeHolderLabel={t("attachUrCv")}
          icon={<AttachIcon />}
          isUploading={isUploading.resume}
          getFile={(e, isDrag) => {
            const callback = (type, fileId) => {
              const fileList = isDrag
                ? [...e.dataTransfer.files]
                : [...e.target.files];
              const file = [...fileList][0];
              const name = file?.name ?? "";
              setValue(type, { id: fileId, type: resumeType, name });
            };
            uploadResume(e, isDrag, callback);
          }}
          containerClassName="grid w-full gap-[10px]"
          labelClassName="text-primary font-semibold text-[16px]"
          placeHolderContainerClassName={`flex p-[14px] justify-between items-center gap-[8px] self-stretch rounded-[6px] h-[54px] w-full min-w-[190px] sm-max:min-w-full sm-max:w-full border-[1px] border-dashed ${
            errors.resume && !resumeFileName ? "border-red" : "border-dark-gray"
          } cursor-pointer`}
          isRequired={true}
          errorMessage={errors.resume ? errors.resume.message : ""}
          ref={resumeRef}
          fileName={resumeFileName}
          onRemoveFile={(fileName) => removeResumeFile(fileName, setValue)}
          accept=".pdf,.doc,.docx"
        />
      </div>

      <div className="flex items-center justify-end gap-[10px]">
        <button
          type="button"
          onClick={onDiscard}
          disabled={isLoading || isUploading.resume}
          className="cursor-pointer flex py-[14px] px-[16px] gap-[8px] justify-center items-center rounded-[6px] border-[1px] border-solid border-default-color bg-white h-[46px]"
        >
          <div className="text-base-black font-bold text-[16px] not-italic">
            {t("discard")}
          </div>
        </button>
        <button
          type="submit"
          disabled={isLoading || isUploading.resume}
          className="cursor-pointer flex py-[14px] px-[16px] gap-[8px] justify-center items-center rounded-[6px] border-[1px] border-solid border-primary bg-primary h-[46px]"
        >
          <Oval
            height={30}
            width={30}
            color="#FFF"
            visible={isLoading}
            ariaLabel="oval-loading"
            secondaryColor="#FFF"
            strokeWidth={5}
            strokeWidthSecondary={3}
          />
          <div className="text-white font-bold text-[16px] not-italic">
            {t("saveChangesCta")}
          </div>
        </button>
      </div>
    </form>
  );
}

EditPersonalExperience.propTypes = {
  userDetails: PropTypes.object,
  onEditClick: PropTypes.func,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default EditPersonalExperience;
