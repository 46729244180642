import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import LabelForm from "pages/signup/form/labelForm";
import CardCheckbox from "components/cardCheckbox";
import useServices from "hooks/useServices";
import Select from "components/select";
import RateTypesInputs from "./rateTypeInputs";
import { ReactComponent as AddCircleIcon } from "images/auth/add-circle.svg";
import { ReactComponent as TrashIcon } from "images/auth/trash.svg";

function ServiceForm({
  setValue = () => {},
  register = () => {},
  unregister = () => {},
  watch,
  errors,
  data = {},
  label = "",
  defaultServices = [],
  defaultServicesRates = {},
  defaultDrawLanguages = {},
  isVendor = false,
}) {
  const [t] = useTranslation();
  const { services = [], languages = [] } = data;

  const languagesOptions = languages.map((elem) => ({
    ...elem,
    label: elem.title,
    value: elem.id,
  }));
  const {
    activeService,
    serviceRates,
    removeService,
    addService,
    addLanguages,
    removeLanguage,
    onHandleChange,
    drawLanguages,
    getMaxPrice,
  } = useServices(defaultServices, defaultServicesRates, defaultDrawLanguages);

  return (
    <Fragment>
      <LabelForm
        label={label}
        labelClassName="text-primary font-bold text-[24px] md-max:text-[18px] not-italic pt-[16px] bg-white z-[3]"
      />
      <div className="flex gap-[16px] max-w-[843px] sm-max:max-w-full pt-[10px] px-[0px] pb-[30px]  z-[3] bg-white">
        <div className="flex flex-wrap gap-y-[20px] gap-x-[14px] items-center">
          {services.map((service) => {
            return (
              <Fragment key={service.title}>
                <CardCheckbox
                  title={t(service.title)}
                  isActive={activeService.some(
                    (elem) => elem.id === service.id
                  )}
                  onClick={() => {
                    if (activeService.some((elem) => elem.id === service.id)) {
                      removeService(service, setValue, unregister);
                    } else {
                      addService(service, setValue);
                    }
                  }}
                  containerClassName="flex gap-[5px] items-center  self-stretch py-[12px] px-[10px] cursor-pointer"
                  titleClassName="text-gray-700 font-semibold text-[14px] not-italic w-full"
                />
              </Fragment>
            );
          })}

          <div className="flex flex-col w-full gap-[10px]">
            {Object.keys(serviceRates).map((serviceId, index) => {
              const serviceRate = serviceRates[serviceId];
              const service = services.find(
                (service) => service.id === serviceId
              );

              return (
                <Fragment key={serviceId}>
                  {serviceRate.map((rateType, rateIndex) => {
                    const isLimitLanguageError =
                      isVendor && drawLanguages[serviceId].length >= 10;

                    const showLanguages = rateType.hasMoreThanRate
                      ? rateIndex < drawLanguages[serviceId].length
                      : true;

                    const languageFrom = rateType.isPair
                      ? watch(`${serviceId}-${index}-${rateIndex}-from`)
                        ? languagesOptions.find(
                            (elem) =>
                              elem.id ===
                              watch(`${serviceId}-${index}-${rateIndex}-from`)
                          )
                        : null
                      : false;

                    const languageTo = rateType?.hasLanguages
                      ? watch(`${serviceId}-${index}-${rateIndex}-to`)
                        ? languagesOptions.find(
                            (elem) =>
                              elem.id ===
                              watch(`${serviceId}-${index}-${rateIndex}-to`)
                          )
                        : null
                      : false;
                    const isPair = rateType.isPair;

                    const getMaxCeilingPrice = rateType?.hasLanguages
                      ? getMaxPrice({
                          isPair,
                          languageFrom,
                          languageTo,
                          service,
                        })
                      : false;

                    const parseMaxPrice = getMaxCeilingPrice
                      ? !Number.isNaN(Number(getMaxCeilingPrice))
                        ? Number(getMaxCeilingPrice)
                        : false
                      : false;

                    const showDisclaimer =
                      !Number.isNaN(Number(rateType?.rate)) &&
                      typeof parseMaxPrice === "number"
                        ? Number(rateType?.rate) > parseMaxPrice
                        : false;

                    return (
                      <div
                        className={
                          showLanguages || rateIndex === 0
                            ? "grid gap-[10px] w-full"
                            : rateIndex === serviceRate.length - 1
                            ? "grid gap-[10px] w-full"
                            : ""
                        }
                        key={`${rateIndex}`}
                      >
                        {rateType.withRate && rateIndex === 0 && (
                          <div className="text-gray-900 font-semibold text-[16px] not-italic mt-[10px]">
                            {t("serviceTitle", {
                              serviceTitle: t(rateType.serviceName),
                            })}
                          </div>
                        )}
                        {!rateType.hasLanguages && (
                          <RateTypesInputs
                            rateType={rateType}
                            onHandleChange={onHandleChange}
                            serviceId={serviceId}
                            rateIndex={rateIndex}
                            setValue={setValue}
                            showDisclaimer={showDisclaimer}
                            isCharacter={
                              languageFrom?.isCharacter ??
                              languageTo?.isCharacter
                            }
                          />
                        )}

                        {rateType.hasLanguages && (
                          <div className={"grid w-full gap-[10px]"}>
                            <div
                              className={`grid ${
                                isPair
                                  ? rateIndex === 0
                                    ? "grid-cols-2"
                                    : "grid-cols-custom-grid-select-icon"
                                  : rateIndex === 0
                                  ? "grid-cols-1"
                                  : "grid-cols-custom-grid-label-icon"
                              } ${
                                rateIndex > 0 &&
                                rateIndex % service.type.length === 0
                                  ? "mt-[10px]"
                                  : ""
                              } gap-[10px]`}
                            >
                              {isPair &&
                                rateIndex % service.type.length === 0 && (
                                  <Select
                                    label={t("languageFrom")}
                                    placeholder={t("languagesPlaceholder")}
                                    name={`${serviceId}-${index}-${rateIndex}-from`}
                                    value={languageFrom}
                                    onChange={(e = {}) => {
                                      onHandleChange(
                                        e.value,
                                        serviceId,
                                        "fromLanguage",
                                        rateIndex,
                                        setValue
                                      );
                                      setValue(
                                        `${serviceId}-${index}-${rateIndex}-from`,
                                        e.value
                                      );
                                    }}
                                    register={register}
                                    registerArgs={{
                                      required: t("errorField", {
                                        field: t("languageFrom"),
                                      }),
                                    }}
                                    errorMessage={
                                      errors[
                                        `${serviceId}-${index}-${rateIndex}-from`
                                      ]
                                        ? errors[
                                            `${serviceId}-${index}-${rateIndex}-from`
                                          ].message
                                        : ""
                                    }
                                    isRequired={true}
                                    options={languagesOptions}
                                  />
                                )}

                              {rateIndex % service.type.length === 0 && (
                                <Select
                                  label={t("languageTo")}
                                  placeholder={t("languagesPlaceholder")}
                                  name={`${serviceId}-${index}-${rateIndex}-to`}
                                  value={languageTo}
                                  onChange={(e = {}) => {
                                    onHandleChange(
                                      e.value,
                                      serviceId,
                                      "toLanguage",
                                      rateIndex,
                                      setValue
                                    );

                                    setValue(
                                      `${serviceId}-${index}-${rateIndex}-to`,
                                      e.value
                                    );
                                  }}
                                  register={register}
                                  registerArgs={{
                                    required: t("errorField", {
                                      field: t("languageTo"),
                                    }),
                                  }}
                                  errorMessage={
                                    errors[
                                      `${serviceId}-${index}-${rateIndex}-to`
                                    ]
                                      ? errors[
                                          `${serviceId}-${index}-${rateIndex}-to`
                                        ].message
                                      : ""
                                  }
                                  isRequired={true}
                                  options={languagesOptions}
                                />
                              )}

                              {rateIndex >= 1 &&
                                rateIndex % service.type.length === 0 && (
                                  <TrashIcon
                                    className="cursor-pointer mt-[42px] object-cover w-full max-w-[25px]"
                                    onClick={() => {
                                      if (rateType.isPair) {
                                        unregister(
                                          `${serviceId}-${index}-${rateIndex}-from`
                                        );
                                      }
                                      unregister(
                                        `${serviceId}-${index}-${rateIndex}-to`
                                      );
                                      removeLanguage(
                                        serviceId,
                                        rateIndex,
                                        setValue
                                      );
                                    }}
                                  />
                                )}
                            </div>

                            <RateTypesInputs
                              rateType={rateType}
                              onHandleChange={onHandleChange}
                              serviceId={serviceId}
                              rateIndex={rateIndex}
                              setValue={setValue}
                              showDisclaimer={showDisclaimer}
                              isCharacter={
                                languageFrom?.isCharacter ??
                                languageTo?.isCharacter
                              }
                            />

                            {rateIndex === serviceRate.length - 1 && (
                              <div className="grid mt-[10px]">
                                {isLimitLanguageError && (
                                  <div className="text-[12px] text-red font-regular">
                                    {t("maximumNumberLanguageError")}
                                  </div>
                                )}
                                <div
                                  className={`flex gap-[8px] ${
                                    isLimitLanguageError
                                      ? "cursor-not-allowed"
                                      : "cursor-pointer"
                                  }`}
                                  onClick={() => {
                                    if (!isLimitLanguageError) {
                                      addLanguages(service, setValue);
                                    }
                                  }}
                                >
                                  <AddCircleIcon
                                    className={
                                      isLimitLanguageError
                                        ? "text-gray-500 cursor-not-allowed opacity-50"
                                        : ""
                                    }
                                  />
                                  <div
                                    className={`${
                                      isLimitLanguageError
                                        ? "text-gray-500 cursor-not-allowed opacity-50"
                                        : "text-primary font-bold text-[16px] not-italic"
                                    }`}
                                  >
                                    {t("addAnotherLanguage")}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {index < Object.keys(serviceRates).length - 1 && (
                    <div className="h-[1px] w-full  grid bg-dark-gray mt-[15px]" />
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

ServiceForm.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  watch: PropTypes.func,
  data: PropTypes.object,
  getValues: PropTypes.func,
  label: PropTypes.string,
  defaultServices: PropTypes.array,
  defaultServicesRates: PropTypes.object,
  defaultDrawLanguages: PropTypes.object,
  unregister: PropTypes.func,
  isVendor: PropTypes.bool,
};

export default ServiceForm;
