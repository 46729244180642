export const profile = {
  changePasswordSuccess: "Password changed successfully.",
  changePasswordTitle: "Change your password",
  currentPassword: "Current password",
  currentPasswordPlaceHolder: "Write your current password",
  backToProfile: "Back to profile",
  passwordChanged: "Password changed",
  passwordChangedSuccess: "Your password has been successfully changed",
  goToMyProfile: "Go to my profile",
  changedPasswordSubTitle:
    "Your new password must be different to previously used passwords.",
  greetingTitle: "Welcome {{title}}",
  personalDetailsTitle: "Personal details",
  nameLabel: "Name",
  nationalityLabel: "Nationality",
  contactDetailsTitle: "Contact details",
  availabilityTitle: "Availability",
  from: "From",
  to: "To",
  years: "Years",
  skills: "Skills",
  services: "Services",
  cvTitle: "CV",
  isWillingToTravel: "I'm {{isWillingToTravel}} willing to travel",
  relevantProfileTitle: "Relevant profiles",

  "serviceRate-per word": "/word",
  "serviceRate-per hour": "/h",
  "serviceRate-per page": "/page",
  "serviceRate-per slide": "/slide",
  "serviceRate-per minute": "/min",
  "serviceRate-online": "/h-online",
  "serviceRate-on-site": "/h-onsite",
  "serviceRate-online half a day": "/half a day (4h)-online",
  "serviceRate-on-site half a day": "/half a day (4h)-onsite",
  "serviceRate-online full day": "/full day (8h)-online",
  "serviceRate-on site full day": "/full day (8h)-onsite",
  "serviceRate-overtime online": "/overtime hourly-online",
  "serviceRate-overtime on-site": "/overtime hourly-onsite",
  companyDetailsTitle: "Company details",
  companyName: "Company name",
  website: "Website",
  discard: "Discard",
  saveChangesCta: "Save changes",
  gender: "Gender",
  dateOfBirth: "Date of birth",
  dateOfBirthPlaceholder: "DD/MM/YYYY",
  martialStatus: "Martial status",
  placeholderAgency: "Enter {{title}}",

  countryOfBirth: "Country of birth",
  placeOfBirth: "Place of birth",
  socialSecurityNumber: "Social security number",
  nationalCardNo: "National card number",
  passportNo: "Passport number",
  expiryDate: "Expiry date",

  placeOfIssue: "Place of issue",
  countryOfIssue: "Country of issue",
  qualificationsTitle: "Qualifications",

  university: "University",
  qualifications: "Qualifications",
  major: "Major",
  greetingFormTitle: "Welcome to our community",
  greetingFormSubTitle: "Please complete your profile and start working!",

  permanentAddress: "Permanent address",
  currentResidenceAddress: "Current residence address",
  phoneNumber: "Phone number",
  homeTelephone: "Home telephone",
  country: "Country",
  bankDetails: "Bank details",
  financialInformation: "Financial information",
  financialInformationSubTitle:
    "Add your preferred method; including all available options is recommended.",

  accountName: "Account name",
  bankName: "Bank name",
  accountType: "Account type",
  accountNumber: "Account number",
  swiftCode: "Swift code",
  sortCode: "Sort code",
  iban: "IBAN",
  phonePlaceholder: "00962",

  emergencyContactTitle: "Emergency contact",
  name: "Full name",
  relationship: "Relationship",
  email: "Email",
  saveAndSubmit: "Save and submit",
  addNewContacts: "Add new contacts",
  removeSections: "Remove sections",
  department: "Department",
  position: "Position",
  accountHolderName: "Account holder name",
  accountCurrency: "Account currency",
  bankAddress: "Bank address",
  routingCode: "Routing Code",
  legalDetailsTitle: "Legal details",
  fileDocument: "File documents",
  profileCompletion: "Profile completion",
  completed: "Completed",

  preferredCurrency: "Preferred currency",
  paypal: "PayPal",
  cashExpress: "Cash Express",
  paypalLabel: "PayPal email or mobile number",
  paypalPlaceholder: "Enter your PayPal email or phone number",
  expressFullNameSubTitle: "it should be exactly same as your ID",
  fullName: "Full Name",
  uploadCv: "Upload CVs",
  cvs: "CVs",
};
