import React, { Fragment, forwardRef } from "react";
import PropTypes from "prop-types";
import LabelForm from "pages/signup/form/labelForm";
import { useTranslation } from "react-i18next";
import Input from "components/input";
import { isValidEmail, validateEmail } from "utils/helper";
import UploadFile from "components/uploadFile";
import useUploadFiles from "pages/signup/hooks/useUploadFiles";
import { ReactComponent as AttachIcon } from "images/auth/attach.svg";

function StartJourney(
  { register = () => {}, errors = {}, setValue = () => {}, watch = () => {} },
  ref
) {
  const [t] = useTranslation();
  const {
    resumeRef,
    uploadResume,
    isUploading,
    resumeFileName,
    removeResumeFile,
  } = useUploadFiles({});
  return (
    <Fragment>
      <LabelForm
        label={t("startJourneyLabel")}
        ref={ref}
        labelClassName="text-primary font-bold text-[24px] md-max:text-[18px] not-italic mb-[50px]"
      />

      <div className="flex flex-row sm-max:flex-col flex-wrap sm-max:flex-nowrap gap-[16px] max-w-[843px] sm-max:max-w-full pt-[10px] px-[0px] pb-[30px]  z-[3] bg-white border-b-[1px] border-solid border-default-color">
        <Input
          label={t("firstName")}
          isRequired={true}
          placeholder={t("placeHolderType", {
            title: t("firstName").toLowerCase(),
          })}
          name="firstName"
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("firstName") }),
          }}
          errorMessage={
            errors.firstName && !watch("firstName")
              ? errors.firstName.message
              : ""
          }
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName={`flex h-[43px] min-w-[190px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.firstName && !watch("firstName")
              ? "border-red"
              : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />

        <Input
          label={t("lastName")}
          isRequired={true}
          placeholder={t("placeHolderType", {
            title: t("lastName").toLowerCase(),
          })}
          name="lastName"
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("lastName") }),
          }}
          errorMessage={
            errors.lastName && !watch("lastName") ? errors.lastName.message : ""
          }
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName={`flex h-[43px] min-w-[190px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.lastName && !watch("lastName")
              ? "border-red"
              : "border-default-color"
          }  bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />

        <Input
          label={t("emailTitle")}
          isRequired={true}
          type="email"
          name="email"
          placeholder={t("placeHolderType", {
            title: t("emailTitle").toLowerCase(),
          })}
          errorMessage={
            errors.email && !isValidEmail(watch("email"))
              ? watch("email") && !isValidEmail(watch("email"))
                ? t("invalidEmail")
                : t("errorField", { field: t("emailTitle") })
              : ""
          }
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("emailTitle") }),
            validate: validateEmail,
          }}
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName={`flex h-[43px] min-w-[190px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.email && !isValidEmail(watch("email"))
              ? "border-red"
              : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />

        <UploadFile
          name={"resume"}
          register={register}
          registerArgs={{
            required: resumeFileName
              ? false
              : t("errorField", { field: t("resume") }),
          }}
          label={t("yourCvLabel")}
          placeHolderLabel={t("attachUrCv")}
          icon={<AttachIcon />}
          isUploading={isUploading.resume}
          getFile={(e, isDrag) => uploadResume(e, isDrag, setValue)}
          labelClassName="text-primary font-semibold text-[16px]"
          placeHolderContainerClassName={`flex p-[14px] justify-between items-center gap-[8px] self-stretch rounded-[6px] h-[43px] w-full min-w-[190px] sm-max:min-w-full sm-max:w-full border-[1px] border-dashed ${
            errors.resume && !resumeFileName ? "border-red" : "border-dark-gray"
          } cursor-pointer`}
          isRequired={true}
          errorMessage={errors.resume ? errors.resume.message : ""}
          ref={resumeRef}
          fileName={resumeFileName}
          onRemoveFile={(fileName) => removeResumeFile(fileName, setValue)}
          accept=".pdf,.doc,.docx"
        />
      </div>
    </Fragment>
  );
}

StartJourney.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  errors: PropTypes.object,
};

export default forwardRef(StartJourney);
