import { useState, useEffect } from "react";
import { useLookupsContext } from "hooks/useContext";
import useNotify from "hooks/useNotify";
import { useTranslation } from "react-i18next";
import profileApi from "api/profile";
import { scrollIntoView } from "utils/helper";

function usePersonalExperience({
  userDetails = {},
  userDomains = [],
  userSubDomains = [],
  profileCompletionRef = {},
  domainIds = [],
}) {
  const vendorId = userDetails?.vendorId;
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditPersonalExperience, setIsEditPersonalExperience] =
    useState(false);

  const [radios, setRadios] = useState([
    {
      title: t("Yes"),
      isActive: userDetails?.isWillingToTravel,
    },
    { title: t("No"), isActive: !userDetails?.isWillingToTravel },
  ]);

  const vendorProps = vendorId
    ? {
        yearOfExperience: userDetails.yearOfExperience,
        isWillingToTravel: userDetails?.isWillingToTravel ?? false,
      }
    : {};

  profileCompletionRef.experience = {
    openEdit: setIsEditPersonalExperience,
    userDomains: userDomains ?? [],
    subDomains: userSubDomains ?? [],
    ...vendorProps,
  };
  const {
    domains = [],
    subDomains = [],
    getSubDomains,
  } = useLookupsContext(false);

  const domainsOptions = domains.map((elem) => ({
    ...elem,
    label: elem.title,
    value: elem.id,
  }));

  const subDomainsOptions = subDomains.map((elem) => ({
    ...elem,
    label: elem.title,
    value: elem.id,
  }));

  const { notifyError, notifySuccess } = useNotify();

  const onEditPersonalExperience = async (
    values,
    setUserData = () => {},
    setUserDomains = () => {},
    setUserSubDomains = () => {}
  ) => {
    try {
      setIsLoading(true);

      const agencyId = userDetails?.agencyId;
      const { domains, isWillingToTravel, yearOfExperience, subDomains } =
        values;

      if (vendorId) {
        const canUpdateDomains = userDetails.flags.lockDomain
          ? {}
          : { domains, subDomains };
        await profileApi.updateVendorExperienceApi(vendorId, {
          yearsOfExperience: Number.isNaN(Number(`${yearOfExperience}`))
            ? 0
            : Number(yearOfExperience),
          isWillingToTravel,
          ...canUpdateDomains,
        });
      } else {
        await profileApi.updateAgencyExperienceApi(agencyId, {
          domains,
          subDomains,
        });
      }

      setUserData((prevState) => ({
        ...prevState,
        ...values,
        yearOfExperience: Number.isNaN(Number(`${yearOfExperience}`))
          ? 0
          : Number(yearOfExperience),
      }));
      setUserDomains(
        domainsOptions.filter((elem) => values.domains.includes(elem.id))
      );
      setUserSubDomains(
        subDomainsOptions.filter((elem) => values.subDomains.includes(elem.id))
      );
      setIsEditPersonalExperience(false);
      notifySuccess("Has been updated successfully");
      scrollIntoView("experienceScrollId");
    } catch (err) {
      console.error(err);
      notifyError(err?.data?.error ?? err?.message ?? "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (domainIds?.length > 0) {
      getSubDomains(domainIds);
    }
  }, [domainIds]);

  const resetOnUnmount = () => {};
  useEffect(() => {
    return () => {
      resetOnUnmount();
    };
  }, []);

  return {
    isEditPersonalExperience,
    setIsEditPersonalExperience,
    onEditPersonalExperience,
    isExperienceLoading: isLoading,
    domainsOptions,
    domains,
    radios,
    setRadios,
    subDomainsOptions,
    subDomains,
  };
}

export default usePersonalExperience;
