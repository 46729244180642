/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import { useAppContext } from "hooks/useContext";
import authApi from "api/auth";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useNotify from "./useNotify";

function useCheckUser() {
  const location = useLocation().pathname;
  const params = useLocation().search;
  const searchParams = new URLSearchParams(params);
  const loginToken = searchParams.get("loginToken");
  const staffId = searchParams.get("staffId");

  let intervalId;

  const [isChecked, setIsChecked] = useState(false);
  const [countDown, setCountDown] = useState(undefined);

  const { user = {}, isAuth, setUser, setAuth, resetUser } = useAppContext();
  const { notifyWarning } = useNotify();
  const [t] = useTranslation();

  const loginByToken = useCallback(async () => {
    try {
      const payload = {
        token: loginToken,
        staffId: staffId,
      };

      authApi
        .getUser()
        .then(() => {
          notifyWarning(
            t("userAlreadyLoggedInError", { countdown: countDown || 5 }),
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              id: "testtest",
              style: {
                borderRadius: 46,
                zIndex: 99999999,
              },
            }
          );
          setCountDown(5);
          setTimeout(() => {
            window.close();
          }, 5000);
        })
        .catch(async () => {
          await authApi.loginByToken(payload);
          const data = await authApi.getUser();
          setUser(data.data ? data.data : data);
          setAuth(true);
          setIsChecked(true);
        });
    } catch (err) {
      console.error(err);
      setIsChecked(true);
      resetUser();
      const urlWithoutParams =
        window.location.origin + window.location.pathname;
      // Redirect to the URL without query parameters
      window.location.href = urlWithoutParams;
    }
  }, [loginToken, staffId]);

  const checkUser = useCallback(async () => {
    try {
      const data = await authApi.getUser();
      setUser(data.data ? data.data : data);
      setAuth(true);
      setIsChecked(true);
    } catch (err) {
      console.error(err);
      setIsChecked(true);
      resetUser();
    }
  }, [location]);

  useEffect(() => {
    if (loginToken || staffId) {
      loginByToken();
    } else if (!isAuth || Object.keys(user).length <= 0) {
      checkUser();
    } else {
      setAuth(true);
      setIsChecked(true);
    }
  }, [location]);

  useEffect(() => {
    if (countDown === 1) {
      clearInterval(intervalId);
      intervalId = null;
      setCountDown(undefined);
    }

    if (typeof countDown === "number" && !intervalId) {
      intervalId = setInterval(() => {
        const elements = document.getElementsByTagName("div");
        const divElement = [];

        Array.prototype.slice.call(elements).forEach((elem) => {
          if (
            elem.textContent.includes(
              "You are already logged in, Kindly sign out first, then try again, this browser tab will be closed in"
            )
          ) {
            divElement.push(elem);
          }
        });
        if (divElement && divElement.length) {
          divElement[divElement.length - 1].textContent = t(
            "userAlreadyLoggedInError",
            {
              countdown: countDown - 1,
            }
          );
        }
        setCountDown(countDown - 1);
      }, 1000);
    }
  }, [countDown]);

  useEffect(() => {
    return () => {
      setIsChecked(false);
      clearInterval(intervalId);
      setCountDown(undefined);
    };
  }, []);

  return { isChecked, isAuth: isAuth && Object.keys(user).length > 0 };
}

export default useCheckUser;
