import { useEffect, useState } from "react";
import {
  LANGUAGE_MODEL_NONE,
  LANGUAGE_MODEL_PAIR,
  LANGUAGE_MODEL_SINGLE,
} from "utils/constantValues";

function useServices(
  defaultServices = [],
  defaultServicesRates = {},
  defaultDrawLanguages = {}
) {
  const [activeService, setActiveService] = useState(defaultServices);
  const [serviceRates, setServiceRates] = useState(defaultServicesRates);
  const [drawLanguages, setDrawLanguages] = useState(defaultDrawLanguages);

  function range(start, end) {
    if (start === end) return [start];
    return [start, ...range(start + 1, end)];
  }

  const getDrawIndex = (
    drawLanguages = [],
    ratesLengthPerService,
    rateIndex
  ) => {
    let findIndex = 0;
    drawLanguages.forEach((savedIndex) => {
      const calcIndex = savedIndex === 0 ? 1 : savedIndex + 1;
      const calcCapacityLength = ratesLengthPerService * calcIndex;
      const fromIndex = calcCapacityLength - ratesLengthPerService;
      const toIndex = calcCapacityLength - 1;
      if (rateIndex >= fromIndex && rateIndex <= toIndex) {
        findIndex = savedIndex;
      }
    });

    return findIndex;
  };

  const removeLanguage = (serviceId, index, setValue = () => {}) => {
    let filterServiceRate = [];

    const hasMoreThanRate = serviceRates[serviceId][index]?.hasMoreThanRate;
    const ratesLengthPerService =
      serviceRates[serviceId][index]?.ratesLengthPerService;
    if (hasMoreThanRate) {
      const findIndex = getDrawIndex(
        drawLanguages[serviceId],
        ratesLengthPerService,
        index
      );
      const calcIndex = findIndex === 0 ? 1 : findIndex + 1;
      const calcCapacityLength = ratesLengthPerService * calcIndex;
      const fromIndex = calcCapacityLength - ratesLengthPerService;
      const toIndex = calcCapacityLength - 1;
      serviceRates[serviceId].splice(fromIndex, toIndex);
      filterServiceRate = serviceRates[serviceId];
    } else {
      filterServiceRate = serviceRates[serviceId].filter((rateType, i) => {
        return i !== index;
      });
    }

    setValue(
      "serviceRates",
      filterServiceRate.map((elem) => {
        const hasCapacity = elem.withCapacity
          ? { capacity: elem.capacity || 0 }
          : {};
        const hasFromLanguages =
          elem.hasLanguages && elem.isPair
            ? { fromLanguage: elem.fromLanguage }
            : {};
        const hasToLanguages = elem.hasLanguages
          ? { toLanguage: elem.toLanguage }
          : {};

        return {
          rate: elem.rate || 0,
          rateId: elem.rateId,

          ...hasCapacity,
          ...hasFromLanguages,
          ...hasToLanguages,
        };
      })
    );
    setServiceRates((prevState) => ({
      ...prevState,
      [serviceId]: filterServiceRate,
    }));

    setDrawLanguages((prevState) => ({
      ...prevState,
      [serviceId]: prevState[serviceId].slice(0, -1),
    }));
  };

  const addLanguages = (service, setValue = () => {}) => {
    const newServiceRate = [
      ...serviceRates[service.id],
      ...addNewServiceRate(service),
    ];

    const currentDrawLanguages = drawLanguages[service.id];
    setDrawLanguages((prevState) => ({
      ...prevState,
      [service.id]: range(0, currentDrawLanguages.length),
    }));

    setServiceRates((prevState) => ({
      ...prevState,
      [service.id]: newServiceRate,
    }));
    setValue(
      "serviceRates",
      Object.values(newServiceRate).map((elem) => {
        const hasCapacity = elem.withCapacity
          ? { capacity: elem.capacity || 0 }
          : {};
        const hasFromLanguages =
          elem.hasLanguages && elem.isPair
            ? { fromLanguage: elem.fromLanguage }
            : {};
        const hasToLanguages = elem.hasLanguages
          ? { toLanguage: elem.toLanguage }
          : {};

        return {
          rate: elem.rate || 0,
          rateId: elem.rateId,
          ...hasCapacity,
          ...hasCapacity,
          ...hasFromLanguages,
          ...hasToLanguages,
        };
      })
    );
  };

  function addNewServiceRate(service = {}) {
    const serviceType = service.type.map((el, index) => {
      const languages =
        service?.languageModel === LANGUAGE_MODEL_PAIR
          ? { fromLanguage: "", toLanguage: "" }
          : service?.languageModel === LANGUAGE_MODEL_SINGLE
          ? { toLanguage: "" }
          : {};
      return {
        rate: 0,
        rateId: el.id,
        title: el.title,
        capacity: 0,
        serviceName: service.title,
        serviceId: service.id,
        hasMoreThanRate: service.type.length > 1,
        ratesLengthPerService: service.type.length,
        withRate: service?.withRate ?? true,
        withCapacity: service?.withCapacity ?? false,
        isPair: service?.languageModel === LANGUAGE_MODEL_PAIR,
        hasLanguages:
          service?.languageModel !== LANGUAGE_MODEL_NONE || index > 1,
        languageModel: service?.languageModel ?? LANGUAGE_MODEL_NONE,

        ...languages,
      };
    });

    return serviceType;
  }

  const onHandleChange = (
    newValue,
    serviceId,
    filedName = "",
    index,
    setValue = () => {}
  ) => {
    const currentServiceRates = serviceRates[serviceId];

    const hasMoreThanRate = currentServiceRates[index]?.hasMoreThanRate;
    const ratesLengthPerService =
      currentServiceRates[index]?.ratesLengthPerService;

    const findIndex = getDrawIndex(
      drawLanguages[serviceId],
      ratesLengthPerService,
      index
    );
    const calcIndex = findIndex === 0 ? 1 : findIndex + 1;

    const calcCapacityLength = ratesLengthPerService * calcIndex;
    const fromIndex = calcCapacityLength - ratesLengthPerService;
    const toIndex = calcCapacityLength - 1;

    let updateNewValue = [];

    if (hasMoreThanRate && ["fromLanguage", "toLanguage"].includes(filedName)) {
      updateNewValue = currentServiceRates.map((item, i) => {
        if (i >= fromIndex && i <= toIndex) {
          return { ...item, [filedName]: newValue };
        }

        return { ...item };
      });
    } else {
      updateNewValue = currentServiceRates.map((item, i) => {
        if (i === index) {
          return { ...item, [filedName]: newValue };
        }

        return { ...item };
      });
    }

    const updatedServiceRates = {
      ...serviceRates,
      [serviceId]: updateNewValue,
    };

    setValue(
      "serviceRates",
      Object.values(updatedServiceRates)
        .reduce((acc, item) => {
          item.forEach((el) => {
            acc.push(el);
          });
          return acc;
        }, [])
        .map((elem) => {
          const hasCapacity = elem.withCapacity
            ? { capacity: elem.capacity || 0 }
            : {};
          const hasFromLanguages =
            elem.hasLanguages && elem.isPair
              ? { fromLanguage: elem.fromLanguage }
              : {};
          const hasToLanguages = elem.hasLanguages
            ? { toLanguage: elem.toLanguage }
            : {};

          return {
            rate: elem.rate || 0,
            rateId: elem.rateId,
            ...hasCapacity,
            ...hasFromLanguages,
            ...hasToLanguages,
          };
        })
    );
    setServiceRates(updatedServiceRates);
  };

  const handleServiceRates = (service = {}, ratesTypes = {}) => {
    if (!ratesTypes[service.id]) {
      ratesTypes[service.id] = [];
    }

    const serviceType = addNewServiceRate(service);
    ratesTypes[service.id] = [...ratesTypes[service.id], ...serviceType];
    return ratesTypes;
  };

  const removeService = (
    service = {},
    setValue = () => {},
    unregister = () => {}
  ) => {
    const filteredServices = activeService.filter(
      (elem) => elem.id !== service.id
    );

    Object.keys(serviceRates).forEach((serviceId, index) => {
      const serviceRate = serviceRates[serviceId];
      serviceRate.forEach((_, rateIndex) => {
        unregister(`${serviceId}-${index}-${rateIndex}-from`);
        unregister(`${serviceId}-${index}-${rateIndex}-to`);
      });
    });

    delete serviceRates[service.id];
    delete drawLanguages[service.id];

    setValue(
      "services",
      filteredServices.map((elem) => elem.id)
    );

    setValue(
      "serviceRates",
      Object.values(serviceRates).reduce((acc, item) => {
        item.forEach((el) => {
          acc.push(el);
        });
        return acc;
      }, [])
    );
    setActiveService(filteredServices);
    setServiceRates(serviceRates);
    setDrawLanguages(drawLanguages);
  };

  const addService = (service = {}, setValue) => {
    const chosenService = [...activeService, service];

    const chosenServiceRates = handleServiceRates(service, serviceRates);

    setValue(
      "services",
      chosenService.map((elem) => elem.id)
    );

    setDrawLanguages((prevState) => ({ ...prevState, [service.id]: [0] }));

    setValue(
      "serviceRates",
      Object.values(serviceRates)
        .reduce((acc, item) => {
          item.forEach((el) => {
            acc.push(el);
          });
          return acc;
        }, [])
        .map((elem) => {
          const hasCapacity = elem.withCapacity
            ? { capacity: elem.capacity || 0 }
            : {};
          const hasFromLanguages =
            elem.hasLanguages && elem.isPair
              ? { fromLanguage: elem.fromLanguage }
              : {};
          const hasToLanguages = elem.hasLanguages
            ? { toLanguage: elem.toLanguage }
            : {};

          return {
            rate: elem.rate || 0,
            rateId: elem.rateId,
            ...hasCapacity,
            ...hasFromLanguages,
            ...hasToLanguages,
          };
        })
    );

    setActiveService(chosenService);
    setServiceRates(chosenServiceRates);
  };

  const getMaxPrice = ({
    isPair = false,
    languageFrom = {},
    languageTo = {},
    service = {},
  }) => {
    const ceilingPrices = service.ceilingPrices;

    if (isPair) {
      const languageFromId = languageFrom?.id;
      const languageToId = languageTo?.id;
      const hasCeilingPrices = ceilingPrices[languageFromId];

      if (hasCeilingPrices && languageToId) {
        return hasCeilingPrices[languageToId]
          ? hasCeilingPrices[languageToId]?.max ?? false
          : false;
      } else {
        return false;
      }
    } else {
      const languageToId = languageTo?.id;
      const hasCeilingPrices = ceilingPrices[languageToId];

      return hasCeilingPrices ? hasCeilingPrices?.max ?? false : false;
    }
  };

  const resetOnUnmount = () => {
    setActiveService([]);
    setServiceRates({});
  };

  useEffect(() => {
    return () => {
      resetOnUnmount();
    };
  }, []);

  return {
    activeService,
    setActiveService,
    serviceRates,
    setServiceRates,
    handleServiceRates,
    removeService,
    addService,
    addLanguages,
    removeLanguage,
    onHandleChange,
    drawLanguages,
    getMaxPrice,
  };
}

export default useServices;
