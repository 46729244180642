import axiosInstance from "api/setupAxios";

const uploadApi = {
  getFileUrlApi: async ({ name, type, mimeType }) => {
    const { content } = await axiosInstance.post("/tempFile/upload", {
      name,
      type,
      mimeType,
    });
    return content.data;
  },
};

export default uploadApi;
