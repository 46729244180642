import React from "react";
import PropTypes from "prop-types";
import { Oval } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import useVendorForm from "./useVendorForm";
import PersonalDetailsForm from "./personalDetailsForm";
import QualificationsForm from "./qualifications";
import ContactDetailsForm from "./contactDetails";
import BankDetailsForm from "./bankDetailsForm";
import EmergencyContactForm from "./emergencyContact";

function VendorForm({ userDetails = {}, setUserDetails = () => {} }) {
  const [t] = useTranslation();

  const {
    onSubmit,
    isLoading,
    defaultsValues = {},
  } = useVendorForm({ vendorId: userDetails.vendorId, setUserDetails });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    unregister,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultsValues,
  });
  return (
    <React.Fragment>
      <form
        className="max-w-[869px] grid w-full mx-auto"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col items-center justify-center gap-[10px] mb-[30px] sm-max:mb-[10px]">
          <div className="base-black font-bold text-[36px] not-italic">
            {`${t("greetingFormTitle")} 🎉`}
          </div>
          <div className="base-black font-medium text-[18px] not-italic">
            {t("greetingFormSubTitle")}
          </div>
        </div>

        <PersonalDetailsForm
          register={register}
          setValue={setValue}
          errors={errors}
          watch={watch}
          getValues={getValues}
        />

        <QualificationsForm
          register={register}
          setValue={setValue}
          errors={errors}
          watch={watch}
          getValues={getValues}
        />

        <ContactDetailsForm
          register={register}
          setValue={setValue}
          errors={errors}
          watch={watch}
          getValues={getValues}
        />
        <BankDetailsForm
          register={register}
          setValue={setValue}
          errors={errors}
          watch={watch}
          getValues={getValues}
        />

        <EmergencyContactForm
          register={register}
          setValue={setValue}
          errors={errors}
          watch={watch}
          getValues={getValues}
        />
        <div className="sm-max:max-w-full mt-[40px]">
          <button
            type="submit"
            className="flex p-[18px] items-center h-[48px] justify-center ml-auto gap-[8px] rounded-[6px] bg-primary border-[1px] border-solid border-primary  cursor-pointer"
            disabled={isLoading}
          >
            <Oval
              height={30}
              width={30}
              color="#fff"
              visible={isLoading}
              ariaLabel="oval-loading"
              secondaryColor="#fff"
              strokeWidth={5}
              strokeWidthSecondary={3}
            />

            <div className="text-white font-bold text-[18px] not-italic">
              {t("saveAndSubmit")}
            </div>
          </button>
        </div>
      </form>
    </React.Fragment>
  );
}

VendorForm.propTypes = {
  userDetails: PropTypes.object,
  setUserDetails: PropTypes.func,
};

export default VendorForm;
