import { useEffect, useRef } from "react";
import { useLookupsContext } from "hooks/useContext";
import { useTranslation } from "react-i18next";
import profileApi from "api/profile";
import {
  LANGUAGE_MODEL_NONE,
  LANGUAGE_MODEL_PAIR,
  LANGUAGE_MODEL_SINGLE,
} from "utils/constantValues";
import useNotify from "hooks/useNotify";

/* eslint-disable array-callback-return */
function useProfileHelper(isVendor = false) {
  const [t] = useTranslation();
  const { services } = useLookupsContext(isVendor);
  const { notifyError } = useNotify();

  const profileCompletionRef = useRef({});

  async function downloadFileInANewTab(fileId) {
    try {
      const fileUrl = await profileApi.getFileUrlApi(fileId);
      window.open(fileUrl, "_blank");
    } catch (err) {
      console.error(err);
      notifyError(err?.data?.error ?? err?.message ?? "Something went wrong!");
    }
  }

  const onCountryTitle = (country, city, timezone) => {
    if (!country) {
      return "";
    }

    return `${city ? city + "," : ""} ${country} ${
      timezone ? "- " + timezone : ""
    }`;
  };

  function range(start, end) {
    if (start === end) return [start];
    return [start, ...range(start + 1, end)];
  }

  const defaultServiceRates = (userRates = []) => {
    const results = {};
    const serviceRateMap = userRates.map((serviceRate) => {
      const service = services.find(
        (elem) => elem.id === serviceRate.serviceId
      );
      const languages =
        serviceRate?.languageModel === LANGUAGE_MODEL_PAIR
          ? {
              fromLanguage: serviceRate?.fromLang?.id ?? "",
              toLanguage: serviceRate?.toLang?.id ?? "",
            }
          : serviceRate?.languageModel === LANGUAGE_MODEL_SINGLE
          ? { toLanguage: serviceRate?.toLang?.id ?? "" }
          : {};
      return {
        rate: serviceRate?.rate ?? 0,
        rateId: serviceRate.id,
        title: serviceRate.rateTitle,
        capacity: serviceRate?.capacity ?? "",
        serviceName: serviceRate.serviceTitle,
        serviceId: serviceRate.serviceId,
        hasMoreThanRate: service.type.length > 1,
        ratesLengthPerService: service.type.length,
        withRate: serviceRate?.withRate ?? true,
        withCapacity: serviceRate?.withCapacity ?? false,
        isPair: serviceRate?.languageModel === LANGUAGE_MODEL_PAIR,
        hasLanguages: serviceRate?.languageModel !== LANGUAGE_MODEL_NONE,
        languageModel: serviceRate?.languageModel ?? LANGUAGE_MODEL_NONE,
        ...languages,
      };
    });

    serviceRateMap.forEach((elem) => {
      if (!results[elem.serviceId]) {
        results[elem.serviceId] = [elem];
      } else {
        results[elem.serviceId] = [...results[elem.serviceId], elem];
      }
    });

    return results;
  };

  const defaultDrawLanguages = (userRates = []) => {
    const defaultRates = defaultServiceRates(userRates);
    const serviceIds = Object.keys(defaultRates);
    const typeLengths = serviceIds.map((serviceId, i) => {
      const findService = services.find((el) => el.id === serviceId);
      return {
        [i]: { length: findService.type.length, serviceId },
      };
    });

    return typeLengths.reduce((acc, elem, index) => {
      const serviceId = elem[index].serviceId;
      const serviceTypeLengths = elem[index].length;

      const currentRateTypes = defaultRates[serviceId].length; //9
      const calcDefaultLanguages = Math.ceil(currentRateTypes / serviceTypeLengths);

      acc[serviceId] = range(0, calcDefaultLanguages - 1);

      return acc;
    }, {});
  };

  const handleServiceRateLabels = (serviceId, serviceRates = []) => {
    const results = serviceRates.reduce((acc, elem) => {
      if (
        serviceId === elem.serviceId
        //  &&
        // !Number.isNaN(Number(elem.rate)) &&
        // elem.rate > 0
      ) {
        acc.push({ ...elem });
      }

      return acc;
    }, []);

    return results;
  };

  const displayLabel = (displayFirstServiceRate = {}, isLabel = true) => {
    const serviceTitle = `${t(displayFirstServiceRate.serviceTitle)}`;
    const fromLang = displayFirstServiceRate?.fromLang?.code ?? "";
    const toLang = displayFirstServiceRate?.toLang?.code ?? "";

    const language = fromLang
      ? `${isLabel ? " - " : ""}${fromLang} > ${toLang}`
      : toLang
      ? `${isLabel ? " - " : ""}${toLang}`
      : "";

    return isLabel ? `${serviceTitle} ${language}` : `${language}`;
  };

  useEffect(() => {
    return () => {
      profileCompletionRef.current = {};
    };
  }, []);

  return {
    onCountryTitle,
    handleServiceRateLabels,
    defaultServiceRates,
    defaultDrawLanguages,
    displayLabel,
    downloadFileInANewTab,
    profileCompletionRef: profileCompletionRef.current,
  };
}

export default useProfileHelper;
