import React, { useReducer } from "react";
import { lookupsContext } from "context/createContext";
import lookupsApi from "api/lookups";
import {
  GET_COUNTRIES,
  GET_CITIES,
  GET_DOMAINS,
  GET_LANGUAGES_LOOKUPS,
  GET_NATIONALITIES,
  GET_SERVICES,
  GET_TIMEZONES,
  RESET_LOOKUPS,
  GET_SUB_DOMAINS,
  GET_CURRENCIES,
} from "context/types";
import LookupsReducer, { initialState } from "./lookupsReducer";

const LookupsState = ({ children }) => {
  const [state, dispatch] = useReducer(LookupsReducer, initialState);

  const getCountries = async () => {
    try {
      const countries = await lookupsApi.getCountriesApi();
      dispatch({ type: GET_COUNTRIES, payload: countries });
    } catch (e) {
      console.error(e);
      dispatch({ type: GET_COUNTRIES, payload: [] });
    }
  };

  const getCities = async (countryId) => {
    try {
      const cities = await lookupsApi.getCitiesApi(countryId);
      dispatch({ type: GET_CITIES, payload: cities });
    } catch (e) {
      console.error(e);
      dispatch({ type: GET_CITIES, payload: [] });
    }
  };

  const getDomains = async () => {
    try {
      const domains = await lookupsApi.getDomainsApi();
      dispatch({ type: GET_DOMAINS, payload: domains });
    } catch (e) {
      console.error(e);
      dispatch({ type: GET_DOMAINS, payload: [] });
    }
  };

  const getSubDomains = async (domainIds) => {
    try {
      const subDomains = await lookupsApi.getSubDomains(domainIds);
      dispatch({ type: GET_SUB_DOMAINS, payload: subDomains });
    } catch (e) {
      console.error(e);
      dispatch({ type: GET_CITIES, payload: [] });
    }
  };

  const getLanguages = async () => {
    try {
      const languages = await lookupsApi.getLanguagesAPi();
      dispatch({ type: GET_LANGUAGES_LOOKUPS, payload: languages });
    } catch (e) {
      console.error(e);
      dispatch({
        type: GET_LANGUAGES_LOOKUPS,
        payload: [],
      });
    }
  };

  const getNationalities = async () => {
    try {
      const nationalities = await lookupsApi.getNationalitiesApi();
      dispatch({ type: GET_NATIONALITIES, payload: nationalities });
    } catch (e) {
      console.error(e);
      dispatch({ type: GET_NATIONALITIES, payload: [] });
    }
  };

  const getServices = async () => {
    try {
      const services = await lookupsApi.getServicesApi();
      dispatch({ type: GET_SERVICES, payload: services });
    } catch (e) {
      console.error(e);
      dispatch({ type: GET_SERVICES, payload: [] });
    }
  };

  const getTimeZones = async (countryId) => {
    try {
      const timezones = await lookupsApi.getTimezonesApi(countryId);
      dispatch({ type: GET_TIMEZONES, payload: timezones });
    } catch (e) {
      console.error(e);
      dispatch({ type: GET_TIMEZONES, payload: [] });
    }
  };

  const getCurrencies = async () => {
    try {
      const currencies = await lookupsApi.getCurrencies();
      dispatch({ type: GET_CURRENCIES, payload: currencies });
    } catch (e) {
      console.error(e);
      dispatch({ type: GET_CURRENCIES, payload: [] });
    }
  };
  const resetLookups = () => dispatch({ type: RESET_LOOKUPS });

  return (
    <lookupsContext.Provider
      value={{
        ...state,
        getCountries,
        getCities,
        getDomains,
        getLanguages,
        getNationalities,
        getServices,
        getTimeZones,
        resetLookups,
        getSubDomains,
        getCurrencies,
      }}
    >
      {children}
    </lookupsContext.Provider>
  );
};

export default LookupsState;
