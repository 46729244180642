import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as CloseIcon } from "images/auth/Close.svg";

function Chips({
  onClick = () => {},
  chips = [],
  chipsContainerClassName = "",
  chipsContentClassName = "",
  chipLabelClassName = "",
}) {
  return (
    <div
      className={`${
        chipsContainerClassName
          ? chipsContainerClassName
          : "flex flex-wrap gap-[10px]"
      }`}
    >
      {chips.map((chipLabel, index) => (
        <div
          key={chipLabel}
          className={`${
            chipsContentClassName
              ? chipsContentClassName
              : "flex min-h-[43px] p-[12px] justify-center items-center gap-[4px] rounded-[34px] border-[1px] border-solid border-default-color bg-white"
          }`}
        >
          <div
            className={`${
              chipLabelClassName
                ? chipLabelClassName
                : "text-gray-700 font-semibold text-[14px] not-italic"
            }`}
          >
            {chipLabel}
          </div>
          <CloseIcon
            className="cursor-pointer"
            onClick={() => onClick(chipLabel, index)}
          />
        </div>
      ))}
    </div>
  );
}

Chips.propTypes = {
  onClick: PropTypes.func,
  chips: PropTypes.array,
  chipsContainerClassName: PropTypes.string,
  chipsContentClassName: PropTypes.string,
  chipLabelClassName: PropTypes.string,
};

export default Chips;
