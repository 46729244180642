import React from "react";
import PropTypes from "prop-types";
import Greeting from "pages/profile/common/greeting";
import {
  STATUS_QUALIFIED,
  SUBSTATUS_ONBOARDING_REQUEST,
  STATUS_ACTIVE,
  STATUS_BLACKLIST,
  STATUS_SUSPENDED,
  SUBSTATUS_READY_FOR_WORK,
  SUBSTATUS_ONBOARDING_DONE,
  SUBSTATUS_PENDING_INVESTIGATION,
  SUBSTATUS_DO_NOT_USE,
  SUBSTATUS_AGREEMENT_SENT,
  SUBSTATUS_EXPIRED_AGREEMENT,
  SUBSTATUS_DECLINED_AGREEMENT,
  SUBSTATUS_UNDER_VALIDATION,
} from "utils/constantValues";
import useProfileHelper from "../useProfileHelper";
import { useTranslation } from "react-i18next";
import ProfileExperience from "../common/profileExperience";
import ProfileServices from "../common/profileServices";
import EditProfileServices from "../common/profileServices/editProfileServices";
import ProfileCertification from "../common/profileCertification";
import EditProfileCertifications from "../common/profileCertification/editProfileCertification";
import CompanyDetails from "./companyDetails";
import EditCompanyDetails from "./companyDetails/editCompanyDetails";
import PortfolioFiles from "./portfolioFiles";
import EditPortfolioFiles from "./portfolioFiles/editPortfolioFiles";
import RateCardFile from "./rateCardFile";
import EditRateCardFile from "./rateCardFile/editRateCardFile";
import useCompanyDetails from "./companyDetails/useCompanyDetails";
import usePersonalExperience from "../common/profileExperience/usePersonalExperience";
import EditPersonalExperience from "../common/profileExperience/editExperience";
import useProfileCertifications from "../common/profileCertification/useProfileCertifications";
import usePortfolioFiles from "./portfolioFiles/usePortfolio";
import useRateCardFile from "./rateCardFile/useRateCardFile";

import useProfileServices from "../common/profileServices/useProfileServices";
import { scrollIntoView } from "utils/helper";
import AgencyForm from "pages/profile/agencyProfile/agencyForm";
import useBankDetails from "./bankDetails/useBankDetails";
import EditBankDetails from "./bankDetails/editBankDetails";
import BankDetails from "./bankDetails";
import useContactDetails from "./contactDetails/useContactDertails";
import EditContactDetails from "./contactDetails/editContactDetails";
import ContactDetails from "./contactDetails";
import VmFileDocuments from "../common/vmFileDocuments";
import ProfileCompletion from "components/profileCompletion";
import useProfileCompletion from "hooks/useProfileCompletion";
import useMultiCVs from "./multiCvs/useMultiCVs";
import EditMultiCvs from "./multiCvs/editMultiCvs";
import MultiCvsComponent from "./multiCvs/index";

function AgencyProfile({
  userDetails = {},
  setUserDetails = () => {},
  userDomains = [],
  setUserDomains = () => {},
  userSubDomains = [],
  setUserSubDomains = () => {},
}) {
  const [t] = useTranslation();
  const { onCountryTitle, profileCompletionRef } = useProfileHelper();

  const showOnboardingForum =
    [
      STATUS_ACTIVE,
      STATUS_QUALIFIED,
      STATUS_BLACKLIST,
      STATUS_SUSPENDED,
    ].includes(userDetails?.status) &&
    [
      SUBSTATUS_READY_FOR_WORK,
      SUBSTATUS_ONBOARDING_DONE,
      SUBSTATUS_PENDING_INVESTIGATION,
      SUBSTATUS_DO_NOT_USE,
      SUBSTATUS_AGREEMENT_SENT,
      SUBSTATUS_DECLINED_AGREEMENT,
      SUBSTATUS_EXPIRED_AGREEMENT,
      SUBSTATUS_UNDER_VALIDATION,
    ].includes(userDetails?.subStatus);

  const isShowEditIcon = ![STATUS_BLACKLIST, STATUS_SUSPENDED].includes(
    userDetails?.status
  );

  const {
    isEditCompanyDetails,
    setIsEditCompanyDetails,
    onEditSave,
    isLoading,
  } = useCompanyDetails({ userDetails, profileCompletionRef });

  const {
    isEditPersonalExperience,
    setIsEditPersonalExperience,
    onEditPersonalExperience,
    isExperienceLoading,
  } = usePersonalExperience({
    userDetails,
    profileCompletionRef,
    userDomains,
    userSubDomains,
  });

  const {
    isEditCertificationFiles,
    setIsEditCertificationFiles,
    onEditCertificationsFiles,
    isCertificationLoading,
  } = useProfileCertifications({ userDetails, profileCompletionRef });

  const {
    onEditPortfolioFiles,
    isEditPortfolioFiles,
    setIsEditPortfolioFiles,
    isPortfolioLoading,
  } = usePortfolioFiles({ userDetails, profileCompletionRef });

  const {
    isEditRateCard,
    setIsEditRateCard,
    onEditRateCardFile,
    isRateCardLoading,
  } = useRateCardFile({ userDetails, profileCompletionRef });

  const {
    isEditMultiCvs,
    setIsEditMultiCvs,
    onEditMultiCvs,
    isMultiCvsLoading,
  } = useMultiCVs({ userDetails });

  const {
    isEditServices,
    setIsEditServices,
    onEditServices,
    isProfileServicesLoading,
  } = useProfileServices({ userDetails, profileCompletionRef });

  const {
    isEditBankDetails,
    setIsEditBankDetails,
    onEditBankDetails,
    isBankDetailsLoading,
  } = useBankDetails({ userDetails, profileCompletionRef });

  const {
    isEditContactDetails,
    setIsEditContactDetails,
    onEditContactDetails,
    isContactDetailsLoading,
  } = useContactDetails({ userDetails, profileCompletionRef });

  const {
    sections,
    allSectionPercentages,
    onOpenEditSection,
    bounceToSection,
  } = useProfileCompletion({
    userDetails,
    profileCompletionRef,
    showOnboardingForum,
    isShowEditIcon,
  });

  if (
    userDetails?.status === STATUS_QUALIFIED &&
    userDetails?.subStatus === SUBSTATUS_ONBOARDING_REQUEST
  ) {
    return (
      <AgencyForm userDetails={userDetails} setUserDetails={setUserDetails} />
    );
  }

  return (
    <div className="grid md-max:flex md-max:flex-1 grid-cols-custom-grid-profile md-max:flex-col-reverse  gap-[24px] md-max:gap-[10px] ">
      <div className="grid w-full">
        <div className="md-max:hidden grid w-full">
          <Greeting
            title={t("greetingTitle", {
              title: `${userDetails?.companyName ?? ""}`,
            })}
            email={userDetails?.email}
            subTitle={onCountryTitle(
              userDetails.country?.name,
              userDetails.city?.name,
              userDetails.timezone?.name
            )}
          />
        </div>
        <div className="grid w-full" id="companyDetailsScrollId">
          {isEditCompanyDetails ? (
            <EditCompanyDetails
              userDetails={userDetails}
              onDiscard={() => {
                setIsEditCompanyDetails(false);
                scrollIntoView("companyDetailsScrollId");
              }}
              onSave={(values) => onEditSave(values, setUserDetails)}
              isLoading={isLoading}
            />
          ) : (
            <CompanyDetails
              isShowEditIcon={isShowEditIcon}
              userDetails={userDetails}
              onEditClick={() => setIsEditCompanyDetails(true)}
            />
          )}
        </div>

        {showOnboardingForum && (
          <div className="grid w-full" id="bankDetailsScrollId">
            {isEditBankDetails ? (
              <EditBankDetails
                userDetails={userDetails}
                onDiscard={() => {
                  setIsEditBankDetails(false);
                  scrollIntoView("bankDetailsScrollId");
                }}
                onSave={(values) => onEditBankDetails(values, setUserDetails)}
                isLoading={isBankDetailsLoading}
              />
            ) : (
              <BankDetails
                isShowEditIcon={isShowEditIcon}
                userDetails={userDetails}
                onEditClick={() => setIsEditBankDetails(true)}
              />
            )}
          </div>
        )}

        {showOnboardingForum && (
          <div className="grid w-full" id="contactDetailsScrollId">
            {isEditContactDetails ? (
              <EditContactDetails
                userDetails={userDetails}
                onDiscard={() => {
                  setIsEditContactDetails(false);
                  scrollIntoView("contactDetailsScrollId");
                }}
                onSave={(values) =>
                  onEditContactDetails(values, setUserDetails)
                }
                isLoading={isContactDetailsLoading}
              />
            ) : (
              <ContactDetails
                isShowEditIcon={isShowEditIcon}
                userDetails={userDetails}
                onEditClick={() => setIsEditContactDetails(true)}
              />
            )}
          </div>
        )}

        <div className="grid w-full" id="experienceScrollId">
          {isEditPersonalExperience ? (
            <EditPersonalExperience
              userDetails={userDetails}
              onDiscard={() => {
                setIsEditPersonalExperience(false);
                scrollIntoView("experienceScrollId");
              }}
              onSave={(values) =>
                onEditPersonalExperience(
                  values,
                  setUserDetails,
                  setUserDomains,
                  setUserSubDomains
                )
              }
              isLoading={isExperienceLoading}
              userDomains={userDomains}
              userSubDomains={userSubDomains}
              setUserDomains={setUserDomains}
              setUserSubDomains={setUserSubDomains}
            />
          ) : (
            <ProfileExperience
              isShowEditIcon={isShowEditIcon}
              userDetails={userDetails}
              userDomains={userDomains}
              userSubDomains={userSubDomains}
              onEditClick={() => setIsEditPersonalExperience(true)}
            />
          )}
        </div>

        <div className="grid w-full" id="profileServiceScrollId">
          {isEditServices ? (
            <EditProfileServices
              userDetails={userDetails}
              isVendor={false}
              onDiscard={() => {
                setIsEditServices(false);
                scrollIntoView("profileServiceScrollId");
              }}
              onSave={(values) => onEditServices(values, setUserDetails)}
              isLoading={isProfileServicesLoading}
            />
          ) : (
            <ProfileServices
              isShowEditIcon={isShowEditIcon}
              userDetails={userDetails}
              isVendor={false}
              onEditClick={() => setIsEditServices(true)}
            />
          )}
        </div>
      </div>

      <div className="flex flex-col gap-[10px]">
        <ProfileCompletion
          sections={sections}
          allSectionPercentages={allSectionPercentages}
          onOpenEditSection={onOpenEditSection}
          bounceToSection={bounceToSection}
        />
        <div className="grid w-full" id="portfolioScrollId">
          {isEditPortfolioFiles ? (
            <EditPortfolioFiles
              userDetails={userDetails}
              onDiscard={() => {
                setIsEditPortfolioFiles(false);
                scrollIntoView("portfolioScrollId");
              }}
              onSave={(values) => onEditPortfolioFiles(values, setUserDetails)}
              isLoading={isPortfolioLoading}
            />
          ) : (
            <PortfolioFiles
              userDetails={userDetails}
              onEditClick={() => setIsEditPortfolioFiles(true)}
              isShowEditIcon={isShowEditIcon}
            />
          )}
        </div>
        <div className="grid w-full" id="certificationScrollId">
          {isEditCertificationFiles ? (
            <EditProfileCertifications
              userDetails={userDetails}
              onDiscard={() => {
                setIsEditCertificationFiles(false);
                scrollIntoView("certificationScrollId");
              }}
              onSave={(values) =>
                onEditCertificationsFiles(values, setUserDetails)
              }
              isLoading={isCertificationLoading}
            />
          ) : (
            <ProfileCertification
              userDetails={userDetails}
              onEditClick={() => setIsEditCertificationFiles(true)}
              isShowEditIcon={isShowEditIcon}
            />
          )}
        </div>
        <div className="grid w-full" id="rateCardScrollId">
          {isEditRateCard ? (
            <EditRateCardFile
              userDetails={userDetails}
              onDiscard={() => {
                setIsEditRateCard(false);
                scrollIntoView("rateCardScrollId");
              }}
              onSave={(values) => onEditRateCardFile(values, setUserDetails)}
              isLoading={isRateCardLoading}
            />
          ) : (
            <RateCardFile
              userDetails={userDetails}
              onEditClick={() => setIsEditRateCard(true)}
              isShowEditIcon={isShowEditIcon}
            />
          )}
        </div>
        <div className="grid w-full" id="multiCvsScrollId">
          {isEditMultiCvs ? (
            <EditMultiCvs
              userDetails={userDetails}
              onDiscard={() => {
                setIsEditMultiCvs(false);
                scrollIntoView("multiCvsScrollId");
              }}
              onSave={(values) => onEditMultiCvs(values, setUserDetails)}
              isLoading={isMultiCvsLoading}
            />
          ) : (
            <MultiCvsComponent
              userDetails={userDetails}
              onEditClick={() => setIsEditMultiCvs(true)}
              isShowEditIcon={isShowEditIcon}
            />
          )}
        </div>
        <div className="grid w-full">
          <VmFileDocuments userDetails={userDetails} />
        </div>
      </div>
      <div className="hidden md-max:grid w-full">
        <Greeting
          title={`Welcome ${userDetails?.name ?? ""}`}
          subTitle={onCountryTitle(
            userDetails.country?.name,
            userDetails.city?.name,
            userDetails.timezone?.name
          )}
        />
      </div>
    </div>
  );
}

AgencyProfile.propTypes = {
  userDetails: PropTypes.object,
  setUserDetails: PropTypes.func,
  userDomains: PropTypes.array,
  setUserDomains: PropTypes.func,
  userSubDomains: PropTypes.array,
  setUserSubDomains: PropTypes.func,
};

export default AgencyProfile;
