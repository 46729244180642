import {
  GET_COUNTRIES,
  GET_CITIES,
  GET_DOMAINS,
  GET_LANGUAGES_LOOKUPS,
  GET_NATIONALITIES,
  GET_SERVICES,
  GET_TIMEZONES,
  RESET_LOOKUPS,
  GET_SUB_DOMAINS,
  GET_CURRENCIES,
} from "context/types";

export const initialState = {
  countries: [],
  cities: [],
  domains: [],
  subDomains: [],
  languages: [],
  nationalities: [],
  services: [],
  timezones: [],
  currencies: [],
};

const LookupsReducer = (state, action) => {
  switch (action.type) {
    case GET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };

    case GET_CITIES:
      return {
        ...state,
        cities: action.payload,
      };

    case GET_DOMAINS:
      return {
        ...state,
        domains: action.payload,
      };

    case GET_SUB_DOMAINS:
      return {
        ...state,
        subDomains: action.payload,
      };

    case GET_LANGUAGES_LOOKUPS:
      return {
        ...state,
        languages: action.payload,
      };

    case GET_NATIONALITIES:
      return {
        ...state,
        nationalities: action.payload,
      };

    case GET_SERVICES:
      return {
        ...state,
        services: action.payload,
      };

    case GET_TIMEZONES:
      return {
        ...state,
        timezones: action.payload,
      };

    case GET_CURRENCIES:
      return {
        ...state,
        currencies: action.payload,
      };
    case RESET_LOOKUPS:
      return {
        countries: [],
        cities: [],
        domains: [],
        languages: [],
        nationalities: [],
        services: [],
        timezones: [],
        currencies: [],
      };
    default:
      return state;
  }
};

export default LookupsReducer;
