import { useState, useEffect } from "react";

function usePersonalProfile() {
  const [availableDays, setAvailableDays] = useState([]);
  const [profiles, setProfiles] = useState([0]);

  const addAnotherProfile = (index) => {
    setProfiles([...profiles, index]);
  };

  const removeProfile = (index) => {
    setProfiles(profiles.filter((_, i) => i !== index));
  };
  const resetOnUnmount = () => {
    setAvailableDays([]);
    setProfiles([0]);
  };
  useEffect(() => {
    return () => {
      resetOnUnmount();
    };
  }, []);

  return {
    availableDays,
    setAvailableDays,
    addAnotherProfile,
    profiles,
    removeProfile,
  };
}

export default usePersonalProfile;
