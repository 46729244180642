import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-tooltip";
import { ReactComponent as RoundedCheckboxBase } from "images/auth/rounded-checkbox-base.svg";
import { ReactComponent as RoundedCheckboxActive } from "images/auth/rounded-checkbox-active.svg";
import { ReactComponent as InfoIcon } from "images/profile/info-circle.svg";

function CardCheckbox({
  title,
  hideCheckIcon = false,
  isActive,
  onClick = () => {},
  containerClassName = "",
  titleClassName = "",
  isActiveClassName = "",
  hoverContent = "",
  isShowInfoIcon,
  tooltipId = "",
  tooltipIconId,
}) {
  return (
    <div
      onClick={onClick}
      className={`${
        containerClassName
          ? containerClassName
          : "flex items-center justify-center gap-[10px] self-stretch py-[22px] px-[12px] cursor-pointer"
      } ${
        isActiveClassName
          ? isActiveClassName
          : isActive
          ? "rounded-[12px] border-[1px] border-solid border-secondary bg-light-primary"
          : "rounded-[12px] border-[1px] border-solid border-default-color bg-white"
      }`}
    >
      {!hideCheckIcon ? (
        isActive ? (
          <RoundedCheckboxActive className="mt-[-3px]" />
        ) : (
          <RoundedCheckboxBase className="mt-[-3px]" />
        )
      ) : null}
      <div className="flex gap-[4px] items-center">
        <div
          data-tooltip-id={tooltipId}
          className={
            titleClassName
              ? titleClassName
              : "text-primary font-medium text-[16px] not-italic"
          }
        >
          {" "}
          {title}
        </div>

        {isShowInfoIcon && (
          <div
            data-tooltip-id={tooltipIconId}
            className="w-[12px] h-[10px] mt-[-2px] cursor-pointer"
          >
            <InfoIcon />
          </div>
        )}

        {hoverContent && (
          <Tooltip
            className="bg-base-black rounded-[8px] opacity-[1]"
            place="top"
            id={isShowInfoIcon ? tooltipIconId : tooltipId}
            content={hoverContent}
          />
        )}
      </div>
    </div>
  );
}

CardCheckbox.propTypes = {
  title: PropTypes.any,
  hideCheckIcon: PropTypes.bool,
  isActive: PropTypes.bool,
  containerClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  onClick: PropTypes.func,
  isActiveClassName: PropTypes.string,
  hoverContent: PropTypes.any,
  isShowInfoIcon: PropTypes.bool,
  tooltipId: PropTypes.string,
};

export default CardCheckbox;
