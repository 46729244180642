import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import StartJourney from "./startJourney";
import PersonalProfile from "pages/signup/form/common/personalProfile";
import Experience from "./experience";
import Certifications from "../common/certifications";
import Services from "components/serviceForm";

function VendorForm({
  startPointRef,
  endPointRef,
  register = () => {},
  unregister = () => {},
  setValue = () => {},
  errors = {},
  watch = () => {},
  data = {},
  getValues = () => {},
}) {
  const [t] = useTranslation();

  return (
    <Fragment>
      <StartJourney
        register={register}
        errors={errors}
        setValue={setValue}
        ref={startPointRef}
        watch={watch}
      />

      <div className="w-[10px] h-[10px] bg-primary rounded-[50%] mt-[30px] ml-[20px]" />
      <PersonalProfile
        data={data}
        register={register}
        watch={watch}
        setValue={setValue}
        errors={errors}
        getValues={getValues}
        isVendor={true}
      />
      <div className="w-[10px] h-[10px] bg-primary rounded-[50%] mt-[30px] ml-[20px]" />
      <Experience
        data={data}
        register={register}
        watch={watch}
        setValue={setValue}
        errors={errors}
        getValues={getValues}
      />
      <div className="w-[10px] h-[10px] bg-primary rounded-[50%] mt-[30px] ml-[20px]" />

      <Certifications register={register} setValue={setValue} />
      <div
        className="w-[10px] h-[10px] bg-primary rounded-[50%] mt-[30px] ml-[20px]"
        ref={endPointRef}
      />

      <Services
        data={data}
        register={register}
        watch={watch}
        setValue={setValue}
        errors={errors}
        getValues={getValues}
        label={t("applyingFor")}
        unregister={unregister}
        isVendor={true}
      />
    </Fragment>
  );
}

VendorForm.propTypes = {
  label: PropTypes.string,
  startPointRef: PropTypes.any,
  endPointRef: PropTypes.any,
  register: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  watch: PropTypes.func,
  data: PropTypes.object,
  getValues: PropTypes.func,
  unregister: PropTypes.func,
};

export default VendorForm;
