/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import AccountType from "pages/signup/accountType";
import SignUpForm from "pages/signup/form";
import { steps, stepsContent } from "pages/signup/constants";
import useSignup from "pages/signup/hooks/useSignup";
import { useNavigate, useParams } from "react-router-dom";

const SignupPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const {
    onSubmit,
    isLoading,
    accountType,
    setAccountType,
    defaultsValues,
    isVendor,
  } = useSignup();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!steps.includes(params.accountType)) {
      navigate("/signup");
    }
  }, [params.accountType]);
  return (
    <Fragment>
      {!steps.includes(params.accountType) && (
        <AccountType
          steps={steps}
          stepsContent={stepsContent}
          setAccountType={setAccountType}
          accountType={accountType}
        />
      )}
      {steps.includes(params.accountType) && (
        <SignUpForm
          onSubmit={onSubmit}
          isLoading={isLoading}
          defaultsValues={defaultsValues}
          isVendor={isVendor}
        />
      )}
    </Fragment>
  );
};

export default SignupPage;
