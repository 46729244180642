import React from "react";
import { useTranslation } from "react-i18next";
import Select from "components/select";
import Input from "components/input";
import PropTypes from "prop-types";
import usePersonalDetailsForm from "./usePersonalDetailsForm";
import DatePicker from "components/datePicker";

function PersonalDetailsForm({
  register = () => {},
  setValue = () => {},
  errors = {},
  watch = () => {},
  title = "",
  containerClassName = "flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]",
}) {
  const [t] = useTranslation();

  const defaultDateOfBirth = watch("dateOfBirth");
  const defaultExpiryDate = watch("expiryDate");
  const {
    GENDER_OPTIONS,
    dateOfBirth,
    setDateOfBirth,
    MATERIAL_STATUS_OPTIONS,
    countriesOptions,
    expiryDate,
    setExpiryDate,
  } = usePersonalDetailsForm({ defaultDateOfBirth, defaultExpiryDate });
  return (
    <div className={containerClassName}>
      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        <div className="text-primary font-semibold text-[30px] not-italic">
          {title || t("personalDetailsTitle")}
        </div>
      </div>

      <div className="flex flex-wrap gap-[20px]">
        <Select
          label={t("gender")}
          containerClassName="flex flex-1 flex-col gap-[10px] min-w-[250px] sm-max:w-full"
          placeholder={t("placeholderSelect", {
            field: t("gender").toLowerCase(),
          })}
          name={"gender"}
          value={
            watch("gender")
              ? GENDER_OPTIONS.find((el) => el.value === watch("gender"))
              : null
          }
          onChange={(e) => setValue("gender", e?.value ?? "")}
          isRequired={true}
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("gender") }),
          }}
          errorMessage={errors.gender ? errors.gender.message : ""}
          options={GENDER_OPTIONS}
        />

        <DatePicker
          label={t("dateOfBirth")}
          name="dateOfBirth"
          placeholder={t("dateOfBirthPlaceholder")}
          isRequired={true}
          register={register}
          value={dateOfBirth}
          maxDate={new Date()}
          onValueChange={(date) => {
            setDateOfBirth(date);
            setValue("dateOfBirth", date);
          }}
          registerArgs={{
            required: t("errorField", { field: t("dateOfBirth") }),
          }}
          errorMessage={errors.dateOfBirth ? errors.dateOfBirth.message : ""}
          labelClassName="text-primary font-semibold text-[16px]"
          customInputStyle={`flex h-[43px] min-w-[250px] w-full sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.dateOfBirth && !dateOfBirth
              ? "border-red"
              : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />

        <Select
          label={t("martialStatus")}
          containerClassName="flex flex-col flex-1 gap-[10px] min-w-[250px] sm-max:w-full"
          placeholder={t("placeholderSelect", {
            field: t("martialStatus").toLowerCase(),
          })}
          name={"martialStatus"}
          value={
            watch("martialStatus")
              ? MATERIAL_STATUS_OPTIONS.find(
                  (el) => el.value === watch("martialStatus")
                )
              : null
          }
          onChange={(e) => setValue("martialStatus", e?.value ?? "")}
          isRequired={true}
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("martialStatus") }),
          }}
          errorMessage={
            errors.martialStatus ? errors.martialStatus.message : ""
          }
          options={MATERIAL_STATUS_OPTIONS}
        />

        <Select
          label={t("countryOfBirth")}
          placeholder={t("placeholderSelect", {
            field: t("countryOfBirth").toLowerCase(),
          })}
          name={"countryOfBirth"}
          containerClassName="flex flex-1 flex-col gap-[10px] min-w-[250px] sm-max:w-full"
          value={
            watch("countryOfBirth")
              ? countriesOptions.find(
                  (elem) => elem.name === watch("countryOfBirth")
                )
              : null
          }
          onChange={(e) => {
            setValue("countryOfBirth", e.label);
          }}
          isRequired={true}
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("countryOfBirth") }),
          }}
          errorMessage={
            errors.countryOfBirth ? errors.countryOfBirth.message : ""
          }
          options={countriesOptions}
        />

        <Input
          label={t("placeOfBirth")}
          isRequired={true}
          placeholder={t("placeHolderType", {
            title: t("placeOfBirth").toLowerCase(),
          })}
          name="placeOfBirth"
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("placeOfBirth") }),
          }}
          errorMessage={errors.placeOfBirth ? errors.placeOfBirth.message : ""}
          containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.placeOfBirth ? "border-red" : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />

        <Input
          label={t("socialSecurityNumber")}
          isRequired={true}
          placeholder={t("placeHolderType", {
            title: t("socialSecurityNumber").toLowerCase(),
          })}
          name="socialSecurityNo"
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("socialSecurityNumber") }),
          }}
          errorMessage={
            errors.socialSecurityNo ? errors.socialSecurityNo.message : ""
          }
          containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.socialSecurityNo ? "border-red" : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />

        <Input
          label={t("nationalCardNo")}
          isRequired={true}
          placeholder={t("placeHolderType", {
            title: t("nationalCardNo").toLowerCase(),
          })}
          name="nationalCardNo"
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("nationalCardNo") }),
          }}
          errorMessage={
            errors.nationalCardNo ? errors.nationalCardNo.message : ""
          }
          containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.nationalCardNo ? "border-red" : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />

        <Input
          label={t("passportNo")}
          isRequired={true}
          placeholder={t("placeHolderType", {
            title: t("passportNo").toLowerCase(),
          })}
          name="passportNo"
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("passportNo") }),
          }}
          errorMessage={errors.passportNo ? errors.passportNo.message : ""}
          containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.passportNo ? "border-red" : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />

        <DatePicker
          label={t("expiryDate")}
          name="expiryDate"
          placeholder={t("dateOfBirthPlaceholder")}
          isRequired={true}
          register={register}
          value={expiryDate}
          onValueChange={(date) => {
            setExpiryDate(date);
            setValue("expiryDate", date);
          }}
          registerArgs={{
            required: t("errorField", { field: t("expiryDate") }),
          }}
          errorMessage={errors.expiryDate ? errors.expiryDate.message : ""}
          labelClassName="text-primary font-semibold text-[16px]"
          customInputStyle={`flex h-[43px] min-w-[250px] w-full sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.expiryDate && !expiryDate
              ? "border-red"
              : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />
        <div className="grid grid-cols-2 sm-max:grid-cols-1  w-full gap-[16px]">
          <Input
            label={t("countryOfIssue")}
            isRequired={true}
            placeholder={t("placeHolderType", {
              title: t("countryOfIssue").toLowerCase(),
            })}
            name="countryOfIssue"
            register={register}
            registerArgs={{
              required: t("errorField", { field: t("countryOfIssue") }),
            }}
            errorMessage={
              errors.countryOfIssue ? errors.countryOfIssue.message : ""
            }
            labelClassName="text-primary font-semibold text-[16px]"
            inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
              errors.countryOfIssue ? "border-red" : "border-default-color"
            } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
          />

          <Input
            label={t("placeOfIssue")}
            isRequired={true}
            placeholder={t("placeHolderType", {
              title: t("placeOfIssue").toLowerCase(),
            })}
            name="placeOfIssue"
            register={register}
            registerArgs={{
              required: t("errorField", { field: t("placeOfIssue") }),
            }}
            errorMessage={
              errors.placeOfIssue ? errors.placeOfIssue.message : ""
            }
            labelClassName="text-primary font-semibold text-[16px]"
            inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
              errors.placeOfIssue ? "border-red" : "border-default-color"
            } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
          />
        </div>
      </div>
    </div>
  );
}
PersonalDetailsForm.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  watch: PropTypes.func,
  title: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default PersonalDetailsForm;
