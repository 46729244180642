import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Oval } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import Input from "components/input";
import CardCheckbox from "components/cardCheckbox";
import { useTranslation } from "react-i18next";
import useTime from "hooks/useTime";
import Select from "components/select";
import { validateEmail } from "utils/helper";
import usePersonalProfile from "./usePersonalProfile";

import {
  availableDaysOptions,
  availableDaysOptionsMapper,
  timesOptions,
} from "utils/constantValues";
import TimePicker from "components/timePicker";

function EditPersonalDetails({
  userDetails = {},
  onDiscard = () => {},
  onSave = () => {},
  isLoading = false,
}) {
  const [t] = useTranslation();

  const {
    firstName,
    lastName,
    userNationalities,
    country,
    city,
    timezone,
    phone,
    availability,
  } = userDetails;

  const defaultsValues = {
    firstName: firstName || "",
    lastName: lastName || "",
    nationalities: userNationalities.map((elem) => elem.id),
    country: country?.id ?? "",
    city: city?.id ?? "",
    timezone: timezone?.id ?? "",
    phoneNumber: phone || "",
    availability: {
      days: availability?.days ?? [],
      time: {
        from: availability?.time?.from ?? "",
        to: availability?.time?.to ?? "",
      },
    },
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultsValues,
  });

  const countryId = watch("country");

  const {
    cities,
    timezones,
    nationalitiesOptions,
    countriesOptions,
    citiesOptions,
    timezonesOptions,
    availableDays,
    setAvailableDays,
  } = usePersonalProfile({ userDetails, countryId, availability });

  const {
    onMeridiemClick,
    meridiem,
    timeFrom,
    setTimeFrom,
    timeTo,
    setTimeTo,
    convertTimeTo24h,
    isTimeAfter,
  } = useTime(availability?.time);

  return (
    <form
      className="flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]"
      onSubmit={handleSubmit(onSave)}
    >
      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        {/* ************** PERSONAL DETAILS SECTION => START ***************/}
        <div className="text-primary font-semibold text-[30px] not-italic">
          {t("personalDetailsTitle")}
        </div>
      </div>

      <div className="flex flex-wrap gap-[20px]">
        {/* ************** FIRST NAME ***************/}
        <Input
          label={t("firstName")}
          isRequired={true}
          placeholder={t("placeHolderType", {
            title: t("firstName").toLowerCase(),
          })}
          name="firstName"
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("firstName") }),
          }}
          errorMessage={errors.firstName ? errors.firstName.message : ""}
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.firstName ? "border-red" : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />

        {/* ************** LAST NAME ***************/}

        <Input
          label={t("lastName")}
          isRequired={true}
          placeholder={t("placeHolderType", {
            title: t("lastName").toLowerCase(),
          })}
          name="lastName"
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("lastName") }),
          }}
          errorMessage={errors.lastName ? errors.lastName.message : ""}
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.lastName ? "border-red" : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />
        {/* ************** NATIONALITY ***************/}
        <Select
          label={t("nationality")}
          placeholder={t("placeholderSelect", {
            field: t("nationality").toLowerCase(),
          })}
          name={"nationalities"}
          containerClassName="flex flex-col gap-[10px] min-w-[250px] sm-max:w-full"
          isMulti={true}
          closeMenuOnSelect={false}
          value={
            watch("nationalities")
              ? nationalitiesOptions.filter((elem) =>
                  watch("nationalities").includes(elem.id)
                )
              : null
          }
          onChange={(e) => {
            setValue(
              "nationalities",
              e.map((e) => e.value)
            );
          }}
          register={register}
          options={nationalitiesOptions}
        />
        {/* ************** COUNTRY ***************/}

        <Select
          label={t("country")}
          placeholder={t("placeholderSelect", {
            field: t("country").toLowerCase(),
          })}
          name={"country"}
          containerClassName="flex flex-col gap-[10px] min-w-[250px] sm-max:w-full"
          value={
            watch("country")
              ? countriesOptions.find((elem) => elem.id === watch("country"))
              : null
          }
          onChange={(e) => {
            setValue("country", e.value);
          }}
          register={register}
          options={countriesOptions}
        />
        {/* ************** CITY ***************/}
        <Select
          label={t("city")}
          placeholder={t("placeholderSelect", {
            field: t("city").toLowerCase(),
          })}
          name={"city"}
          containerClassName="flex flex-col gap-[10px] min-w-[250px] sm-max:w-full"
          isDisabled={cities.length <= 0}
          value={
            watch("city")
              ? citiesOptions.find((elem) => elem.id === watch("city"))
              : null
          }
          onChange={(e) => {
            setValue("city", e.value);
          }}
          register={register}
          options={citiesOptions}
        />
        {/* ************** TIMEZONE ***************/}
        <Select
          label={t("timeZone")}
          placeholder={t("placeholderSelect", {
            field: t("timeZone").toLowerCase(),
          })}
          name={"timezone"}
          containerClassName="flex flex-col gap-[10px] min-w-[250px] sm-max:w-full"
          isDisabled={timezones.length <= 0}
          value={
            watch("timezone")
              ? timezonesOptions.find((elem) => elem.id === watch("timezone"))
              : null
          }
          onChange={(e) => {
            setValue("timezone", e.value);
          }}
          register={register}
          options={timezonesOptions}
        />
        <Input
          label={t("phoneNumber")}
          placeholder={t("placeholder", {
            field: t("phoneNumber").toLowerCase(),
          })}
          type="text"
          name="phoneNumber"
          register={register}
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName="flex h-[43px]  min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid border-default-color bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px] no-arrows-on-input"
        />
      </div>

      <div className="h-[1px] w-full  grid bg-default-color" />

      {/* ************** CONTACT DETAILS SECTION => END ***************/}

      <div className="text-gray-900 font-semibold text-[24px] not-italic">
        {t("availabilityTitle")}
      </div>
      <div className="flex flex-row md-max:flex-col justify-between md:max:justify-start  flex-wrap flex-1 gap-[20px]">
        <div className={"flex flex-col gap-[10px]"}>
          <div className="text-primary font-semibold text-[16px]">
            {t("availableDaysTitle")}
          </div>

          <div className="flex flex-wrap gap-[5px] items-center">
            {availableDaysOptions.map((title) => {
              return (
                <Fragment key={title}>
                  <CardCheckbox
                    title={title}
                    isActive={availableDays.includes(title)}
                    onClick={() => {
                      const availability = watch("availability");
                      if (availableDays.includes(title)) {
                        //remove
                        const newAvailableDays = availableDays.filter(
                          (elem) => elem !== title
                        );
                        setValue("availability", {
                          ...availability,
                          days: newAvailableDays.map(
                            (day) => availableDaysOptionsMapper[day]
                          ),
                        });
                        setAvailableDays(newAvailableDays);
                      } else {
                        //add
                        const newAvailableDays = [...availableDays, title];
                        setValue("availability", {
                          ...availability,
                          days: newAvailableDays.map(
                            (day) => availableDaysOptionsMapper[day]
                          ),
                        });
                        setAvailableDays(newAvailableDays);
                      }
                    }}
                    hideCheckIcon
                    containerClassName="flex items-center justify-center self-stretch py-[12px] px-[10px] w-[56px] cursor-pointer"
                    titleClassName="text-gray-700 font-semibold text-[14px] not-italic"
                  />
                </Fragment>
              );
            })}
          </div>
        </div>
        <div className="flex flex-wrap flex-1 gap-[16px]">
          <TimePicker
            label={t("timeFromTitle")}
            placeholder={`HH:MM - PM/AM`}
            containerClassName="flex flex-col gap-[10px] min-w-[250px] lg-max:min-w-full"
            name={"timeFrom"}
            value={
              timeFrom
                ? {
                    ...timeFrom,
                    label: `${timeFrom.label} ${timeFrom.value.split(" ")[1]}`,
                  }
                : null
            }
            onChange={(e) => {
              const availability = watch("availability");
              setValue("availability", {
                ...availability,
                time: {
                  ...availability.time,
                  from: convertTimeTo24h(e.value),
                  to: "",
                },
              });
              setTimeFrom(e);
              setTimeTo(null);
            }}
            options={timesOptions.map((el) => ({
              ...el,
              value: `${el.value} ${meridiem.from}`,
            }))}
            onMeridiemClick={(chosenMeridiem) =>
              onMeridiemClick(chosenMeridiem, "from")
            }
            meridiem={meridiem.from}
          />

          <TimePicker
            label={t("timeToTitle")}
            containerClassName="flex flex-col gap-[10px] min-w-[250px] lg-max:min-w-full"
            placeholder={`HH:MM - PM/AM`}
            name={"timeTo"}
            isRequired={!timeFrom ? false : true}
            register={register}
            registerArgs={{
              required:
                !timeFrom || timeTo?.value
                  ? false
                  : t("errorField", { field: t("timeToTitle") }),
            }}
            errorMessage={
              !timeFrom ? "" : errors.timeTo ? errors.timeTo.message : ""
            }
            isDisabled={!timeFrom}
            value={
              timeTo
                ? {
                    ...timeTo,
                    label: `${timeTo.label} ${timeTo.value.split(" ")[1]}`,
                  }
                : null
            }
            onChange={(e) => {
              const availability = watch("availability");
              setValue("availability", {
                ...availability,
                time: { ...availability.time, to: convertTimeTo24h(e.value) },
              });
              setValue("timeTo", e.value);
              setTimeTo(e);
            }}
            options={timesOptions
              .filter((elem) => {
                if (!timeFrom) {
                  return elem;
                }
                return isTimeAfter(
                  timeFrom.value,
                  `${elem.value} ${meridiem.to}`
                );
              })
              .map((el) => ({
                ...el,
                value: `${el.value} ${meridiem.to}`,
              }))}
            onMeridiemClick={(chosenMeridiem) =>
              onMeridiemClick(chosenMeridiem, "to")
            }
            meridiem={meridiem.to}
          />
        </div>
      </div>

      <div className="flex items-center justify-end gap-[10px]">
        <button
          type="button"
          onClick={onDiscard}
          disabled={isLoading}
          className="cursor-pointer flex py-[14px] px-[16px] gap-[8px] justify-center items-center rounded-[6px] border-[1px] border-solid border-default-color bg-white h-[46px]"
        >
          <div className="text-base-black font-bold text-[16px] not-italic">
            {t("discard")}
          </div>
        </button>
        <button
          type="submit"
          disabled={isLoading}
          className="cursor-pointer flex py-[14px] px-[16px] gap-[8px] justify-center items-center rounded-[6px] border-[1px] border-solid border-primary bg-primary h-[46px]"
        >
          <Oval
            height={30}
            width={30}
            color="#FFF"
            visible={isLoading}
            ariaLabel="oval-loading"
            secondaryColor="#FFF"
            strokeWidth={5}
            strokeWidthSecondary={3}
          />
          <div className="text-white font-bold text-[16px] not-italic">
            {t("saveChangesCta")}
          </div>
        </button>
      </div>
    </form>
  );
}

EditPersonalDetails.propTypes = {
  userDetails: PropTypes.object,
  onEditClick: PropTypes.func,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default EditPersonalDetails;
