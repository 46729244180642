import i18n from "../i18n";

export const validateEmail = (value = "") => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return !emailRegex.test(value) ? i18n.t("invalidEmail") : null;
};

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return !!emailRegex.test(email);
};
export function scrollIntoView(id) {
  document.getElementById(id)?.scrollIntoView?.({
    behavior: "smooth",
    block: "end",
  });
}
