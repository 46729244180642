import React from "react";
import PropTypes from "prop-types";
import useWindowDimensions from "hooks/useWindowDimensions";

export default function Pointer({ startPoint, endPoint }) {
  const { isResponsive } = useWindowDimensions();
  const endPointY = endPoint?.y ?? 0;
  const startPointY = startPoint?.y ?? 0;
  const isHide = (!startPoint && !endPoint) || endPointY === 0;

  return (
    <div
      className={`${"block"} absolute w-[3.5px] left-[calc(20%+20px)] xl-max:left-[calc(10%+20px)] lg-max:left-[calc(2rem+20px)] md-max:left-[calc(1rem+20px)] top-[40px] z-[1]`}
    >
      <div className="w-[10px] h-[10px] bg-primary rounded-[50%]" />
      <div
        className="bg-primary w-[1px]"
        style={{
          height: endPointY - (startPointY + 40),
          marginInlineStart: "4.4px",
          marginTop: "-1px",
        }}
      ></div>
    </div>
  );
}

Pointer.propTypes = {
  startPoint: PropTypes.object,
  endPoint: PropTypes.object,
};
