import { useState, useEffect } from "react";

const useWindowDimensions = (subtractedHeight = 0) => {
  const hasWindow = typeof window !== "undefined";
  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight - subtractedHeight : null;
    const isResponsive = window.innerWidth < 840; // mobile
    return {
      width,
      height,
      isResponsive,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (hasWindow) {
      // eslint-disable-next-line no-inner-declarations
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow, subtractedHeight]);

  return windowDimensions;
};

export default useWindowDimensions;
