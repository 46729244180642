import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { ReactComponent as EditIcon } from "images/profile/edit-icon.svg";
import { useTranslation } from "react-i18next";
import { portfolioType } from "utils/constantValues";
import useProfileHelper from "pages/profile/useProfileHelper";

function PortfolioFiles({
  userDetails = {},
  onEditClick = () => {},
  isShowEditIcon = false,
}) {
  const { downloadFileInANewTab } = useProfileHelper(true);
  const portfolioFiles = userDetails?.userFiles?.filter(
    (file) => file.type === portfolioType
  );
  const [t] = useTranslation();
  return (
    <div className="flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]">
      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        <div className="text-primary font-semibold text-[30px] not-italic">
          {t("portfolio")}
        </div>

        {isShowEditIcon && (
          <EditIcon className="cursor-pointer" onClick={onEditClick} />
        )}
      </div>

      <div className="flex flex-col">
        {portfolioFiles && portfolioFiles.length > 0
          ? portfolioFiles.map((file, index) => {
              return (
                <Fragment key={file.name}>
                  <div
                    className="text-gray-900 font-semibold text-[20px] not-italic py-[20px] cursor-pointer"
                    onClick={() => downloadFileInANewTab(file.id)}
                  >
                    {file.name}
                  </div>
                  {index < portfolioFiles.length - 1 && (
                    <div className="h-[1px] w-full  grid bg-default-color" />
                  )}
                </Fragment>
              );
            })
          : "-"}
      </div>
    </div>
  );
}

PortfolioFiles.propTypes = {
  userDetails: PropTypes.object,
  onEditClick: PropTypes.func,
  isShowEditIcon: PropTypes.bool,
};

export default PortfolioFiles;
