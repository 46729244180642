import { useState } from "react";
import { useNavigate } from "react-router-dom";

import authApi from "api/auth";
import useNotify from "hooks/useNotify";
import i18n from "../../i18n";

function useForgetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useNotify();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await authApi.forgetPassword(data);
      notifySuccess(i18n.t("emailResetPass"));
      navigate("/login");
    } catch (err) {
      console.error(err);
      notifyError(
        err?.data?.message ?? err?.message ?? "Something went wrong!"
      );
    } finally {
      setIsLoading(false);
    }
  };
  return { onSubmit, isLoading };
}

export default useForgetPassword;
