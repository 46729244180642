import React from "react";
import { useTranslation } from "react-i18next";
import Input from "components/input";
import PropTypes from "prop-types";
import Select from "components/select";
import { ReactComponent as BankDetailsIcon } from "images/profile/bank-details-icon.svg";
import { ReactComponent as PaypalIcon } from "images/profile/paypal-icon.svg";
import { ReactComponent as ExpressIcon } from "images/profile/express-icon.svg";
import { useLookupsContext } from "hooks/useContext";

function BankDetailsForm({
  register = () => {},
  setValue = () => {},
  errors = {},
  watch = () => {},
  getValues = () => {},
  title = "",
  containerClassName = "flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]",
}) {
  const [t] = useTranslation();

  const { currencies = [] } = useLookupsContext(false);
  const currenciesOptions = currencies.map((elem) => ({
    ...elem,
    value: elem.id,
    label: elem.code,
  }));

  const isPaypal = !!watch("paypal");
  const isCashExpress =
    !!watch("cashExpressPhoneNumber") && !!watch("fullName");

  const isBankDetails = watch([
    "companyName",
    "accountName",
    "bankName",
    "accountNumber",
    "accountCurrency",
    "swiftCode",
    "iban",
    "bankAddress",
    "routingCode",
    "sortCode",
  ]).every((elem) => !!elem);

  const isBankDetailsRequired = !isPaypal && !isCashExpress;
  const isPaypalRequired = !isBankDetails && !isCashExpress;

  const isCashExpressRequired = !isBankDetails && !isPaypal;

  return (
    <div className={containerClassName}>
      <div className="grid items-center w-full gap-[16px]">
        <div className="text-primary font-semibold text-[30px] not-italic">
          {title || t("financialInformation")}
        </div>

        <div className="text-gray-500 text-[18px] not-italic">
          {t("financialInformationSubTitle")}
        </div>
      </div>

      <Select
        label={t("preferredCurrency")}
        placeholder={t("placeholderSelect", {
          field: t("preferredCurrency").toLowerCase(),
        })}
        name={"preferredCurrency"}
        containerClassName="flex flex-1 flex-col gap-[10px] min-w-[250px] sm-max:w-full"
        value={
          watch("preferredCurrency")
            ? currenciesOptions.find(
                (elem) => elem.value === watch("preferredCurrency")
              )
            : currenciesOptions.find((elem) => elem.code === "USD")?.id ?? null
        }
        onChange={(e) => {
          setValue("preferredCurrency", e.value);
        }}
        isRequired={false}
        register={register}
        options={currenciesOptions}
      />

      {/* <<<<<Bank Details section start>>>> */}

      <div className="flex flex-col py-[20px] px-[24px] items-center gap-[18px] rounded-[6px] border-[1px] border-solid border-primary">
        <div className="flex items-center w-full gap-[12px] ">
          <BankDetailsIcon />
          <div className="text-base-black font-semibold text-[20px] not-italic">
            {t("bankDetails")}{" "}
            <span className="text-gray-500 font-semibold text-[20px] not-italic ">{`(${t(
              "Primary"
            )})`}</span>
          </div>
        </div>
        <div className="border-b-[1px] border-solid border-default-color w-calc-100-plus-48" />

        <div className="flex flex-wrap gap-[20px] w-full">
          <div className="grid grid-cols-2 sm-max:grid-cols-1  w-full gap-[16px]">
            <Input
              label={t("companyName")}
              isRequired={isBankDetailsRequired}
              placeholder={t("placeholderAgency", {
                title: t("companyName").toLowerCase(),
              })}
              name="companyName"
              register={register}
              registerArgs={{
                required: isBankDetailsRequired
                  ? t("errorField", { field: t("companyName") })
                  : false,
              }}
              errorMessage={
                errors.companyName && isBankDetailsRequired
                  ? errors.companyName.message
                  : ""
              }
              containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
              labelClassName="text-primary font-semibold text-[16px]"
              inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
                errors.companyName && isBankDetailsRequired
                  ? "border-red"
                  : "border-default-color"
              } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
            />

            <Input
              label={t("accountHolderName")}
              isRequired={isBankDetailsRequired}
              placeholder={t("placeholderAgency", {
                title: t("accountName").toLowerCase(),
              })}
              name="accountName"
              register={register}
              registerArgs={{
                required: isBankDetailsRequired
                  ? t("errorField", { field: t("accountHolderName") })
                  : false,
              }}
              errorMessage={
                errors.accountName && isBankDetailsRequired
                  ? errors.accountName.message
                  : ""
              }
              containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
              labelClassName="text-primary font-semibold text-[16px]"
              inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
                errors.accountName && isBankDetailsRequired
                  ? "border-red"
                  : "border-default-color"
              } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
            />

            <Input
              label={t("bankName")}
              isRequired={isBankDetailsRequired}
              placeholder={t("placeholderAgency", {
                title: t("bankName").toLowerCase(),
              })}
              name="bankName"
              register={register}
              registerArgs={{
                required: isBankDetailsRequired
                  ? t("errorField", {
                      field: t("bankName"),
                    })
                  : false,
              }}
              errorMessage={
                errors.bankName && isBankDetailsRequired
                  ? errors.bankName.message
                  : ""
              }
              containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
              labelClassName="text-primary font-semibold text-[16px]"
              inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
                errors.bankName && isBankDetailsRequired
                  ? "border-red"
                  : "border-default-color"
              } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
            />

            <Input
              label={t("accountNumber")}
              isRequired={isBankDetailsRequired}
              placeholder={t("placeholderAgency", {
                title: t("accountNumber").toLowerCase(),
              })}
              name="accountNumber"
              register={register}
              registerArgs={{
                required: isBankDetailsRequired
                  ? t("errorField", {
                      field: t("accountNumber"),
                    })
                  : false,
              }}
              errorMessage={
                errors.accountNumber && isBankDetailsRequired
                  ? errors.accountNumber.message
                  : ""
              }
              containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
              labelClassName="text-primary font-semibold text-[16px]"
              inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
                errors.accountNumber && isBankDetailsRequired
                  ? "border-red"
                  : "border-default-color"
              } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
            />

            <Input
              label={t("accountCurrency")}
              isRequired={isBankDetailsRequired}
              placeholder={t("placeholderAgency", {
                title: t("accountCurrency").toLowerCase(),
              })}
              name="accountCurrency"
              register={register}
              registerArgs={{
                required: isBankDetailsRequired
                  ? t("errorField", {
                      field: t("accountCurrency"),
                    })
                  : false,
              }}
              errorMessage={
                errors.accountCurrency && isBankDetailsRequired
                  ? errors.accountCurrency.message
                  : ""
              }
              containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
              labelClassName="text-primary font-semibold text-[16px]"
              inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
                errors.accountCurrency && isBankDetailsRequired
                  ? "border-red"
                  : "border-default-color"
              } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
            />

            <Input
              label={t("swiftCode")}
              isRequired={isBankDetailsRequired}
              placeholder={t("placeholderAgency", {
                title: t("swiftCode").toLowerCase(),
              })}
              name="swiftCode"
              register={register}
              registerArgs={{
                required: isBankDetailsRequired
                  ? t("errorField", {
                      field: t("swiftCode"),
                    })
                  : false,
              }}
              errorMessage={
                errors.swiftCode && isBankDetailsRequired
                  ? errors.swiftCode.message
                  : ""
              }
              containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
              labelClassName="text-primary font-semibold text-[16px]"
              inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
                errors.swiftCode && isBankDetailsRequired
                  ? "border-red"
                  : "border-default-color"
              } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
            />

            <Input
              label={t("iban")}
              isRequired={isBankDetailsRequired}
              placeholder={t("placeholderAgency", {
                title: t("iban").toLowerCase(),
              })}
              name="iban"
              register={register}
              registerArgs={{
                required: isBankDetailsRequired
                  ? t("errorField", {
                      field: t("iban"),
                    })
                  : false,
              }}
              errorMessage={
                errors.iban && isBankDetailsRequired ? errors.iban.message : ""
              }
              containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
              labelClassName="text-primary font-semibold text-[16px]"
              inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
                errors.iban && isBankDetailsRequired
                  ? "border-red"
                  : "border-default-color"
              } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
            />

            <Input
              label={t("bankAddress")}
              isRequired={isBankDetailsRequired}
              placeholder={t("placeholderAgency", {
                title: t("bankAddress").toLowerCase(),
              })}
              name="bankAddress"
              register={register}
              registerArgs={{
                required: isBankDetailsRequired
                  ? t("errorField", { field: t("bankAddress") })
                  : false,
              }}
              errorMessage={
                errors.bankAddress && isBankDetailsRequired
                  ? errors.bankAddress.message
                  : ""
              }
              containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
              labelClassName="text-primary font-semibold text-[16px]"
              inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
                errors.bankAddress && isBankDetailsRequired
                  ? "border-red"
                  : "border-default-color"
              } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
            />

            <Input
              label={t("routingCode")}
              isRequired={isBankDetailsRequired}
              placeholder={t("placeholderAgency", {
                title: t("routingCode").toLowerCase(),
              })}
              name="routingCode"
              register={register}
              registerArgs={{
                required: isBankDetailsRequired
                  ? t("errorField", {
                      field: t("routingCode"),
                    })
                  : false,
              }}
              errorMessage={
                errors.routingCode && isBankDetailsRequired
                  ? errors.routingCode.message
                  : ""
              }
              containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
              labelClassName="text-primary font-semibold text-[16px]"
              inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
                errors.routingCode && isBankDetailsRequired
                  ? "border-red"
                  : "border-default-color"
              } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
            />

            <Input
              label={t("sortCode")}
              isRequired={isBankDetailsRequired}
              placeholder={t("placeholderAgency", {
                title: t("sortCode").toLowerCase(),
              })}
              name="sortCode"
              register={register}
              registerArgs={{
                required: isBankDetailsRequired
                  ? t("errorField", {
                      field: t("sortCode"),
                    })
                  : false,
              }}
              errorMessage={
                errors.sortCode && isBankDetailsRequired
                  ? errors.sortCode.message
                  : ""
              }
              containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
              labelClassName="text-primary font-semibold text-[16px]"
              inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
                errors.sortCode && isBankDetailsRequired
                  ? "border-red"
                  : "border-default-color"
              } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
            />
          </div>
        </div>
      </div>
      {/* <<<<<Bank Details section end>>>> */}

      {/* <<<<<PAYPAL section start>>>> */}

      <div className="flex flex-col py-[20px] px-[24px] items-center gap-[18px] rounded-[6px] border-[1px] border-solid border-primary">
        <div className="flex items-center w-full gap-[12px] ">
          <PaypalIcon />
          <div className="text-base-black font-semibold text-[20px] not-italic">
            {t("paypal")}
          </div>
        </div>

        <div className="border-b-[1px] border-solid border-default-color w-calc-100-plus-48" />
        <div className="grid w-full gap-[20px]">
          <Input
            label={t("paypalLabel")}
            isRequired={isPaypalRequired}
            placeholder={t("paypalPlaceholder")}
            name="paypal"
            register={register}
            registerArgs={{
              required: isPaypalRequired
                ? t("errorField", {
                    field: t("paypal"),
                  })
                : false,
            }}
            errorMessage={
              errors.paypal && isPaypalRequired ? errors.paypal.message : ""
            }
            containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
            labelClassName="text-primary font-semibold text-[16px]"
            inputClassName={`flex h-[43px] w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
              errors.paypal && isPaypalRequired
                ? "border-red"
                : "border-default-color"
            } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
          />
        </div>
      </div>
      {/* <<<<<PAYPAL section end>>>> */}

      {/* <<<<<Cash express section start>>>> */}
      <div className="flex flex-col py-[20px] px-[24px] items-center gap-[18px] rounded-[6px] border-[1px] border-solid border-primary">
        <div className="flex items-center w-full gap-[12px] ">
          <ExpressIcon />
          <div className="text-base-black font-semibold text-[20px] not-italic">
            {t("cashExpress")}
          </div>
        </div>

        <div className="border-b-[1px] border-solid border-default-color w-calc-100-plus-48" />
        <div className="grid w-full gap-[20px]">
          <div className="grid gap-[6px] w-full">
            <Input
              label={t("fullName")}
              isRequired={isCashExpressRequired}
              placeholder={t("placeHolderType", {
                title: t("fullName").toLowerCase(),
              })}
              name="fullName"
              register={register}
              registerArgs={{
                required: isCashExpressRequired
                  ? t("errorField", {
                      field: t("fullName"),
                    })
                  : false,
              }}
              errorMessage={
                errors.fullName && isCashExpressRequired
                  ? errors.fullName.message
                  : ""
              }
              containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
              labelClassName="text-primary font-semibold text-[16px]"
              inputClassName={`flex h-[43px] w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
                errors.fullName && isCashExpressRequired
                  ? "border-red"
                  : "border-default-color"
              } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
            />
            <div className="text-gray-500 font-regular text-[14px] not-italic">
              {t("expressFullNameSubTitle")}
            </div>
          </div>

          <Input
            label={t("phoneNumber")}
            isRequired={isCashExpressRequired}
            placeholder={t("phonePlaceholder")}
            name="cashExpressPhoneNumber"
            type="text"
            register={register}
            registerArgs={{
              required: isCashExpressRequired
                ? t("errorField", { field: t("phoneNumber") })
                : false,
            }}
            errorMessage={
              errors.cashExpressPhoneNumber && isCashExpressRequired
                ? errors.cashExpressPhoneNumber.message
                : ""
            }
            containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
            labelClassName="text-primary font-semibold text-[16px]"
            inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
              errors.cashExpressPhoneNumber && isCashExpressRequired
                ? "border-red"
                : "border-default-color"
            } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
          />
        </div>
      </div>
      {/* <<<<<Cash express section end>>>> */}
    </div>
  );
}
BankDetailsForm.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  watch: PropTypes.func,
  getValues: PropTypes.func,
  title: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default BankDetailsForm;
