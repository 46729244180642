import React from "react";
import PropTypes from "prop-types";
import { Oval } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ServiceForm from "components/serviceForm";
import { useLookupsContext } from "hooks/useContext";
import useProfileHelper from "pages/profile/useProfileHelper";

function EditPersonalExperience({
  userDetails = {},
  isVendor,
  onDiscard = () => {},
  onSave = () => {},
  isLoading = false,
}) {
  const [t] = useTranslation();

  const { defaultServiceRates, defaultDrawLanguages } =
    useProfileHelper(isVendor);
  const { services, languages } = useLookupsContext(isVendor);

  const handleDefaultServiceRates = defaultServiceRates(userDetails?.userRates);

  const defaultLanguages = {};
  Object.keys(handleDefaultServiceRates).forEach((serviceId, index) => {
    const serviceRate = handleDefaultServiceRates[serviceId];
    serviceRate.forEach((rateType, rateIndex) => {
      if (rateType.fromLanguage) {
        defaultLanguages[`${serviceId}-${index}-${rateIndex}-from`] =
          rateType?.fromLanguage;
      }

      if (rateType.toLanguage) {
        defaultLanguages[`${serviceId}-${index}-${rateIndex}-to`] =
          rateType.toLanguage;
      }
    });
  });

  const defaultsValues = {
    services: userDetails?.userServices?.map((e) => e.id) ?? [],
    serviceRates: Object.values(handleDefaultServiceRates)
      .reduce((acc, item) => {
        item.forEach((el) => {
          acc.push(el);
        });
        return acc;
      }, [])
      .map((elem) => {
        const hasCapacity = elem.withCapacity
          ? { capacity: elem.capacity || 0 }
          : {};
        const hasFromLanguages =
          elem.hasLanguages && elem.isPair
            ? { fromLanguage: elem.fromLanguage }
            : {};
        const hasToLanguages = elem.hasLanguages
          ? { toLanguage: elem.toLanguage }
          : {};

        return {
          rate: elem.rate || 0,
          rateId: elem.rateId,
          ...hasCapacity,
          ...hasFromLanguages,
          ...hasToLanguages,
        };
      }),
    ...defaultLanguages,
  };

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultsValues,
  });

  return (
    <form
      className="flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]"
      onSubmit={handleSubmit(onSave)}
      id={"experienceScrollId"}
    >
      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        <div className="text-primary font-semibold text-[30px] not-italic">
          {isVendor ? t("skills") : t("services")}
        </div>
      </div>

      <div className="flex flex-wrap gap-[20px]">
        <ServiceForm
          data={{ services, languages }}
          register={register}
          watch={watch}
          setValue={setValue}
          errors={errors}
          getValues={getValues}
          unregister={unregister}
          defaultServices={services.filter((elem) =>
            userDetails?.userServices.map((el) => el.id).includes(elem.id)
          )}
          defaultServicesRates={handleDefaultServiceRates}
          defaultDrawLanguages={defaultDrawLanguages(userDetails?.userRates)}
          isVendor={isVendor}
        />
      </div>

      <div className="flex items-center justify-end gap-[10px]">
        <button
          type="button"
          onClick={onDiscard}
          disabled={isLoading}
          className="cursor-pointer flex py-[14px] px-[16px] gap-[8px] justify-center items-center rounded-[6px] border-[1px] border-solid border-default-color bg-white h-[46px]"
        >
          <div className="text-base-black font-bold text-[16px] not-italic">
            {t("discard")}
          </div>
        </button>
        <button
          type="submit"
          disabled={isLoading}
          className="cursor-pointer flex py-[14px] px-[16px] gap-[8px] justify-center items-center rounded-[6px] border-[1px] border-solid border-primary bg-primary h-[46px]"
        >
          <Oval
            height={30}
            width={30}
            color="#FFF"
            visible={isLoading}
            ariaLabel="oval-loading"
            secondaryColor="#FFF"
            strokeWidth={5}
            strokeWidthSecondary={3}
          />
          <div className="text-white font-bold text-[16px] not-italic">
            {t("saveChangesCta")}
          </div>
        </button>
      </div>
    </form>
  );
}

EditPersonalExperience.propTypes = {
  userDetails: PropTypes.object,
  isVendor: PropTypes.bool,
  onEditClick: PropTypes.func,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default EditPersonalExperience;
