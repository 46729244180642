import React, { forwardRef, createRef } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";

const CustomInput = forwardRef(
  (
    {
      value,
      onClick,
      customInputStyle,
      inputStyle = {},
      errorMessage,
      isRequired,
      placeholderInput = "Date",
    },
    ref
  ) => {
    return (
      <input
        onClick={onClick}
        type="text"
        placeholder={placeholderInput}
        value={value}
        ref={ref}
        className={
          customInputStyle
            ? customInputStyle
            : `flex h-[43px] items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
                errorMessage && isRequired
                  ? "border-red"
                  : "border-default-color"
              } border-default-color bg-transparent outline-none text-white font-regular text-[16px] not-italic font-normal  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px] opacity-[0.65]`
        }
        readOnly
        style={{ ...inputStyle }}
      />
    );
  }
);

CustomInput.propTypes = {
  value: PropTypes.any,
  onClick: PropTypes.any,
  customInputStyle: PropTypes.string,
  inputStyle: PropTypes.object,
  errorMessage: PropTypes.string,
  isRequired: PropTypes.bool,
  placeholder: PropTypes.string,
};

const DatePickerComponent = ({
  onValueChange,
  value,
  customInputStyle = "",
  inputStyle = {},
  showTimeSelect,
  dateFormat = "dd/MM/yyyy",
  minDate = null,
  maxDate = null,
  minTime = null,
  maxTime = null,
  disabled = false,
  dateRef = createRef(),
  label = "",
  isRequired = false,
  errorMessage = "",
  name = "",
  register = () => {},
  registerArgs = {},
  containerClassName = "",
  labelClassName = "",
  placeholder = "Date",
  showYearDropdown = true,
  showMonthDropdown = true,
}) => {
  const handleChange = (date) => {
    onValueChange(date);
  };
  return (
    <div
      className={
        containerClassName
          ? containerClassName
          : "flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
      }
    >
      {label && (
        <div
          className={
            labelClassName
              ? labelClassName
              : "text-white font-semibold text-[16px] not-italic font-normal"
          }
        >
          {label} {isRequired && <span className="text-red">*</span>}
        </div>
      )}

      <DatePicker
        {...register(name, { ...registerArgs })}
        ref={dateRef}
        selected={value}
        showTimeSelect={showTimeSelect}
        dateFormat={dateFormat}
        onChange={(date) => handleChange(date)}
        closeOnScroll
        dropdownMode="select"
        nextYearButtonLabel="sdjks"
        showYearDropdown={showYearDropdown}
        scrollableYearDropdown={showYearDropdown}
        previousYearButtonLabel
        showMonthDropdown={showMonthDropdown}
        customInput={
          <CustomInput
            customInputStyle={customInputStyle}
            inputStyle={inputStyle}
            isRequired={isRequired}
            errorMessage={errorMessage}
            placeholderInput={placeholder}
          />
        }
        minDate={minDate}
        maxDate={maxDate}
        minTime={minTime}
        maxTime={maxTime}
        disabled={disabled}
      />

      {errorMessage && isRequired && !value && (
        <span className="text-[12px] text-red font-regular">
          {errorMessage}
        </span>
      )}
    </div>
  );
};

DatePickerComponent.propTypes = {
  onValueChange: PropTypes.any,
  value: PropTypes.any,
  showTimeSelect: PropTypes.bool,
  dateFormat: PropTypes.any,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  minTime: PropTypes.any,
  maxTime: PropTypes.any,
  disabled: PropTypes.bool,
  customInputStyle: PropTypes.string,
  dateRef: PropTypes.any,
  inputStyle: PropTypes.object,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  register: PropTypes.func,
  registerArgs: PropTypes.object,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  placeholder: PropTypes.string,
  showYearDropdown: PropTypes.bool,
  showMonthDropdown: PropTypes.bool,
};

export default DatePickerComponent;
