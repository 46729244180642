import React from "react";
import useCountFilledFields from "./useCountFilledFields";
import { useTranslation } from "react-i18next";

function useProfileCompletion({
  userDetails = {},
  profileCompletionRef = {},
  showOnboardingForum = false,
  isShowEditIcon = false,
}) {
  const [t] = useTranslation();
  const isVendor = userDetails?.vendorId;

  const agencySections = [
    {
      sectionTitle: t("companyDetailsTitle"),
      isDone: false,
      canOpenEdit: false,
      name: "companyDetails",
      id: "companyDetailsScrollId",
    },
    {
      sectionTitle: t("bankDetails"),
      isDone: false,
      canOpenEdit: false,
      isHide: !showOnboardingForum,
      name: "agencyBankDetails",
      id: "bankDetailsScrollId",
    },

    {
      sectionTitle: t("contactDetailsTitle"),
      isDone: false,
      canOpenEdit: false,
      isHide: !showOnboardingForum,
      name: "agencyContacts",
      id: "contactDetailsScrollId",
    },

    {
      sectionTitle: t("services"),
      isDone: true,
      canOpenEdit: false,
      isLock: userDetails.flags.lockService,
      name: "services",
      id: "profileServiceScrollId",
    },

    {
      sectionTitle: t("experienceTitle"),
      isDone: false,
      canOpenEdit: false,
      isLock: userDetails.flags.lockDomain,
      name: "experience",
      id: "experienceScrollId",
    },

    {
      sectionTitle: t("portfolio"),
      isDone: false,
      canOpenEdit: true,
      name: "portfolio",
      id: "portfolioScrollId",
    },

    {
      sectionTitle: t("certificationsTitle"),
      isDone: false,
      canOpenEdit: false,
      name: "certification",
      id: "certificationScrollId",
    },

    {
      sectionTitle: t("rateCard"),
      isDone: false,
      canOpenEdit: false,
      name: "rateCard",
      id: "rateCardScrollId",
    },
  ];

  const vendorSections = [
    {
      sectionTitle: t("personalDetailsTitle"),
      isDone: false,
      canOpenEdit: false,
      name: "personalProfile",
      id: "personalDetailsScrollId",
    },

    {
      sectionTitle: t("experienceTitle"),
      isDone: false,
      canOpenEdit: false,
      name: "experience",
      id: "experienceScrollId",
    },
    {
      sectionTitle: t("qualificationsTitle"),
      isDone: false,
      canOpenEdit: false,
      isHide: !showOnboardingForum,
      name: "qualifications",
      id: "qualificationsScrollId",
    },

    {
      sectionTitle: t("skills"),
      isDone: true,
      canOpenEdit: false,
      isLock: userDetails.flags.lockService,
      name: "services",
      id: "profileServiceScrollId",
    },

    {
      sectionTitle: t("contactDetailsTitle"),
      isDone: false,
      canOpenEdit: false,
      isHide: !showOnboardingForum,
      name: "vendorContacts",
      id: "contactDetailsScrollId",
    },

    {
      sectionTitle: t("legalDetailsTitle"),
      isDone: false,
      canOpenEdit: false,
      isHide: !showOnboardingForum,
      name: "legalDetails",
      id: "legalDetailsScrollId",
    },

    {
      sectionTitle: t("bankDetails"),
      isDone: false,
      canOpenEdit: false,
      isHide: !showOnboardingForum,
      name: "vendorBankDetails",
      id: "bankDetailsScrollId",
    },

    {
      sectionTitle: t("emergencyContactTitle"),
      isDone: false,
      canOpenEdit: false,
      isHide: !showOnboardingForum,
      name: "emergencyContact",
      id: "emergencyContactScrollId",
    },

    {
      sectionTitle: t("cvTitle"),
      isDone: false,
      canOpenEdit: true,
      name: "resume",
      id: "resumeScrollId",
    },

    {
      sectionTitle: t("relevantProfileTitle"),
      isDone: false,
      canOpenEdit: false,
      name: "relevant",
      id: "relevantProfileIdScrollId",
    },
    {
      sectionTitle: t("certificationsTitle"),
      isDone: false,
      canOpenEdit: false,
      name: "certification",
      id: "certificationScrollId",
    },
  ];

  const ignoreSections = isVendor
    ? [
        "qualifications",
        "vendorContacts",
        "legalDetails",
        "vendorBankDetails",
        "emergencyContact",
      ]
    : ["agencyBankDetails", "agencyContacts"];
  const ignoreValueFields = ["openEdit"];

  const ignoreOnboardSections = (object = {}) => {
    let objectResult = { ...object };
    ignoreSections.forEach((elem) => {
      if (objectResult[elem]) {
        delete objectResult[elem];
      }
    });

    return objectResult;
  };

  const onOpenEditSection = (section = {}) => {
    const sectionName = section?.name;

    profileCompletionRef[sectionName]?.openEdit?.(true);

    document.getElementById(section?.id)?.scrollIntoView?.({
      behavior: "smooth",
      block: "start",
    });
  };

  const bounceToSection = (section = {}) => {
    document.getElementById(section?.id)?.scrollIntoView?.({
      behavior: "smooth",
      block: "start",
    });
  };

  const setupFields = () => {
    let results = [];
    const filteredRef = showOnboardingForum
      ? profileCompletionRef
      : ignoreOnboardSections(profileCompletionRef);

    for (const [_, value] of Object.entries(filteredRef)) {
      for (const [valKey, valElem] of Object.entries(value)) {
        if (!ignoreValueFields.includes(valKey)) {
          results.push({ [valKey]: valElem });
        }
      }
    }

    return results;
  };

  const { countFilledFields, calcFilledPercentages, handleAllFields } =
    useCountFilledFields(isVendor);

  const allFields = setupFields();

  const numberOfAllSectionFields = countFilledFields(
    allFields,
    userDetails.userRates
  );

  const extraPointsForService = 3;

  const totalAllSectionFields = allFields.length + extraPointsForService;

  const allSectionPercentage = calcFilledPercentages(
    numberOfAllSectionFields,
    totalAllSectionFields
  );

  const addPercentagePerSection = (fields) => {
    return fields
      .filter((elem) =>
        showOnboardingForum ? elem : !ignoreSections.includes(elem.name)
      )
      .map((elem) => {
        let sectionFields = profileCompletionRef[elem.name]
          ? { ...profileCompletionRef[elem.name] }
          : {};

        delete sectionFields?.openEdit;

        const fields = handleAllFields(sectionFields);
        const numberOfFilledFields = countFilledFields(
          fields,
          userDetails.userRates
        );

        const totalFields = fields[0]?.services
          ? fields.length + extraPointsForService
          : fields.length;

        const percentage = calcFilledPercentages(
          numberOfFilledFields,
          totalFields
        );
        return {
          ...elem,
          canOpenEdit: Math.floor(percentage) < 100 && isShowEditIcon,
          isDone: Math.floor(percentage) === 100,
          percentage: Math.floor(percentage),
        };
      });
  };

  const getSections = () => {
    const sections = isVendor ? vendorSections : agencySections;

    return addPercentagePerSection(sections);
  };

  return {
    sections: getSections(),
    allSectionPercentages: Math.floor(allSectionPercentage),
    onOpenEditSection,
    bounceToSection,
  };
}

export default useProfileCompletion;
