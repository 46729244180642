import { useState } from "react";
import profileApi from "api/profile";
import useNotify from "hooks/useNotify";
import { useAppContext, useLookupsContext } from "hooks/useContext";

function useAgencyForm({ agencyId, setUserDetails = () => {} }) {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAppContext();
  const userId = user?.id;

  const { currencies = [] } = useLookupsContext(false);

  const { notifyError, notifySuccess } = useNotify();

  const defaultsValues = {
    //bankDetails
    preferredCurrency: currencies.find((elem) => elem.code === "USD")?.id ?? "",
    paypal: "",
    fullName: "",
    cashExpressPhoneNumber: "",

    companyName: "",
    accountName: "",
    bankName: "",
    accountNumber: "",
    accountCurrency: "",
    swiftCode: "",
    iban: "",

    bankAddress: "",
    routingCode: "",
    sortCode: "",

    //contacts
    contacts: [],
  };

  const onSubmit = async ({
    preferredCurrency,
    paypal,
    fullName,
    cashExpressPhoneNumber,
    companyName,
    accountName,
    bankName,
    accountNumber,
    accountCurrency,
    swiftCode,
    iban,
    bankAddress,
    routingCode,
    sortCode,
    contacts,
  }) => {
    setIsLoading(true);
    const bankDetails = {
      companyName,
      accountName,
      bankName,
      accountNumber,
      accountCurrency,
      swiftCode,
      iban,
      bankAddress,
      routingCode,
      sortCode,
    };

    const paypalDetails = {
      emailOrPhoneNumber: paypal,
    };

    const cashExpress = {
      fullName,
      cashExpressPhoneNumber,
    };

    const data = {
      bankDetails,
      preferredCurrency,
      paypalDetails,
      cashExpress,
      contacts,
    };

    try {
      await profileApi.updateAdditionalAgencyInfo(agencyId, data);
      notifySuccess("Has been updated successfully");

      const userData = await profileApi.getUserProfileByIdApi(userId);
      setTimeout(() => {
        setUserDetails(userData);
        window.scrollTo(0, 0);
      }, 400);
    } catch (err) {
      console.error(err);
      notifyError(err?.data?.error ?? err?.message ?? "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };
  return { onSubmit, isLoading, defaultsValues };
}

export default useAgencyForm;
