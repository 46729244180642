import { useState } from "react";
import authApi from "api/auth";
import { useNavigate } from "react-router-dom";
import useNotify from "hooks/useNotify";
import { useTranslation } from "react-i18next";

function useLogin() {
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { notifyError } = useNotify();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await authApi.login(data);
      navigate("/");
    } catch (err) {
      notifyError(err?.data?.error ?? err?.message ?? "Something went wrong!");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return { onSubmit, isLoading };
}

export default useLogin;
