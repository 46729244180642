import React from "react";
import PropTypes from "prop-types";
import { Oval } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import BankDetailsForm from "../agencyForm/bankDetailsForm";

function EditBankDetails({
  userDetails = {},
  onDiscard = () => {},
  onSave = () => {},
  isLoading = false,
}) {
  const [t] = useTranslation();
  const defaultsValues = {
    companyName: userDetails?.bankDetails?.companyName ?? "",
    accountName: userDetails?.bankDetails?.accountName ?? "",
    bankName: userDetails?.bankDetails?.bankName ?? "",
    accountNumber: userDetails?.bankDetails?.accountNumber ?? "",
    accountCurrency: userDetails?.bankDetails?.accountCurrency ?? "",
    swiftCode: userDetails?.bankDetails?.swiftCode ?? "",
    iban: userDetails?.bankDetails?.iban ?? "",
    bankAddress: userDetails?.bankDetails?.bankAddress ?? "",
    routingCode: userDetails?.bankDetails?.routingCode ?? "",
    sortCode: userDetails?.bankDetails?.sortCode ?? "",
    fullName: userDetails?.bankDetails?.fullName ?? "",
    paypal: userDetails?.bankDetails?.emailOrPhoneNumber ?? "",
    cashExpressPhoneNumber:
      userDetails?.bankDetails?.cashExpressPhoneNumber ?? "",
    preferredCurrency: userDetails?.currency?.id,
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultsValues,
  });

  return (
    <form
      className="flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]"
      onSubmit={handleSubmit(onSave)}
    >
      <BankDetailsForm
        register={register}
        setValue={setValue}
        errors={errors}
        watch={watch}
        containerClassName="grid gap-[30px] w-full"
      />

      <div className="flex items-center justify-end gap-[10px]">
        <button
          type="button"
          onClick={onDiscard}
          disabled={isLoading}
          className="cursor-pointer flex py-[14px] px-[16px] gap-[8px] justify-center items-center rounded-[6px] border-[1px] border-solid border-default-color bg-white h-[46px]"
        >
          <div className="text-base-black font-bold text-[16px] not-italic">
            {t("discard")}
          </div>
        </button>
        <button
          type="submit"
          disabled={isLoading}
          className="cursor-pointer flex py-[14px] px-[16px] gap-[8px] justify-center items-center rounded-[6px] border-[1px] border-solid border-primary bg-primary h-[46px]"
        >
          <Oval
            height={30}
            width={30}
            color="#FFF"
            visible={isLoading}
            ariaLabel="oval-loading"
            secondaryColor="#FFF"
            strokeWidth={5}
            strokeWidthSecondary={3}
          />
          <div className="text-white font-bold text-[16px] not-italic">
            {t("saveChangesCta")}
          </div>
        </button>
      </div>
    </form>
  );
}

EditBankDetails.propTypes = {
  userDetails: PropTypes.object,
  onEditClick: PropTypes.func,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default EditBankDetails;
