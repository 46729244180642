import React, { useEffect } from "react";
import { FallingLines } from "react-loader-spinner";
import useProfile from "./useProfile";
import Header from "components/header";
import VendorProfile from "./vendorProfile";
import AgencyProfile from "./agencyProfile";
import useScrollToTop from "hooks/useScrollToTop";
import { useLookupsContext } from "hooks/useContext";

const ProfilePage = () => {
  const {
    initLoading,
    userDetails,
    setUserDetails,
    userDomains,
    setUserDomains,
    userSubDomains,
    setUserSubDomains,
  } = useProfile();

  const isVendor = userDetails.vendorId;
  const isAgency = userDetails.agencyId;

  useScrollToTop(initLoading);
  const { dataToBeLoaded } = useLookupsContext(isVendor || isAgency);

  if (initLoading || !dataToBeLoaded) {
    return (
      <div className="flex items-center justify-center h-screen w-full">
        <FallingLines
          color="#023A66"
          width="100%"
          visible={true}
          ariaLabel="falling-lines-loading"
        />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Header />
      <div className="px-[80px] md-max:px-[1rem] flex flex-col my-[60px] md-max:my-[20px] relative">
        {isVendor && (
          <VendorProfile
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            userDomains={userDomains}
            setUserDomains={setUserDomains}
            userSubDomains={userSubDomains}
            setUserSubDomains={setUserSubDomains}
          />
        )}

        {isAgency && (
          <AgencyProfile
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            userDomains={userDomains}
            setUserDomains={setUserDomains}
            userSubDomains={userSubDomains}
            setUserSubDomains={setUserSubDomains}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default ProfilePage;
