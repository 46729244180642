import React from "react";
import PropTypes from "prop-types";
import LabelComponent from "pages/profile/common/label";
import { ReactComponent as EditIcon } from "images/profile/edit-icon.svg";
import { useTranslation } from "react-i18next";

function EmergencyContact({
  userDetails = {},
  onEditClick = () => {},
  isShowEditIcon = false,
}) {
  const [t] = useTranslation();

  return (
    <div className="flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]">
      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        <div className="text-primary font-semibold text-[30px] not-italic">
          {t("emergencyContactTitle")}
        </div>
        {isShowEditIcon && (
          <EditIcon className="cursor-pointer" onClick={onEditClick} />
        )}
      </div>

      <div className="flex flex-wrap gap-[20px]">
        <LabelComponent
          title={t("name")}
          subTitle={`${
            userDetails?.extraInformation?.emergencyContactDetails?.name ?? "-"
          }`}
        />
        <LabelComponent
          title={t("relationship")}
          subTitle={`${
            userDetails?.extraInformation?.emergencyContactDetails
              ?.relationship ?? "-"
          }`}
        />

        <LabelComponent
          title={t("email")}
          subTitle={`${
            userDetails?.extraInformation?.emergencyContactDetails?.email ?? "-"
          }`}
        />

        <LabelComponent
          title={t("country")}
          subTitle={`${
            userDetails?.extraInformation?.emergencyContactDetails?.country ??
            "-"
          }`}
        />

        <LabelComponent
          title={t("phoneNumber")}
          subTitle={`${
            userDetails?.extraInformation?.emergencyContactDetails
              ?.mobileNumber ?? "-"
          }`}
        />
        <LabelComponent
          title={t("homeTelephone")}
          subTitle={`${
            userDetails?.extraInformation?.emergencyContactDetails
              ?.homeTelephone ?? "-"
          }`}
        />
      </div>
    </div>
  );
}

EmergencyContact.propTypes = {
  userDetails: PropTypes.object,
  onEditClick: PropTypes.func,
  isShowEditIcon: PropTypes.bool,
};

export default EmergencyContact;
