import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as RadioButtonActive } from "images/auth/active-radio-button.svg";
import { ReactComponent as RadioButtonBase } from "images/auth/radio-button-base.svg";

function RadioButtons({
  label = "",
  containerClassName = "",
  labelClassName = "",
  radioClassNameContainer = "",
  contents = [],
  onClick = () => {},
}) {
  return (
    <div
      className={
        containerClassName ? containerClassName : "flex flex-col gap-[16px]"
      }
    >
      {label && (
        <div
          className={
            labelClassName
              ? labelClassName
              : "text-primary font-semibold text-[16px]"
          }
        >
          {label}
        </div>
      )}

      <div
        className={`${
          radioClassNameContainer
            ? radioClassNameContainer
            : "grid grid-cols-2 justify-between"
        } `}
      >
        {contents.map(({ title, isActive, titleClassName }, index) => {
          //i.e [{title:"", isActive:true, titleClassName:""}]
          return (
            <div className="flex gap-[10px]" key={title}>
              {isActive ? (
                <RadioButtonActive />
              ) : (
                <RadioButtonBase
                  className="cursor-pointer"
                  onClick={() => onClick(title, index)}
                />
              )}

              <div
                className={
                  titleClassName
                    ? titleClassName
                    : "text-primary font-medium text-[16px] not-italic"
                }
              >
                {title}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

RadioButtons.propTypes = {
  label: PropTypes.string,
  contents: PropTypes.array,
  containerClassName: PropTypes.string,
  radioClassNameContainer: PropTypes.string,
  labelClassName: PropTypes.string,
  onClick: PropTypes.func,
};

export default RadioButtons;
