import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import BGShutterstock from "images/auth/bg-shutterstock.png";
import { ReactComponent as TVendorIcon } from "images/auth/t-vendors.svg";
import InputComponent from "components/input";
import { validateEmail } from "utils/helper";
import useLogin from "./useLogin";
import useScrollToTop from "hooks/useScrollToTop";

const LoginPage = () => {
  const [t] = useTranslation();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { onSubmit, isLoading } = useLogin();

  useScrollToTop();

  return (
    <form
      className="w-full h-full bg-no-repeat flex-1 bg-cover min-h-[calc(100%-50px)] bg-center flex items-center justify-center md-max:justify-start  py-[60px] px-[120px] md-max:px-[1rem]"
      style={{
        backgroundImage: `url(${BGShutterstock})`,
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        className="w-full max-w-[700px] md-max:max-w-full h-full min-h-[650px] md-max:max-h-full  rounded-[20px] flex-shrink-0 flex items-center justify-center p-[1rem]"
        style={{ background: "rgb(2,23,51, 0.65)" }}
      >
        <div className="flex flex-col items-start gap-[40px] w-[330px]">
          <div className="flex flex-col items-start gap-[10px]">
            <TVendorIcon />
            <div className="text-white font-medium text-[36px] not-italic font-normal">
              {t("loginTitle")}
            </div>
          </div>

          <div className="flex flex-col gap-[18px] self-stretch">
            <InputComponent
              label={t("emailTitle")}
              isRequired={true}
              type="email"
              name="email"
              placeholder={t("placeholder", {
                field: t("emailTitle").toLowerCase(),
              })}
              errorMessage={errors.email ? errors.email.message : ""}
              register={register}
              registerArgs={{
                required: t("errorField", { field: t("emailTitle") }),
                validate: validateEmail,
              }}
              autoFocus={true}
            />

            <InputComponent
              label={t("passwordTitle")}
              isRequired={true}
              type="password"
              name="password"
              placeholder={t("placeholder", {
                field: t("passwordTitle").toLowerCase(),
              })}
              errorMessage={errors.password ? errors.password.message : ""}
              register={register}
              registerArgs={{
                required: t("errorField", { field: t("passwordTitle") }),
                minLength: {
                  value: 8,
                  message: t("errorMinCharacter", {
                    field: t("passwordTitle"),
                    value: 8,
                  }),
                },
              }}
            />

            <div className="flex justify-end">
              <div
                className="text-white text-[16px] font-regular not-italic font-normal opacity-[0.65] cursor-pointer"
                onClick={() => navigate("/forget-password")}
              >
                {t("forgerPasswordTitle")}
              </div>
            </div>

            <button
              className="flex py-[18px] px-[20px] justify-center items-center gap-[8px] rounded-[6px] border-[1px] border-solid border-light-primary bg-light-primary h-[48px]"
              type="submit"
              disabled={isLoading}
            >
              <Oval
                height={30}
                width={30}
                color="#023A66"
                visible={isLoading}
                ariaLabel="oval-loading"
                secondaryColor="#023A66"
                strokeWidth={5}
                strokeWidthSecondary={3}
              />
              <div className="text-primary font-bold text-[18px] not-italic font-normal">
                {t("loginSubmit")}
              </div>
            </button>

            <div className="text-white text-center text-[18px] font-regular not-italic font-normal opacity-[0.65]">
              {t("or")}{" "}
              <span
                className="text-secondary font-bold text-[18px] underline not-italic font-normal cursor-pointer"
                onClick={() => navigate("/signup")}
              >
                {t("signUp")}
              </span>{" "}
              {t("newAccountExtension")}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LoginPage;
