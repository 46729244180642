import { useState, useEffect } from "react";
import { useLookupsContext } from "hooks/useContext";
import { availableDaysNumberMapper } from "utils/constantValues";
import useNotify from "hooks/useNotify";
import profileApi from "api/profile";
import { scrollIntoView } from "utils/helper";

function useCompanyDetails({
  userDetails = {},
  countryId,
  availability = {},
  profileCompletionRef = {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditCompanyDetails, setIsEditCompanyDetails] = useState(false);
  const [availableDays, setAvailableDays] = useState(
    availability?.days?.length
      ? availability?.days.map((dayNum) => {
          return availableDaysNumberMapper[dayNum];
        })
      : []
  );

  profileCompletionRef.companyDetails = {
    openEdit: setIsEditCompanyDetails,
    companyName: userDetails?.companyName ?? "",
    representativeName: userDetails?.representativeName ?? "",
    email: userDetails?.email ?? "",
    phone: userDetails?.phone ?? "",
    country: userDetails?.country?.name ?? "",
    city: userDetails?.city?.name ?? "",
    timezone: userDetails?.timezone?.name ?? "",
    companyWebsite: userDetails?.companyWebsite ?? "",
    days: userDetails?.availability?.days ?? [],
    timeFrom: userDetails?.availability?.time?.from ?? "",
    timeTo: userDetails?.availability?.time?.to ?? "",
  };

  const { countries, cities, timezones, getCities, getTimeZones } =
    useLookupsContext(true);

  const { notifyError, notifySuccess } = useNotify();

  const countriesOptions = countries.map((elem) => ({
    ...elem,
    label: elem.name,
    value: elem.id,
  }));

  const citiesOptions = cities.map((elem) => ({
    ...elem,
    label: elem.name,
    value: elem.id,
  }));

  const timezonesOptions = timezones.map((elem) => ({
    ...elem,
    label: elem.name,
    value: elem.id,
  }));

  const onEditSave = async (values, setUserData = () => {}) => {
    try {
      setIsLoading(true);

      const {
        availability,
        city: cityId,
        companyName,
        companyWebsite,
        country: countryId,
        email,
        phoneNumber: phone,
        representativeName,
        timezone: timezoneId,
      } = values;

      const payload = {
        companyName,
        companyWebsite,
        email,
        phone,
        countryId,
        cityId,
        timezoneId,
        availability,
        representativeName,
      };

      await profileApi.updateCompanyDetails(userDetails?.agencyId, payload);
      setUserData((prevState) => ({
        ...prevState,
        ...values,

        country: countries.find((elem) => elem.id === values.country),
        city: cities.find((elem) => elem.id === values.city),
        timezone: timezones.find((elem) => elem.id === values.timezone),
        phone: values.phoneNumber,
      }));
      setIsEditCompanyDetails(false);
      notifySuccess("Has been updated successfully");
      scrollIntoView("companyDetailsScrollId");
    } catch (err) {
      console.error(err);
      notifyError(err?.data?.error ?? err?.message ?? "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!!countryId) {
      getCities(countryId);
      getTimeZones(countryId);
    }
  }, [countryId]);

  const resetOnUnmount = () => {
    setAvailableDays(
      availability?.days?.length
        ? availability?.days.map((dayNum) => {
            return availableDaysNumberMapper[dayNum];
          })
        : []
    );
  };
  useEffect(() => {
    return () => {
      resetOnUnmount();
    };
  }, []);

  return {
    isEditCompanyDetails,
    setIsEditCompanyDetails,
    onEditSave,
    countries,
    cities,
    timezones,
    countriesOptions,
    citiesOptions,
    timezonesOptions,
    availableDays,
    setAvailableDays,
    isLoading,
  };
}

export default useCompanyDetails;
