export const customStyle = {
  control: (provided, state) => {
    return {
      ...provided,
      border: 0,
      borderColor: state.isFocused ? "#023A66" : provided.borderColor,
      "&:hover": {
        borderColor: "#023A66 !important",
      },
    };
  },

  option: (styles, { data, isFocused, isSelected, isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? null : isSelected ? "#023A66" : data.color,
    color: isDisabled ? "#ccc" : isSelected ? "white" : data.color,
    cursor: isDisabled ? "not-allowed" : "default",
  }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),

  menuList: (provided, state) => ({
    ...provided,
    zIndex: 10,
  }),

  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
};
