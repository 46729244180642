import { useState, useEffect } from "react";
import useNotify from "hooks/useNotify";
import { useTranslation } from "react-i18next";
import { scrollIntoView } from "utils/helper";
import profileApi from "api/profile";

function usePersonalExperience({
  userDetails = {},
  profileCompletionRef = {},
}) {
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditServices, setIsEditServices] = useState(false);

  const { notifyError, notifySuccess } = useNotify();

  profileCompletionRef.services = {
    openEdit: setIsEditServices,
    services: userDetails?.userServices ?? [],
  };

  const onEditServices = async (values, setUserData = () => {}) => {
    try {
      setIsLoading(true);
      const vendorId = userDetails?.vendorId;
      const agencyId = userDetails?.agencyId;
      const { serviceRates = [], services = [] } = values;
      const payload = {
        serviceRates,
        services,
      };

      if (vendorId) {
        await profileApi.updateVendorServices(vendorId, payload);
      } else {
        await profileApi.updateAgencyServices(agencyId, payload);
      }

      const userData = await profileApi.getUserProfileByIdApi(userDetails?.id);
      setUserData(userData);
      setIsEditServices(false);
      notifySuccess("Has been updated successfully");
      scrollIntoView("profileServiceScrollId");
    } catch (err) {
      console.error(err);
      notifyError(err?.data?.error ?? err?.message ?? "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  const resetOnUnmount = () => {};
  useEffect(() => {
    return () => {
      resetOnUnmount();
    };
  }, []);

  return {
    isEditServices,
    setIsEditServices,
    onEditServices,
    isProfileServicesLoading: isLoading,
  };
}

export default usePersonalExperience;
