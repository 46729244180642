import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import BGShutterstock from "images/auth/bg-shutterstock.png";
import { ReactComponent as TVendorIcon } from "images/auth/t-vendors.svg";
import InputComponent from "components/input";
import useScrollToTop from "hooks/useScrollToTop";
import useSetPassword from "./useSetPassword";

const SetPassword = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { onSubmit, isLoading } = useSetPassword();

  useScrollToTop();

  return (
    <form
      className="w-full h-full bg-no-repeat flex-1 bg-cover min-h-[calc(100%-50px)] bg-center flex items-center justify-center md-max:justify-start  py-[60px] px-[120px] md-max:px-[1rem]"
      style={{
        backgroundImage: `url(${BGShutterstock})`,
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        className="w-full max-w-[700px] md-max:max-w-full h-full min-h-[591px] md-max:max-h-full  rounded-[20px] flex-shrink-0 flex items-center justify-center p-[1rem]"
        style={{ background: "rgb(2,23,51, 0.65)" }}
      >
        <div className="flex flex-col items-start gap-[40px] w-[330px]">
          <div className="flex flex-col items-start gap-[10px]">
            <TVendorIcon />
            <div className="text-white font-medium text-[36px] not-italic font-normal">
              {t("setPasswordTitle")}
            </div>
          </div>

          <div className="flex flex-col gap-[18px] self-stretch">
            <InputComponent
              label={t("newPasswordTitle")}
              isRequired={true}
              type="password"
              name="password"
              placeholder={t("newPasswordPlaceholder")}
              errorMessage={errors.password ? errors.password.message : ""}
              register={register}
              registerArgs={{
                required: t("errorField", { field: t("newPasswordTitle") }),
                minLength: {
                  value: 8,
                  message: t("errorMinCharacter", {
                    field: t("newPasswordTitle"),
                    value: 8,
                  }),
                },
              }}
            />

            <InputComponent
              label={t("confirmPassword")}
              isRequired={true}
              type="password"
              name="confirmPassword"
              placeholder={t("confirmPasswordPlaceholder")}
              errorMessage={
                errors.confirmPassword ? errors.confirmPassword.message : ""
              }
              register={register}
              registerArgs={{
                required: t("errorField", { field: t("confirmPassword") }),
                validate: (val) => {
                  if (watch("password") != val) {
                    return t("confirmPassErrorMsg");
                  }
                },
              }}
            />

            <button
              className="flex py-[18px] px-[20px] justify-center items-center gap-[8px] rounded-[6px] border-[1px] border-solid border-light-primary bg-light-primary h-[48px]"
              type="submit"
              disabled={isLoading}
            >
              <Oval
                height={30}
                width={30}
                color="#023A66"
                visible={isLoading}
                ariaLabel="oval-loading"
                secondaryColor="#023A66"
                strokeWidth={5}
                strokeWidthSecondary={3}
              />
              <div className="text-primary font-bold text-[18px] not-italic font-normal">
                {t("setPasswordCta")}
              </div>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SetPassword;
