import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Input from "components/input";

const RateTypesInputs = ({
  rateType,
  onHandleChange,
  serviceId,
  rateIndex,
  setValue,
  showDisclaimer,
  isCharacter = false,
}) => {
  const [t] = useTranslation();
  return (
    <div className="grid gap-[10px] w-full">
      {rateType.withRate && (
        <div className="text-gray-500 font-regular text-[14px] not-italic mt-[10px] ">
          {t(`subTitle-${rateType.title}`, {
            isCharacter: isCharacter ? t("character") : t("word"),
          })}
        </div>
      )}

      {rateType.withRate && showDisclaimer && (
        <div className="text-red font-bold text-[14px] not-italic mt-[10px] ">
          {t("rateDisclaimer")}
        </div>
      )}

      {rateType.withRate && (
        <div
          className={`grid ${
            rateType.withCapacity ? "grid-cols-2" : "grid-cols-1"
          } sm-max:grid-cols-1 gap-[10px]`}
        >
          <Input
            value={rateType.rate || null}
            placeholder={t(`rate-${rateType.title}`, {
              isCharacter: isCharacter ? t("character") : t("word"),
            })}
            onChange={(e) => {
              const newRate = Number(e.target.value);
              onHandleChange(newRate, serviceId, "rate", rateIndex, setValue);
            }}
            label=""
            inputMode="numeric"
            type="number"
            name="rate"
            min={0}
            step={0.001}
            labelClassName="text-primary font-semibold text-[16px]"
            inputClassName={`flex h-[43px] w-full sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${"border-default-color"} bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal placeholder:text-gray-500 py-[16px] px-[14px]`}
          />

          {rateType.withCapacity && (
            <Input
              value={rateType.capacity || null}
              placeholder={t(`${rateType.title}`, {
                isCharacter: isCharacter
                  ? t("character").toLowerCase()
                  : t("word").toLowerCase(),
              })}
              min={0}
              onChange={(e) => {
                const newCapacity = Number(e.target.value);
                onHandleChange(
                  newCapacity,
                  serviceId,
                  "capacity",
                  rateIndex,
                  setValue
                );
              }}
              label=""
              inputMode="numeric"
              type="number"
              name="capacity"
              labelClassName="text-primary font-semibold text-[16px]"
              inputClassName="flex h-[43px] w-full sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid border-default-color bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px] "
            />
          )}
        </div>
      )}
    </div>
  );
};

RateTypesInputs.propTypes = {
  rateType: PropTypes.object,
  onHandleChange: PropTypes.func,
  serviceId: PropTypes.string,
  rateIndex: PropTypes.number,
  setValue: PropTypes.func,
  showDisclaimer: PropTypes.bool,
  isCharacter: PropTypes.bool,
};

export default RateTypesInputs;
