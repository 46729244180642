import React, { Fragment } from "react";
import PropTypes from "prop-types";
import LabelComponent from "pages/profile/common/label";
import { ReactComponent as EditIcon } from "images/profile/edit-icon.svg";
import { useTranslation } from "react-i18next";

function ContactDetails({
  userDetails = {},
  onEditClick = () => {},
  isShowEditIcon = false,
}) {
  const [t] = useTranslation();
  const contacts = userDetails?.contacts ?? [];

  return (
    <div className="flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]">
      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        <div className="text-primary font-semibold text-[30px] not-italic">
          {t("contactDetailsTitle")}
        </div>
        {isShowEditIcon && (
          <EditIcon className="cursor-pointer" onClick={onEditClick} />
        )}
      </div>

      {contacts.map((contact, i) => {
        return (
          <Fragment key={`${i}`}>
            <div className="flex flex-wrap gap-[20px]">
              <LabelComponent
                title={t("name")}
                subTitle={`${contact?.name ?? "-"}`}
              />

              <LabelComponent
                title={t("email")}
                subTitle={`${contact?.email ?? "-"}`}
              />
              <LabelComponent
                title={t("department")}
                subTitle={`${contact?.department ?? "-"}`}
              />

              <LabelComponent
                title={t("position")}
                subTitle={`${contact?.position ?? "-"}`}
              />
            </div>
            {i < contacts.length - 1 && (
              <div className="h-[1px] w-full  grid bg-default-color" />
            )}
          </Fragment>
        );
      })}
    </div>
  );
}

ContactDetails.propTypes = {
  userDetails: PropTypes.object,
  onEditClick: PropTypes.func,
  isShowEditIcon: PropTypes.bool,
};

export default ContactDetails;
