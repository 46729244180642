import React from "react";
import PropTypes from "prop-types";
import Greeting from "pages/profile/common/greeting";
import {
  STATUS_QUALIFIED,
  SUBSTATUS_ONBOARDING_REQUEST,
  STATUS_ACTIVE,
  STATUS_BLACKLIST,
  STATUS_SUSPENDED,
  SUBSTATUS_READY_FOR_WORK,
  SUBSTATUS_ONBOARDING_DONE,
  SUBSTATUS_PENDING_INVESTIGATION,
  SUBSTATUS_DO_NOT_USE,
  SUBSTATUS_AGREEMENT_SENT,
  SUBSTATUS_DECLINED_AGREEMENT,
  SUBSTATUS_EXPIRED_AGREEMENT,
  SUBSTATUS_UNDER_VALIDATION,
} from "utils/constantValues";
import useProfileHelper from "../useProfileHelper";
import PersonalDetails from "./personalDetails";
import EditPersonalDetails from "./personalDetails/editPersonalDetails";
import { useTranslation } from "react-i18next";
import ProfileExperience from "../common/profileExperience";
import EditPersonalExperience from "../common/profileExperience/editExperience";
import ProfileServices from "../common/profileServices";
import EditProfileServices from "../common/profileServices/editProfileServices";
import ProfileResume from "./profileResume";
import EditProfileResume from "./profileResume/editProfileResume";
import RelevantProfile from "./relevantProfiles";
import EditRelevantProfile from "./relevantProfiles/editRelevantProfile";
import ProfileCertification from "../common/profileCertification";
import EditProfileCertifications from "../common/profileCertification/editProfileCertification";
import usePersonalProfile from "./personalDetails/usePersonalProfile";
import usePersonalExperience from "../common/profileExperience/usePersonalExperience";
import useProfileResume from "./profileResume/useProfileResume";
import useRelevantProfile from "./relevantProfiles/useRelevantProfile";
import useProfileCertifications from "../common/profileCertification/useProfileCertifications";
import useProfileServices from "../common/profileServices/useProfileServices";
import { scrollIntoView } from "utils/helper";
import VendorForm from "./vendorForm";
import useLegalDetails from "./legalDetails/useLegalDetails";
import LegalDetails from "./legalDetails";
import EditLegalDetails from "./legalDetails/editLegalDetails";
import useQualifications from "./qualifications/useQualifications";
import EditQualifications from "./qualifications/editQualifications";
import Qualifications from "./qualifications";
import useContactDetails from "./contactDetails/useContactDertails";
import EditContactDetails from "./contactDetails/editContactDetails";
import ContactDetails from "./contactDetails";
import useBankDetails from "./bankDetails/useBankDetails";
import EditBankDetails from "./bankDetails/editBankDetails";
import BankDetails from "./bankDetails";
import useEmergencyContact from "./emergencyContact/useEmergencyContact";
import EditEmergencyContact from "./emergencyContact/editEmergencyContact";
import EmergencyContact from "./emergencyContact";
import VmFileDocuments from "../common/vmFileDocuments";
import ProfileCompletion from "components/profileCompletion";
import useProfileCompletion from "hooks/useProfileCompletion";

function VendorProfile({
  userDetails = {},
  setUserDetails = () => {},
  userDomains = [],
  setUserDomains = () => {},
  userSubDomains = [],
  setUserSubDomains = () => {},
}) {
  const [t] = useTranslation();
  const showOnboardingForum =
    [
      STATUS_ACTIVE,
      STATUS_QUALIFIED,
      STATUS_BLACKLIST,
      STATUS_SUSPENDED,
    ].includes(userDetails?.status) &&
    [
      SUBSTATUS_READY_FOR_WORK,
      SUBSTATUS_ONBOARDING_DONE,
      SUBSTATUS_PENDING_INVESTIGATION,
      SUBSTATUS_DO_NOT_USE,
      SUBSTATUS_AGREEMENT_SENT,
      SUBSTATUS_DECLINED_AGREEMENT,
      SUBSTATUS_EXPIRED_AGREEMENT,
      SUBSTATUS_UNDER_VALIDATION,
    ].includes(userDetails?.subStatus);

  const isShowEditIcon = ![STATUS_BLACKLIST, STATUS_SUSPENDED].includes(
    userDetails?.status
  );

  const { onCountryTitle, profileCompletionRef } = useProfileHelper();
  const {
    isEditPersonalProfile,
    setIsEditPersonalProfile,
    onEditSave,
    isLoading,
  } = usePersonalProfile({ userDetails, profileCompletionRef });

  const {
    isEditPersonalExperience,
    setIsEditPersonalExperience,
    onEditPersonalExperience,
    isExperienceLoading,
  } = usePersonalExperience({
    userDetails,
    profileCompletionRef,
    userDomains,
    userSubDomains,
  });

  const {
    isEditProfileResume,
    setIsEditProfileResume,
    onEditResume,
    isResumeLoading,
  } = useProfileResume({ userDetails, profileCompletionRef });

  const {
    isEditRelevantProfile,
    setIsEditRelevantProfile,
    onEditRelevantProfile,
    isRelevantProfileLoading,
  } = useRelevantProfile({ userDetails, profileCompletionRef });

  const {
    isEditCertificationFiles,
    setIsEditCertificationFiles,
    onEditCertificationsFiles,
    isCertificationLoading,
  } = useProfileCertifications({ userDetails, profileCompletionRef });

  const {
    isEditServices,
    setIsEditServices,
    onEditServices,
    isProfileServicesLoading,
  } = useProfileServices({ userDetails, profileCompletionRef });

  const {
    isEditLegalDetails,
    setIsEditLegalDetails,
    onEditLegalDetails,
    isLegalDetailsLoading,
  } = useLegalDetails({ userDetails, profileCompletionRef });

  const {
    isEditQualifications,
    setIsEditQualifications,
    onEditQualifications,
    isQualificationsLoading,
  } = useQualifications({ userDetails, profileCompletionRef });
  const {
    isEditContactDetails,
    setIsEditContactDetails,
    onEditContactDetails,
    isContactDetailsLoading,
  } = useContactDetails({ userDetails, profileCompletionRef });

  const {
    isEditBankDetails,
    setIsEditBankDetails,
    onEditBankDetails,
    isBankDetailsLoading,
  } = useBankDetails({ userDetails, profileCompletionRef });

  const {
    isEditEmergencyContact,
    setIsEditEmergencyContact,
    onEditEmergencyContact,
    isEmergencyContactLoading,
  } = useEmergencyContact({ userDetails, profileCompletionRef });

  const {
    sections,
    allSectionPercentages,
    onOpenEditSection,
    bounceToSection,
  } = useProfileCompletion({
    userDetails,
    profileCompletionRef,
    showOnboardingForum,
    isShowEditIcon,
  });

  if (
    userDetails?.status === STATUS_QUALIFIED &&
    userDetails?.subStatus === SUBSTATUS_ONBOARDING_REQUEST
  ) {
    return (
      <VendorForm userDetails={userDetails} setUserDetails={setUserDetails} />
    );
  }

  return (
    <div className="grid md-max:flex md-max:flex-1 grid-cols-custom-grid-profile md-max:flex-col-reverse  gap-[24px] md-max:gap-[10px] ">
      <div className="grid w-full">
        <div className="md-max:hidden grid w-full">
          <Greeting
            title={t("greetingTitle", {
              title: `${userDetails?.firstName ?? ""} ${
                userDetails?.lastName ?? ""
              }`,
            })}
            email={userDetails?.email ?? ""}
            subTitle={onCountryTitle(
              userDetails.country?.name,
              userDetails.city?.name,
              userDetails.timezone?.name
            )}
          />
        </div>
        <div className="grid w-full" id="personalDetailsScrollId">
          {isEditPersonalProfile ? (
            <EditPersonalDetails
              userDetails={userDetails}
              onDiscard={() => {
                setIsEditPersonalProfile(false);
                scrollIntoView("personalDetailsScrollId");
              }}
              onSave={(values) => onEditSave(values, setUserDetails)}
              isLoading={isLoading}
            />
          ) : (
            <PersonalDetails
              userDetails={userDetails}
              onEditClick={() => setIsEditPersonalProfile(true)}
              isShowEditIcon={isShowEditIcon}
            />
          )}
        </div>

        <div className="grid w-full" id="experienceScrollId">
          {isEditPersonalExperience ? (
            <EditPersonalExperience
              userDetails={userDetails}
              isVendor={true}
              onDiscard={() => {
                setIsEditPersonalExperience(false);
                scrollIntoView("experienceScrollId");
              }}
              onSave={(values) =>
                onEditPersonalExperience(
                  values,
                  setUserDetails,
                  setUserDomains,
                  setUserSubDomains
                )
              }
              isLoading={isExperienceLoading}
              userDomains={userDomains}
              userSubDomains={userSubDomains}
            />
          ) : (
            <ProfileExperience
              userDetails={userDetails}
              isVendor={true}
              onEditClick={() => setIsEditPersonalExperience(true)}
              isShowEditIcon={isShowEditIcon}
              userDomains={userDomains}
              userSubDomains={userSubDomains}
            />
          )}
        </div>

        {showOnboardingForum && (
          <div className="grid w-full" id="qualificationsScrollId">
            {isEditQualifications ? (
              <EditQualifications
                userDetails={userDetails}
                onDiscard={() => {
                  setIsEditQualifications(false);
                  scrollIntoView("qualificationsScrollId");
                }}
                onSave={(values) =>
                  onEditQualifications(values, setUserDetails)
                }
                isLoading={isQualificationsLoading}
              />
            ) : (
              <Qualifications
                userDetails={userDetails}
                onEditClick={() => setIsEditQualifications(true)}
                isShowEditIcon={isShowEditIcon}
              />
            )}
          </div>
        )}

        <div className="grid w-full" id="profileServiceScrollId">
          {isEditServices ? (
            <EditProfileServices
              userDetails={userDetails}
              isVendor={true}
              onDiscard={() => {
                setIsEditServices(false);
                scrollIntoView("profileServiceScrollId");
              }}
              onSave={(values) => onEditServices(values, setUserDetails)}
              isLoading={isProfileServicesLoading}
            />
          ) : (
            <ProfileServices
              userDetails={userDetails}
              isVendor={true}
              onEditClick={() => setIsEditServices(true)}
              isShowEditIcon={isShowEditIcon}
            />
          )}
        </div>

        {showOnboardingForum && (
          <div className="grid w-full" id="contactDetailsScrollId">
            {isEditContactDetails ? (
              <EditContactDetails
                userDetails={userDetails}
                onDiscard={() => {
                  setIsEditContactDetails(false);
                  scrollIntoView("contactDetailsScrollId");
                }}
                onSave={(values) =>
                  onEditContactDetails(values, setUserDetails)
                }
                isLoading={isContactDetailsLoading}
              />
            ) : (
              <ContactDetails
                userDetails={userDetails}
                onEditClick={() => setIsEditContactDetails(true)}
                isShowEditIcon={isShowEditIcon}
              />
            )}
          </div>
        )}

        {showOnboardingForum && (
          <div className="grid w-full" id="legalDetailsScrollId">
            {isEditLegalDetails ? (
              <EditLegalDetails
                userDetails={userDetails}
                onDiscard={() => {
                  setIsEditLegalDetails(false);
                  scrollIntoView("legalDetailsScrollId");
                }}
                onSave={(values) => onEditLegalDetails(values, setUserDetails)}
                isLoading={isLegalDetailsLoading}
              />
            ) : (
              <LegalDetails
                userDetails={userDetails}
                onEditClick={() => setIsEditLegalDetails(true)}
                isShowEditIcon={isShowEditIcon}
              />
            )}
          </div>
        )}

        {showOnboardingForum && (
          <div className="grid w-full" id="bankDetailsScrollId">
            {isEditBankDetails ? (
              <EditBankDetails
                userDetails={userDetails}
                onDiscard={() => {
                  setIsEditBankDetails(false);
                  scrollIntoView("bankDetailsScrollId");
                }}
                onSave={(values) => onEditBankDetails(values, setUserDetails)}
                isLoading={isBankDetailsLoading}
              />
            ) : (
              <BankDetails
                userDetails={userDetails}
                onEditClick={() => setIsEditBankDetails(true)}
                isShowEditIcon={isShowEditIcon}
              />
            )}
          </div>
        )}
        {showOnboardingForum && (
          <div className="grid w-full" id="emergencyContactScrollId">
            {isEditEmergencyContact ? (
              <EditEmergencyContact
                userDetails={userDetails}
                onDiscard={() => {
                  setIsEditEmergencyContact(false);
                  scrollIntoView("emergencyContactScrollId");
                }}
                onSave={(values) =>
                  onEditEmergencyContact(values, setUserDetails)
                }
                isLoading={isEmergencyContactLoading}
              />
            ) : (
              <EmergencyContact
                userDetails={userDetails}
                onEditClick={() => setIsEditEmergencyContact(true)}
                isShowEditIcon={isShowEditIcon}
              />
            )}
          </div>
        )}
      </div>

      <div className="flex flex-col gap-[10px]">
        <ProfileCompletion
          sections={sections}
          allSectionPercentages={allSectionPercentages}
          onOpenEditSection={onOpenEditSection}
          bounceToSection={bounceToSection}
        />

        <div className="grid w-full" id="resumeScrollId">
          {isEditProfileResume ? (
            <EditProfileResume
              userDetails={userDetails}
              onDiscard={() => {
                setIsEditProfileResume(false);
                scrollIntoView("resumeScrollId");
              }}
              onSave={(values) => onEditResume(values, setUserDetails)}
              isLoading={isResumeLoading}
            />
          ) : (
            <ProfileResume
              userDetails={userDetails}
              onEditClick={() => setIsEditProfileResume(true)}
              isShowEditIcon={isShowEditIcon}
            />
          )}
        </div>

        <div className="grid w-full" id="relevantProfileIdScrollId">
          {isEditRelevantProfile ? (
            <EditRelevantProfile
              userDetails={userDetails}
              onDiscard={() => {
                setIsEditRelevantProfile(false);
                scrollIntoView("relevantProfileIdScrollId");
              }}
              onSave={(values) => onEditRelevantProfile(values, setUserDetails)}
              isLoading={isRelevantProfileLoading}
            />
          ) : (
            <RelevantProfile
              userDetails={userDetails}
              onEditClick={() => setIsEditRelevantProfile(true)}
              isShowEditIcon={isShowEditIcon}
            />
          )}
        </div>

        <div className="grid w-full" id="certificationScrollId">
          {isEditCertificationFiles ? (
            <EditProfileCertifications
              userDetails={userDetails}
              onDiscard={() => {
                setIsEditCertificationFiles(false);
                scrollIntoView("certificationScrollId");
              }}
              onSave={(values) =>
                onEditCertificationsFiles(values, setUserDetails)
              }
              isLoading={isCertificationLoading}
            />
          ) : (
            <ProfileCertification
              userDetails={userDetails}
              onEditClick={() => setIsEditCertificationFiles(true)}
              isShowEditIcon={isShowEditIcon}
            />
          )}
        </div>

        <div className="grid w-full">
          <VmFileDocuments userDetails={userDetails} />
        </div>
      </div>
      <div className="hidden md-max:grid w-full">
        <Greeting
          title={`Welcome ${userDetails?.name ?? ""}`}
          subTitle={onCountryTitle(
            userDetails.country?.name,
            userDetails.city?.name,
            userDetails.timezone?.name
          )}
        />
      </div>
    </div>
  );
}

VendorProfile.propTypes = {
  userDetails: PropTypes.object,
  setUserDetails: PropTypes.func,
  userDomains: PropTypes.array,
  setUserDomains: PropTypes.func,
  userSubDomains: PropTypes.array,
  setUserSubDomains: PropTypes.func,
};

export default VendorProfile;
