import { useState, useEffect } from "react";
import { useLookupsContext } from "hooks/useContext";
import { availableDaysNumberMapper } from "utils/constantValues";
import useNotify from "hooks/useNotify";
import profileApi from "api/profile";
import { scrollIntoView } from "utils/helper";

function useLegalDetails({ userDetails = {}, profileCompletionRef = {} }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditLegalDetails, setIsEditLegalDetails] = useState(false);

  const { countries } = useLookupsContext(true);

  const { notifyError, notifySuccess } = useNotify();

  const countriesOptions = countries.map((elem) => ({
    ...elem,
    label: elem.name,
    value: elem.id,
  }));

  profileCompletionRef.legalDetails = {
    openEdit: setIsEditLegalDetails,
    gender: userDetails?.extraInformation?.personalDetails?.gender ?? "",
    dateOfBirth:
      userDetails?.extraInformation?.personalDetails?.dateOfBirth ?? "",
    martialStatus:
      userDetails?.extraInformation?.personalDetails?.martialStatus ?? "",
    countryOfBirth:
      userDetails?.extraInformation?.personalDetails?.countryOfBirth ?? "",
    placeOfBirth:
      userDetails?.extraInformation?.personalDetails?.placeOfBirth ?? "",
    socialSecurityNo:
      userDetails?.extraInformation?.personalDetails?.socialSecurityNo ?? "",
    nationalCardNo:
      userDetails?.extraInformation?.personalDetails?.nationalCardNo ?? "",
    expiryDate:
      userDetails?.extraInformation?.personalDetails?.expiryDate ?? "",
    countryOfIssue:
      userDetails?.extraInformation?.personalDetails?.countryOfIssue ?? "",
    placeOfIssue:
      userDetails?.extraInformation?.personalDetails?.placeOfIssue ?? "",
  };

  const onEditLegalDetails = async (values, setUserData = () => {}) => {
    try {
      setIsLoading(true);

      const payload = { legalDetails: values };

      await profileApi.updateLegalDetails(userDetails?.vendorId, payload);
      setUserData((prevState) => {
        prevState.extraInformation.personalDetails = values;
        return prevState;
      });
      setIsEditLegalDetails(false);
      notifySuccess("Has been updated successfully");
      scrollIntoView("legalDetailsScrollId");
    } catch (err) {
      console.error(err);
      notifyError(err?.data?.error ?? err?.message ?? "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  const resetOnUnmount = () => {};
  useEffect(() => {
    return () => {
      resetOnUnmount();
    };
  }, []);

  return {
    isEditLegalDetails,
    setIsEditLegalDetails,
    onEditLegalDetails,
    isLegalDetailsLoading: isLoading,
    countries,
    countriesOptions,
  };
}

export default useLegalDetails;
