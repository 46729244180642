import React, { Fragment } from "react";
import PropTypes from "prop-types";
import LabelComponent from "pages/profile/common/label";
import CardCheckbox from "components/cardCheckbox";
import { ReactComponent as EditIcon } from "images/profile/edit-icon.svg";
import { numberToAvailableDaysMapper } from "utils/constantValues";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FORMAT_DATE } from "utils/constantValues";

function LegalDetails({
  userDetails = {},
  onEditClick = () => {},
  isShowEditIcon = false,
}) {
  const [t] = useTranslation();

  return (
    <div className="flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]">
      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        <div className="text-primary font-semibold text-[30px] not-italic">
          {t("legalDetailsTitle")}
        </div>
        {isShowEditIcon && (
          <EditIcon className="cursor-pointer" onClick={onEditClick} />
        )}
      </div>

      <div className="flex flex-wrap gap-[20px]">
        <LabelComponent
          title={t("gender")}
          subTitle={`${
            userDetails?.extraInformation?.personalDetails?.gender ?? "-"
          }`}
        />
        <LabelComponent
          title={t("dateOfBirth")}
          subTitle={`${
            userDetails?.extraInformation?.personalDetails?.dateOfBirth
              ? moment(
                  userDetails?.extraInformation?.personalDetails?.dateOfBirth
                ).format(FORMAT_DATE)
              : "-"
          }`}
        />
        <LabelComponent
          title={t("martialStatus")}
          subTitle={`${
            userDetails?.extraInformation?.personalDetails?.martialStatus ?? "-"
          }`}
        />
        <LabelComponent
          title={t("countryOfBirth")}
          subTitle={`${
            userDetails?.extraInformation?.personalDetails?.countryOfBirth ??
            "-"
          }`}
        />
        <LabelComponent
          title={t("placeOfBirth")}
          subTitle={`${
            userDetails?.extraInformation?.personalDetails?.placeOfBirth ?? "-"
          }`}
        />

        <LabelComponent
          title={t("socialSecurityNumber")}
          subTitle={`${
            userDetails?.extraInformation?.personalDetails?.socialSecurityNo ??
            "-"
          }`}
        />

        <LabelComponent
          title={t("nationalCardNo")}
          subTitle={`${
            userDetails?.extraInformation?.personalDetails?.nationalCardNo ??
            "-"
          }`}
        />

        <LabelComponent
          title={t("expiryDate")}
          subTitle={`${
            userDetails?.extraInformation?.personalDetails?.expiryDate
              ? moment(
                  userDetails?.extraInformation?.personalDetails?.expiryDate
                ).format(FORMAT_DATE)
              : "-"
          }`}
        />

        <LabelComponent
          title={t("countryOfIssue")}
          subTitle={`${
            userDetails?.extraInformation?.personalDetails?.countryOfIssue ??
            "-"
          }`}
        />

        <LabelComponent
          title={t("placeOfIssue")}
          subTitle={`${
            userDetails?.extraInformation?.personalDetails?.placeOfIssue ?? "-"
          }`}
        />
      </div>
    </div>
  );
}

LegalDetails.propTypes = {
  userDetails: PropTypes.object,
  onEditClick: PropTypes.func,
  isShowEditIcon: PropTypes.bool,
};

export default LegalDetails;
