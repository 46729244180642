import React, { useReducer } from "react";
import { appContextStore } from "context/createContext";
import LookupsState from "context/lookupsContext";
import { SET_USER, IS_AUTH_USER, REST_USER } from "context/types";

import AppReducer, { initialState } from "./appReducer";

const AppState = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  const setUser = (user = {}) => dispatch({ type: SET_USER, payload: user });
  const setAuth = (isAuth = false) => dispatch({ type: IS_AUTH_USER, isAuth });
  const resetUser = () => dispatch({ type: REST_USER });

  return (
    <appContextStore.Provider
      value={{
        ...state,
        setUser,
        setAuth,
        resetUser,
      }}
    >
      {/* Our lookups state for the App */}
      <LookupsState>{children}</LookupsState>
    </appContextStore.Provider>
  );
};

export default AppState;
