import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Oval } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import Input from "components/input";
import UploadFile from "components/uploadFile";
import useUploadFiles from "pages/signup/hooks/useUploadFiles";
import Chips from "components/chips";
import { ReactComponent as AttachIcon } from "images/auth/attach.svg";
import { ReactComponent as AddCircleIcon } from "images/auth/add-circle.svg";
import { ReactComponent as TrashIcon } from "images/auth/trash.svg";
import { sampleOfWorkType } from "utils/constantValues";
import { useTranslation } from "react-i18next";
import useRelevantProfile from "./useRelevantProfile";

function EditRelevantProfile({
  userDetails = {},
  onDiscard = () => {},
  onSave = () => {},
  isLoading = false,
}) {
  const sampleOfWork = userDetails?.userFiles
    ?.filter((file) => file.type === sampleOfWorkType)
    .map((file) => ({ ...file, fileId: file.id }));

  const [t] = useTranslation();

  const defaultsValues = {
    vendorAdditionalInfo: {
      profile: userDetails?.extraInformation?.profile ?? [],
    },
    newCreated: [],
    deleted: [],
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultsValues,
  });

  const {
    sampleOfWorkRef,
    uploadSampleOfFiles,
    isUploading,
    SOWFiles,
    removeSampleOfFile,
  } = useUploadFiles({ defaultSampleOfWorkFiles: sampleOfWork });

  const { relevantProfiles, setRelevantProfile, removeRelevantProfile } =
    useRelevantProfile({ userDetails });

  return (
    <form
      className="flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]"
      onSubmit={handleSubmit(onSave)}
    >
      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        <div className="text-primary font-semibold text-[30px] not-italic">
          {t("relevantProfileTitle")}
        </div>
      </div>

      <div className="flex flex-wrap gap-[20px]">
        <div className="flex flex-col w-full gap-[10px]">
          {relevantProfiles.map((_, index) => {
            return (
              <Fragment key={`${index}`}>
                <div className="flex gap-[10px] items-center w-full">
                  <Input
                    name={`${index}`}
                    label={index === 0 ? t("ProfileLinkTitle") : ""}
                    value={watch("vendorAdditionalInfo")?.profile[index] || ""}
                    onChange={(e) => {
                      const { value } = e.target;
                      let vendorAdditionalInfo = getValues(
                        "vendorAdditionalInfo"
                      );

                      if (!Array.isArray(vendorAdditionalInfo?.profile)) {
                        vendorAdditionalInfo = { profile: [] };
                      }

                      const updatedProfile = [...vendorAdditionalInfo.profile];

                      updatedProfile[index] = value;
                      setValue("vendorAdditionalInfo", {
                        ...vendorAdditionalInfo,
                        profile: updatedProfile,
                      });
                    }}
                    placeholder={t("placeHolderType", {
                      title: t("ProfileLinkTitle").toLowerCase(),
                    })}
                    containerClassName="flex flex-col gap-[10px] w-full"
                    labelClassName="text-primary font-semibold text-[16px]"
                    inputClassName="flex h-[43px] w-full sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid border-default-color bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px] "
                  />
                  {index >= 1 && (
                    <TrashIcon
                      className="cursor-pointer"
                      onClick={() => {
                        const vendorAdditionalInfo = getValues(
                          "vendorAdditionalInfo"
                        );

                        const removedProfile =
                          vendorAdditionalInfo.profile.filter(
                            (_, i) => i !== index
                          );
                        setValue("vendorAdditionalInfo", {
                          ...vendorAdditionalInfo,
                          profile: removedProfile,
                        });
                        removeRelevantProfile(index);
                      }}
                    />
                  )}
                </div>

                {index === relevantProfiles.length - 1 && (
                  <div
                    className="flex gap-[8px] cursor-pointer"
                    onClick={() => {
                      let vendorAdditionalInfo = getValues(
                        "vendorAdditionalInfo"
                      );

                      if (!Array.isArray(vendorAdditionalInfo?.profile)) {
                        vendorAdditionalInfo = { profile: [] };
                      }

                      const updatedProfile = [...vendorAdditionalInfo.profile];

                      setValue("vendorAdditionalInfo", {
                        ...vendorAdditionalInfo,
                        profile: [...updatedProfile, ""],
                      });
                      setRelevantProfile([...relevantProfiles, index]);
                    }}
                  >
                    <AddCircleIcon />
                    <div className="text-primary font-bold text-[16px] not-italic">
                      {t("addAnotherProfile")}
                    </div>
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>

      <div className="h-[1px] w-full  grid bg-default-color" />

      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        <div className="text-primary font-semibold text-[30px] not-italic">
          {t("sampleOfWorkTitle")}
        </div>
      </div>

      <div className="grid  gap-[12px]">
        <UploadFile
          name={"sampleOfWorks"}
          register={register}
          label={t("sampleOfWorkTitle")}
          isMultiFile={true}
          placeHolderLabel={t("attachYourFiles")}
          placeHolderContainerClassName="flex p-[14px] justify-between items-center gap-[8px] self-stretch rounded-[6px] h-[54px] w-full border-[1px] border-dashed border-dark-gray cursor-pointer"
          icon={<AttachIcon />}
          isUploading={isUploading.sampleOfWork}
          getFile={(e, isDrag) => {
            const callback = (type, files) => {
              setValue(type, files);
              setValue("newCreated", files);
            };
            uploadSampleOfFiles(e, isDrag, callback);
          }}
          labelClassName="text-primary font-semibold text-[16px]"
          ref={sampleOfWorkRef}
        />
        <Chips
          chips={SOWFiles.map((file) => file.name)}
          onClick={(chipLabel, index) => {
            const prevDeletedFiles = watch("deleted") || [];
            const deletedFile = SOWFiles.filter(
              (file) => file.name === chipLabel
            );
            setValue("deleted", [...prevDeletedFiles, ...deletedFile]);

            removeSampleOfFile(chipLabel);
          }}
        />
      </div>

      <div className="flex items-center justify-end gap-[10px]">
        <button
          type="button"
          onClick={onDiscard}
          disabled={isLoading || isUploading.sampleOfWork}
          className="cursor-pointer flex py-[14px] px-[16px] gap-[8px] justify-center items-center rounded-[6px] border-[1px] border-solid border-default-color bg-white h-[46px]"
        >
          <div className="text-base-black font-bold text-[16px] not-italic">
            {t("discard")}
          </div>
        </button>
        <button
          type="submit"
          disabled={isLoading || isUploading.sampleOfWork}
          className="cursor-pointer flex py-[14px] px-[16px] gap-[8px] justify-center items-center rounded-[6px] border-[1px] border-solid border-primary bg-primary h-[46px]"
        >
          <Oval
            height={30}
            width={30}
            color="#FFF"
            visible={isLoading}
            ariaLabel="oval-loading"
            secondaryColor="#FFF"
            strokeWidth={5}
            strokeWidthSecondary={3}
          />
          <div className="text-white font-bold text-[16px] not-italic">
            {t("saveChangesCta")}
          </div>
        </button>
      </div>
    </form>
  );
}

EditRelevantProfile.propTypes = {
  userDetails: PropTypes.object,
  onEditClick: PropTypes.func,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default EditRelevantProfile;
