import React from "react";
import PropTypes from "prop-types";
import { FallingLines } from "react-loader-spinner";
import { Navigate } from "react-router-dom";
import useCheckUser from "hooks/useCheckUser";

function GuardRoute({ children }) {
  const { isAuth, isChecked } = useCheckUser();

  if (!isChecked) {
    return (
      <div className="flex items-center justify-center h-screen w-full">
        <FallingLines
          color="#023A66"
          width="100%"
          visible={true}
          ariaLabel="falling-lines-loading"
        />
      </div>
    );
  }

  return isAuth ? children : <Navigate to="/login" />;
}

GuardRoute.propTypes = {
  children: PropTypes.any,
};
export default GuardRoute;
