import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as LockIcon } from "images/profile/lock.svg";
import { useNavigate } from "react-router-dom";

function Greeting({ title = "", subTitle = "", email = "" }) {
  const navigate = useNavigate();
  return (
    <div className="flex flex-row md-max:flex-col p-[24px] justify-between md-max:justify-start items-center md-max:items-start self-stretch rounded-[8px] bg-light-primary">
      <div className="flex flex-col gap-[4px]">
        <div className="text-gray-900 font-bold text-[30px] not-italic">{`${title} 👋`}</div>
        {email && (
          <div className="text-gray-500 font-medium text-[16px] not-italic mt-[-10px]">
            {email}
          </div>
        )}
        {subTitle && (
          <div className="text-gray-900 font-medium text-[16px] not-italic">
            {subTitle}
          </div>
        )}
      </div>

      <button
        className="flex h-[48px] items-center justify-center gap-[8px] py-[14px] px-[16px] rounded-[6px] bg-white border-[1px] border-solid border-default-color"
        type="button"
        onClick={() => navigate("/update-password")}
      >
        <LockIcon />
        <div className="text-base-black font-bold text-[16px] not-italic">
          Change password
        </div>
      </button>
    </div>
  );
}

Greeting.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  email: PropTypes.string,
};

export default Greeting;
