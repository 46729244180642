import { useState, useEffect } from "react";
import profileApi from "api/profile";
import useNotify from "hooks/useNotify";
import i18n from "../../i18n";

function useUpdatePassword(userId, reset = () => {}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [isEyeOpen, setIsEyeOpen] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const { notifyError } = useNotify();

  const onSubmit = async ({ currentPassword, newPassword }) => {
    try {
      setIsLoading(true);
      await profileApi.updateUserPasswordApi({
        currentPassword,
        newPassword,
        userId,
      });
      setIsPasswordChanged(true);
      reset();
    } catch (err) {
      console.error(err);
      notifyError(
        err?.data?.message ?? err?.message ?? "Something went wrong!"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const resetOnUnmount = () => {
    setIsPasswordChanged(false);
    setIsEyeOpen({
      oldPassword: false,
      newPassword: false,
      confirmPassword: false,
    });
  };

  useEffect(() => {
    return () => {
      resetOnUnmount();
    };
  }, []);

  return { onSubmit, isLoading, isPasswordChanged, isEyeOpen, setIsEyeOpen };
}

export default useUpdatePassword;
