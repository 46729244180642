import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { vmFileType } from "utils/constantValues";
import useProfileHelper from "pages/profile/useProfileHelper";

function VmFileDocuments({ userDetails = {} }) {
  const { downloadFileInANewTab } = useProfileHelper();

  const vmFileDocuments = userDetails?.userFiles?.filter(
    (file) => file.type === vmFileType
  );

  const [t] = useTranslation();

  if (vmFileDocuments.length <= 0) return <></>;
  return (
    <div className="flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]">
      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        <div className="text-primary font-semibold text-[30px] not-italic">
          {t("fileDocument")}
        </div>
      </div>

      <div className="flex flex-col">
        {vmFileDocuments && vmFileDocuments.length > 0
          ? vmFileDocuments.map((file, index) => {
              return (
                <Fragment key={file.id}>
                  <div
                    className="text-gray-900 font-semibold text-[20px] not-italic py-[20px] cursor-pointer"
                    onClick={() => downloadFileInANewTab(file.id)}
                  >
                    {file.name}
                  </div>
                  {index < vmFileDocuments.length - 1 && (
                    <div className="h-[1px] w-full  grid bg-default-color" />
                  )}
                </Fragment>
              );
            })
          : "-"}
      </div>
    </div>
  );
}

VmFileDocuments.propTypes = {
  userDetails: PropTypes.object,
};

export default VmFileDocuments;
