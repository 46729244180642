import React from "react";
import BGShutterstock from "images/auth/bg-shutterstock.png";
import { ReactComponent as TVendorIcon } from "images/auth/t-vendors.svg";
import { useNavigate } from "react-router-dom";
import RoundPercentageProgress from "components/RoundPercentageProgress";

function Header({ percentage = 0 }) {
  const navigate = useNavigate();

  return (
    <div
      className="w-full h-[100px] bg-no-repeat  bg-cover bg-center flex items-center px-[20%] xl-max:px-[10%] lg-max:px-[2rem] md-max:px-[1rem] fixed z-[999999999]"
      style={{
        backgroundImage: `url(${BGShutterstock})`,
        zIndex: 100,
      }}
    >
      <div className="max-w-[843px]  w-full flex items-center justify-between">
        <TVendorIcon
          onClick={() => navigate("/login")}
          className="cursor-pointer"
        />
        <RoundPercentageProgress percentage={percentage} />
      </div>
    </div>
  );
}

export default Header;
