import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as EditIcon } from "images/profile/edit-icon.svg";
import { useTranslation } from "react-i18next";
import { resumeType } from "utils/constantValues";
import useProfileHelper from "pages/profile/useProfileHelper";

function ProfileResume({
  userDetails = {},
  onEditClick = () => {},
  isShowEditIcon = false,
}) {
  const { downloadFileInANewTab } = useProfileHelper(false);

  const resumeFile = userDetails?.userFiles?.find(
    (file) => file.type === resumeType
  );

  const [t] = useTranslation();
  return (
    <div className="flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]">
      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        <div className="text-primary font-semibold text-[30px] not-italic">
          {t("cvTitle")}
        </div>

        {isShowEditIcon && (
          <EditIcon className="cursor-pointer" onClick={onEditClick} />
        )}
      </div>

      <div
        className="text-gray-900 font-semibold text-[20px] not-italic cursor-pointer"
        onClick={() => downloadFileInANewTab(resumeFile?.id)}
      >
        {resumeFile?.name ?? "-"}
      </div>
    </div>
  );
}

ProfileResume.propTypes = {
  userDetails: PropTypes.object,
  onEditClick: PropTypes.func,
  isShowEditIcon: PropTypes.bool,
};

export default ProfileResume;
