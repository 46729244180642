import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CardCheckbox from "components/cardCheckbox";
import { ReactComponent as LockIcon } from "images/profile/lock.svg";
import { ReactComponent as EditIcon } from "images/profile/edit-icon.svg";
import { useTranslation } from "react-i18next";

function ProfileExperience({
  userDetails = {},
  userDomains = [],
  userSubDomains = [],
  isVendor,
  onEditClick = () => {},
  isShowEditIcon = false,
}) {
  const [t] = useTranslation();
  return (
    <div className="flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]">
      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        <div className="flex flex-wrap items-center gap-[10px] w-full">
          <div className="text-primary font-semibold text-[30px] not-italic">
            {t("experienceTitle")}
          </div>

          {isVendor && (
            <div className="flex p-[10px] items-center justify-center rounded-[6px] bg-gray-50">
              <div className="text-gray-800 text-center font-semibold text-[14px]">{`${
                Number.isNaN(Number(`${userDetails.yearOfExperience}`))
                  ? "-"
                  : userDetails.yearOfExperience
              } ${t("years")}`}</div>
            </div>
          )}

          {isVendor && (
            <div className="flex p-[10px] items-center justify-center rounded-[6px] bg-gray-50">
              <div className="text-gray-800 text-center font-semibold text-[14px]">
                {t("isWillingToTravel", {
                  isWillingToTravel: userDetails?.isWillingToTravel
                    ? ""
                    : "not",
                })}
              </div>
            </div>
          )}
        </div>

        {(isVendor || (!isVendor && !userDetails.flags.lockDomain)) &&
          isShowEditIcon && (
            <EditIcon className="cursor-pointer" onClick={onEditClick} />
          )}

        {!isVendor && userDetails.flags.lockDomain && <LockIcon />}
      </div>

      <div className="flex gap-[10px] w-full">
        <div className="grid w-full gap-[20px]">
          <div className="text-gray-900 font-semibold text-[24px] not-italic">
            {t("Domains")}
          </div>
          <div className="flex flex-wrap gap-[10px]">
            {userDomains?.length > 0 ? (
              userDomains.map((elem) => {
                return (
                  <Fragment key={`${elem.id}`}>
                    <CardCheckbox
                      isActive={true}
                      title={elem.title}
                      hideCheckIcon
                      containerClassName="flex justify-center items-center p-[12px] rounded-[4px] bg-light-primary h-[36px]"
                      isActiveClassName="no-className"
                      titleClassName="text-primary font-semibold text-[16px] not-italic"
                    />
                  </Fragment>
                );
              })
            ) : (
              <div className="flex text-center items-center justify-center">
                <div className="text-gray-900 font-semibold text-[24px] not-italic">
                  {"-"}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="grid w-full gap-[20px]">
          <div className="text-gray-900 font-semibold text-[24px] not-italic">
            {t("subDomains")}
          </div>
          <div className="flex flex-wrap gap-[10px]">
            {userSubDomains?.length > 0 ? (
              userSubDomains.map((elem) => {
                return (
                  <Fragment key={`${elem.id}`}>
                    <CardCheckbox
                      isActive={true}
                      title={elem.title}
                      hideCheckIcon
                      containerClassName="flex justify-center items-center p-[12px] rounded-[4px] bg-light-primary h-[36px]"
                      isActiveClassName="no-className"
                      titleClassName="text-primary font-semibold text-[16px] not-italic"
                    />
                  </Fragment>
                );
              })
            ) : (
              <div className="flex text-center items-center justify-center">
                <div className="text-gray-900 font-semibold text-[24px] not-italic">
                  {"-"}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

ProfileExperience.propTypes = {
  userDetails: PropTypes.object,
  userDomains: PropTypes.array,
  userSubDomains: PropTypes.array,
  isVendor: PropTypes.bool,
  isShowEditIcon: PropTypes.bool,
};

export default ProfileExperience;
