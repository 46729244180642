import React from "react";
import PropTypes from "prop-types";
import { Oval } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import UploadFile from "components/uploadFile";
import { ReactComponent as AttachIcon } from "images/auth/attach.svg";
import { useTranslation } from "react-i18next";
import useUploadFiles from "pages/signup/hooks/useUploadFiles";
import { rateCardType } from "utils/constantValues";
import Chips from "components/chips";

function EditRateCardFile({
  userDetails = {},
  onDiscard = () => {},
  onSave = () => {},
  isLoading = false,
}) {
  const [t] = useTranslation();

  const defaultRateCardFile = userDetails?.userFiles
    ?.filter((file) => file.type === rateCardType)
    .map((file) => ({ ...file, fileId: file.id }));

  const isRequired = defaultRateCardFile?.length > 0;

  const defaultsValues = {
    newCreated: [],
    deleted: [],
  };

  const {
    isUploading,
    rateCardRef,
    uploadRateCard,
    rateCardFiles,
    removeRateCardFile,
  } = useUploadFiles({ defaultRateCard: defaultRateCardFile });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultsValues,
  });

  return (
    <form
      className="flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]"
      onSubmit={handleSubmit(onSave)}
    >
      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        <div className="text-primary font-semibold text-[30px] not-italic">
          {t("rateCard")}
        </div>
      </div>

      <div className="grid  gap-[12px]">
        <UploadFile
          name={"rateCard"}
          register={register}
          isMultiFile={true}
          registerArgs={{
            required: isRequired
              ? rateCardFiles.length > 0
                ? false
                : t("errorField", { field: t("rateCard") })
              : false,
          }}
          isRequired={isRequired}
          errorMessage={errors.rateCard ? errors.rateCard.message : ""}
          placeHolderLabel={t("attachYourFiles")}
          placeHolderContainerClassName="flex p-[14px] justify-between items-center gap-[8px] self-stretch rounded-[6px] h-[54px] w-full border-[1px] border-dashed border-dark-gray cursor-pointer"
          icon={<AttachIcon />}
          isUploading={isUploading.rateCard}
          files={rateCardFiles}
          getFile={(e, isDrag) => {
            const callback = (type, files) => {
              setValue(type, files);
              setValue("newCreated", files);
            };
            uploadRateCard(e, isDrag, callback);
          }}
          labelClassName="text-primary font-semibold text-[16px]"
          ref={rateCardRef}
        />

        <Chips
          chips={rateCardFiles.map((file) => file.name)}
          onClick={(chipLabel, index) => {
            const prevDeletedFiles = watch("deleted") || [];

            const deletedFile = rateCardFiles.filter(
              (file) => file.name === chipLabel
            );
            setValue("deleted", [...prevDeletedFiles, ...deletedFile]);

            removeRateCardFile(chipLabel, setValue);
          }}
        />
      </div>

      <div className="flex items-center justify-end gap-[10px]">
        <button
          type="button"
          onClick={onDiscard}
          disabled={isLoading || isUploading.rateCard}
          className="cursor-pointer flex py-[14px] px-[16px] gap-[8px] justify-center items-center rounded-[6px] border-[1px] border-solid border-default-color bg-white h-[46px]"
        >
          <div className="text-base-black font-bold text-[16px] not-italic">
            {t("discard")}
          </div>
        </button>
        <button
          type="submit"
          disabled={isLoading || isUploading.rateCard}
          className="cursor-pointer flex py-[14px] px-[16px] gap-[8px] justify-center items-center rounded-[6px] border-[1px] border-solid border-primary bg-primary h-[46px]"
        >
          <Oval
            height={30}
            width={30}
            color="#FFF"
            visible={isLoading}
            ariaLabel="oval-loading"
            secondaryColor="#FFF"
            strokeWidth={5}
            strokeWidthSecondary={3}
          />
          <div className="text-white font-bold text-[16px] not-italic">
            {t("saveChangesCta")}
          </div>
        </button>
      </div>
    </form>
  );
}

EditRateCardFile.propTypes = {
  userDetails: PropTypes.object,
  onEditClick: PropTypes.func,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default EditRateCardFile;
