import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import LabelForm from "pages/signup/form/labelForm";
import { useTranslation } from "react-i18next";
import Select from "components/select";
import Input from "components/input";
import RadioButtons from "components/radioButtons";

function Experience({
  data = {},
  watch = () => {},
  setValue = () => {},
  register = () => {},
}) {
  const [t] = useTranslation();
  const isWillingToTravel = watch("isWillingToTravel");
  const [radios, setRadios] = useState([
    {
      title: t("Yes"),
      isActive: true,
    },
    { title: t("No"), isActive: false },
  ]);

  const { domains = [], subDomains = [] } = data;

  const subDomainsOptions = subDomains.map((elem) => ({
    ...elem,
    label: elem.title,
    value: elem.id,
  }));

  const domainsOptions = domains.map((elem) => ({
    ...elem,
    label: elem.title,
    value: elem.id,
  }));

  return (
    <Fragment>
      <LabelForm
        label={t("experienceTitle")}
        labelClassName="text-primary font-bold text-[24px] md-max:text-[18px] not-italic pt-[16px] bg-white z-[3]"
      />

      <div className="grid grid-cols-2 md-max:grid-cols-1 gap-[16px] max-w-[843px] sm-max:max-w-full pt-[10px] px-[0px] pb-[30px]  z-[3] bg-white border-b-[1px] border-solid border-default-color">
        <Select
          label={t("domains")}
          placeholder={t("placeholderSelect", {
            field: t("domains").toLowerCase(),
          })}
          name={"domains"}
          closeMenuOnSelect={false}
          isMulti={true}
          value={
            watch("domains")
              ? domainsOptions.filter((elem) =>
                  watch("domains").includes(elem.id)
                )
              : null
          }
          isRequired={watch("domains") && watch("domains").length > 5}
          registerArgs={{
            require:
              watch("domains") && watch("domains").length > 5
                ? t("maxLengthDomainsError")
                : false,
            validate: (value) => {
              return value && value.length > 5 ? false : true;
            },
          }}
          errorMessage={
            watch("domains") && watch("domains").length > 5
              ? t("maxLengthDomainsError")
              : ""
          }
          showError={watch("domains") && watch("domains").length > 5}
          onChange={(e) => {
            setValue(
              "domains",
              e.map((e) => e.value)
            );

            setValue("subDomains", []);
          }}
          register={register}
          options={domainsOptions}
        />
        <Select
          label={t("subDomains")}
          placeholder={t("placeholderSelect", {
            field: t("subDomains").toLowerCase(),
          })}
          closeMenuOnSelect={false}
          isMulti={true}
          name={"subDomains"}
          isDisabled={subDomains.length <= 0 || watch("domains")?.length <= 0}
          value={
            watch("subDomains") && watch("subDomains")?.length > 0
              ? subDomainsOptions.filter((elem) =>
                  watch("subDomains").includes(elem.id)
                )
              : null
          }
          onChange={(e) => {
            setValue(
              "subDomains",
              e.map((e) => e.value)
            );
          }}
          register={register}
          options={subDomainsOptions}
        />

        <Input
          label={t("yearsOfExperienceTitle")}
          placeholder={t("placeHolderType", {
            title: t("yearsOfExperienceTitle").toLowerCase(),
          })}
          type="number"
          inputMode="numeric"
          name="yearsOfExperience"
          register={register}
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName="flex h-[43px] w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid border-default-color bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px] "
        />
        <RadioButtons
          label={t("willingToTravel")}
          contents={radios}
          onClick={(_, index) => {
            setValue("isWillingToTravel", !isWillingToTravel);

            const updatedContents = radios.map((elem, i) => {
              return { ...elem, isActive: i === index };
            });

            setRadios(updatedContents);
          }}
        />
      </div>
    </Fragment>
  );
}

Experience.propTypes = {
  label: PropTypes.string,
  register: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  watch: PropTypes.func,
  data: PropTypes.object,
  getValues: PropTypes.func,
};

export default Experience;
