/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useMemo, useCallback, useEffect } from "react";
import { appContextStore, lookupsContext } from "context/createContext";

function useAppContext() {
  const { user, isAuth, setUser, setAuth, resetUser } =
    useContext(appContextStore);
  return { user, isAuth, setUser, setAuth, resetUser };
}

function useLookupsContext(isVendor) {
  const {
    countries,
    cities,
    domains,
    languages,
    nationalities,
    services,
    timezones,
    currencies,
    getCountries,
    getCities,
    getDomains,
    getLanguages,
    getNationalities,
    getServices,
    getTimeZones,
    resetLookups,
    getSubDomains,
    subDomains,
    getCurrencies,
  } = useContext(lookupsContext);

  const dataToBeLoaded = useMemo(
    () =>
      countries.length > 0 &&
      domains.length > 0 &&
      languages.length > 0 &&
      nationalities.length > 0 &&
      services.length > 0,
    [countries, domains, languages, nationalities, services]
  );

  const getCountryLookups = useCallback(() => {
    getCountries();
  }, [countries]);

  const getDomainsLookups = useCallback(() => {
    getDomains();
  }, [domains]);

  const getLanguagesLookups = useCallback(() => {
    getLanguages();
  }, [languages]);

  const getNationalitiesLookups = useCallback(() => {
    getNationalities();
  }, [nationalities]);

  const getServicesLookups = useCallback(() => {
    getServices();
  }, [services]);

  useEffect(() => {
    if (!dataToBeLoaded) {
      getCountryLookups();
      getDomainsLookups();
      getLanguagesLookups();
      getNationalitiesLookups();
      getServicesLookups();
    }
  }, [dataToBeLoaded]);

  return {
    countries,
    cities,
    domains,
    subDomains,
    languages,
    nationalities,
    services: services.filter((service) => {
      if (isVendor) {
        // For vendors, return services where notForVendor is false
        return !service.notForVendor;
      } else {
        // For agencies, return services where notForAgency is false
        return !service.notForAgency;
      }
    }),
    timezones,
    getCountries,
    getCities,
    getDomains,
    getLanguages,
    getNationalities,
    getServices,
    getTimeZones,
    currencies,
    resetLookups,
    dataToBeLoaded,
    getSubDomains,
    getCurrencies,
  };
}

export { useAppContext, useLookupsContext };
