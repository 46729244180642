import axiosInstance from "api/setupAxios";

const authApi = {
  login: async (credential = {}) => {
    const data = await axiosInstance.post("/auth/login", credential);
    return data;
  },

  forgetPassword: async (credential = {}) => {
    const data = await axiosInstance.patch("/auth/forgetPassword", credential);
    return data;
  },
  setPassword: async (credential = {}) => {
    const data = await axiosInstance.patch("/auth/setPassword", credential);
    return data;
  },
  getUser: async () => {
    const { data } = await axiosInstance.get("/auth/me");
    return data;
  },

  vendorRegisteredApi: async (payload = {}) => {
    const data = await axiosInstance.post("/vendor/register", payload);
    return data;
  },

  agencyRegisteredApi: async (payload = {}) => {
    const data = await axiosInstance.post("/agency/register", payload);
    return data;
  },

  logout: async () => {
    const data = await axiosInstance.get("/auth/logout");
    return data;
  },
  loginByToken: async (payload = {}) => {
    const data = await axiosInstance.post("/auth/login/magicLink", payload);
    return data;
  },
};

export default authApi;
