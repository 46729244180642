import i18n from "../../i18n";
import VendorIcon from "images/auth/freelancer-icon.png";
import AgencyIcon from "images/auth/agency-icon.png";

const defaultVendorValues = {
  firstName: "",
  lastName: "",
  resume: "",
  yearsOfExperience: "",
  isWillingToTravel: true,
  nationalities: [],
  vendorAdditionalInfo: {
    profile: [],
  },
  sampleOfWorks: [],
};

const defaultAgencyValues = {
  companyName: "",
  representativeName: "",
  rateCard: [],
  multiCvs: [],
  companyWebsite: "",
  portfolio: [],
};
const steps = [i18n.t("vendorFreelancer"), i18n.t("agencyType")];
const stepsContent = [
  {
    title: "",
    subTitle: "",
    icon: VendorIcon,
  },
  {
    title: "",
    subTitle: "",
    icon: AgencyIcon,
  },
];

export { defaultVendorValues, defaultAgencyValues, steps, stepsContent };
