import React, { Fragment } from "react";
import PropTypes from "prop-types";
import LabelComponent from "pages/profile/common/label";
import CardCheckbox from "components/cardCheckbox";
import { ReactComponent as EditIcon } from "images/profile/edit-icon.svg";
import { numberToAvailableDaysMapper } from "utils/constantValues";
import { useTranslation } from "react-i18next";
import useTime from "hooks/useTime";

function CompanyDetails({
  userDetails = {},
  onEditClick = () => {},
  isShowEditIcon = false,
}) {
  const [t] = useTranslation();
  const { convertToAMPM } = useTime();

  return (
    <div className="flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]">
      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        <div className="text-primary font-semibold text-[30px] not-italic">
          {t("companyDetailsTitle")}
        </div>
        {isShowEditIcon && (
          <EditIcon className="cursor-pointer" onClick={onEditClick} />
        )}
      </div>

      <div className="flex flex-wrap gap-[20px]">
        <LabelComponent
          title={t("companyName")}
          subTitle={`${userDetails?.companyName ?? "-"}`}
        />
        <LabelComponent
          title={t("representativeName")}
          subTitle={`${userDetails?.representativeName ?? "-"}`}
        />
        <LabelComponent
          title={t("phoneNumber")}
          subTitle={userDetails?.phone ?? "-"}
        />

        <LabelComponent
          title={t("country")}
          subTitle={userDetails?.country?.name ?? "-"}
        />
        <LabelComponent
          title={t("website")}
          subTitle={`${userDetails?.companyWebsite ?? "-"}`}
        />
      </div>
      <div className="h-[1px] w-full  grid bg-default-color" />

      <div className="text-gray-900 font-semibold text-[24px] not-italic">
        {t("availabilityTitle")}
      </div>
      <div className="flex justify-between gap-[10px]">
        <div className="flex flex-wrap gap-[10px]">
          {userDetails?.availability?.days?.length > 0 ? (
            userDetails.availability.days.map((dayNum) => {
              return (
                <Fragment key={`${dayNum}`}>
                  <CardCheckbox
                    isActive={true}
                    title={numberToAvailableDaysMapper[dayNum]}
                    hideCheckIcon
                    containerClassName="flex justify-center items-center p-[12px] rounded-[4px] bg-light-primary h-[36px]"
                    isActiveClassName="no-className"
                    titleClassName="text-primary font-semibold text-[16px] not-italic"
                  />
                </Fragment>
              );
            })
          ) : (
            <div className="flex text-center items-center justify-center">
              <div className="text-gray-900 font-semibold text-[24px] not-italic w-[90px]">
                {"-"}
              </div>
            </div>
          )}
        </div>
        {userDetails?.availability?.time?.from && (
          <div className="text-gray-900 font-regular text-[18px] not-italic">
            {t("from")}{" "}
            <span className="font-semibold">
              {convertToAMPM(userDetails?.availability?.time?.from)}
            </span>{" "}
            {t("to")}
            <span className="font-semibold">
              {" "}
              {convertToAMPM(userDetails?.availability?.time?.to)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

CompanyDetails.propTypes = {
  userDetails: PropTypes.object,
  onEditClick: PropTypes.func,
  isShowEditIcon: PropTypes.bool,
};

export default CompanyDetails;
