import { useState, useEffect } from "react";
import { useLookupsContext } from "hooks/useContext";
import { availableDaysNumberMapper } from "utils/constantValues";
import useNotify from "hooks/useNotify";
import profileApi from "api/profile";
import { scrollIntoView } from "utils/helper";

function usePersonalProfile({
  userDetails = {},
  countryId,
  availability,
  profileCompletionRef = {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditPersonalProfile, setIsEditPersonalProfile] = useState(false);
  const [availableDays, setAvailableDays] = useState(
    availability?.days?.length
      ? availability?.days.map((dayNum) => {
          return availableDaysNumberMapper[dayNum];
        })
      : []
  );

  profileCompletionRef.personalProfile = {
    openEdit: setIsEditPersonalProfile,
    firstName: userDetails?.firstName ?? "",
    lastName: userDetails?.lastName ?? "",
    userNationalities: userDetails?.userNationalities ?? [],
    country: userDetails?.country?.name ?? "",
    city: userDetails?.city?.name ?? "",
    timezone: userDetails?.timezone?.name ?? "",
    phone: userDetails?.phone ?? "",
    email: userDetails?.email ?? "",
    days: userDetails?.availability?.days ?? [],
    timeFrom: userDetails?.availability?.time?.from ?? "",
    timeTo: userDetails?.availability?.time?.to ?? "",
  };

  const {
    countries,
    nationalities,
    cities,
    timezones,
    getCities,
    getTimeZones,
  } = useLookupsContext(true);

  const { notifyError, notifySuccess } = useNotify();

  const nationalitiesOptions = nationalities.map((elem) => ({
    ...elem,
    label: elem.name,
    value: elem.id,
  }));

  const countriesOptions = countries.map((elem) => ({
    ...elem,
    label: elem.name,
    value: elem.id,
  }));

  const citiesOptions = cities.map((elem) => ({
    ...elem,
    label: elem.name,
    value: elem.id,
  }));

  const timezonesOptions = timezones.map((elem) => ({
    ...elem,
    label: elem.name,
    value: elem.id,
  }));

  const onEditSave = async (values, setUserData = () => {}) => {
    try {
      setIsLoading(true);

      const {
        availability,
        city: cityId,
        country: countryId,
        email,
        firstName,
        lastName,
        nationalities: nationalitiesIds,
        phoneNumber: phone,
        timezone: timezoneId,
      } = values;

      const payload = {
        firstName,
        lastName,
        nationalities: nationalitiesIds,
        email,
        phone,
        countryId,
        cityId,
        timezoneId,
        availability,
      };

      await profileApi.updatePersonalDetailsApi(userDetails?.vendorId, payload);
      setUserData((prevState) => ({
        ...prevState,
        ...values,
        userNationalities: nationalities.filter((elem) =>
          values.nationalities.includes(elem.id)
        ),
        country: countries.find((elem) => elem.id === values.country),
        city: cities.find((elem) => elem.id === values.city),
        timezone: timezones.find((elem) => elem.id === values.timezone),
        phone: values.phoneNumber,
      }));
      setIsEditPersonalProfile(false);
      notifySuccess("Has been updated successfully");
      scrollIntoView("personalDetailsScrollId");
    } catch (err) {
      console.error(err);
      notifyError(err?.data?.error ?? err?.message ?? "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!!countryId) {
      getCities(countryId);
      getTimeZones(countryId);
    }
  }, [countryId]);

  const resetOnUnmount = () => {
    setAvailableDays(
      availability?.days?.length
        ? availability?.days.map((dayNum) => {
            return availableDaysNumberMapper[dayNum];
          })
        : []
    );
  };
  useEffect(() => {
    return () => {
      resetOnUnmount();
    };
  }, []);

  return {
    isEditPersonalProfile,
    setIsEditPersonalProfile,
    onEditSave,
    isLoading,
    countries,
    nationalities,
    cities,
    timezones,
    nationalitiesOptions,
    countriesOptions,
    citiesOptions,
    timezonesOptions,
    availableDays,
    setAvailableDays,
  };
}

export default usePersonalProfile;
