export const SET_USER = "SET_USER";
export const IS_AUTH_USER = "IS_AUTH_USER";
export const REST_USER = "REST_USER";

/*@@ LOOKUPS @@*/
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_CITIES = "GET_CITIES";
export const GET_DOMAINS = "GET_DOMAINS";
export const GET_SUB_DOMAINS = "GET_SUB_DOMAINS";
export const GET_LANGUAGES_LOOKUPS = "GET_LANGUAGES_LOOKUPS";
export const GET_NATIONALITIES = "GET_NATIONALITIES";
export const GET_SERVICES = "GET_SERVICES";
export const GET_TIMEZONES = "GET_TIMEZONES";
export const GET_CURRENCIES = "GET_CURRENCIES";

export const RESET_LOOKUPS = "RESET_LOOKUPS";
