import React from "react";
import { useTranslation } from "react-i18next";
import Input from "components/input";
import Select from "components/select";
import PropTypes from "prop-types";
import { useLookupsContext } from "hooks/useContext";
import { validateEmail } from "utils/helper";

function EmergencyContactForm({
  register = () => {},
  setValue = () => {},
  errors = {},
  watch = () => {},
  getValues = () => {},
  title = "",
  containerClassName = "flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]",
}) {
  const [t] = useTranslation();

  const { countries } = useLookupsContext(false);

  const countriesOptions = countries.map((elem) => ({
    ...elem,
    label: elem.name,
    value: elem.id,
  }));

  return (
    <div className={containerClassName}>
      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        <div className="text-primary font-semibold text-[30px] not-italic">
          {title || t("emergencyContactTitle")}
        </div>
      </div>

      <div className="flex flex-wrap gap-[20px]">
        <Input
          label={t("name")}
          isRequired={true}
          placeholder={t("placeHolderType", {
            title: t("name").toLowerCase(),
          })}
          name="name"
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("name") }),
          }}
          errorMessage={errors.name ? errors.name.message : ""}
          containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.name ? "border-red" : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />

        <Input
          label={t("relationship")}
          isRequired={true}
          placeholder={t("placeHolderType", {
            title: t("relationship").toLowerCase(),
          })}
          name="relationship"
          register={register}
          registerArgs={{
            required: t("errorField", {
              field: t("relationship"),
            }),
          }}
          errorMessage={errors.relationship ? errors.relationship.message : ""}
          containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.relationship ? "border-red" : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />

        <Input
          label={t("email")}
          isRequired={true}
          placeholder={t("placeHolderType", {
            title: t("email").toLowerCase(),
          })}
          name="email"
          type={"email"}
          register={register}
          registerArgs={{
            required: t("errorField", {
              field: t("email"),
            }),
            validate: validateEmail,
          }}
          errorMessage={errors.email ? errors.email.message : ""}
          containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.email ? "border-red" : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />

        <Select
          label={t("country")}
          placeholder={t("placeholderSelect", {
            field: t("country").toLowerCase(),
          })}
          name={"emergencyCountry"}
          containerClassName="flex flex-1 flex-col gap-[10px] min-w-[250px] sm-max:w-full"
          value={
            watch("emergencyCountry")
              ? countriesOptions.find(
                  (elem) => elem.name === watch("emergencyCountry")
                )
              : null
          }
          onChange={(e) => {
            setValue("emergencyCountry", e.label);
          }}
          isRequired={true}
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("country") }),
          }}
          errorMessage={
            errors.emergencyCountry ? errors.emergencyCountry.message : ""
          }
          options={countriesOptions}
        />

        <Input
          label={t("phoneNumber")}
          isRequired={true}
          placeholder={t("phonePlaceholder")}
          name="emergencyPhoneNumber"
          type="text"
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("phoneNumber") }),
          }}
          errorMessage={
            errors.emergencyPhoneNumber
              ? errors.emergencyPhoneNumber.message
              : ""
          }
          containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.emergencyPhoneNumber ? "border-red" : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />

        <Input
          label={t("homeTelephone")}
          isRequired={true}
          placeholder={t("phonePlaceholder")}
          name="emergencyHomeTelephone"
          type="text"
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("homeTelephone") }),
          }}
          errorMessage={
            errors.emergencyHomeTelephone
              ? errors.emergencyHomeTelephone.message
              : ""
          }
          containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.emergencyHomeTelephone
              ? "border-red"
              : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />
      </div>
    </div>
  );
}
EmergencyContactForm.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  watch: PropTypes.func,
  getValues: PropTypes.func,
  title: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default EmergencyContactForm;
