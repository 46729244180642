/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useState } from "react";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { FallingLines } from "react-loader-spinner";
import LoginPage from "pages/login";
import SignupPage from "pages/signup";
import ForgetPassword from "pages/forgetPassword";
import SetPassword from "pages/setPassword";
import ProfilePage from "pages/profile";
import UpdatePassword from "pages/updatePassword";
import FooterComponent from "components/footer";
import GuardRoute from "./guardRoute";
import { VM_PORTAL_COOKIE_NAME, publicRoutes } from "utils/constantValues";
import useCheckUser from "hooks/useCheckUser";
import Cookie from "components/cookie";
import { getCookie } from "utils/getCookie";

const Loading = () => {
  return (
    <div className="flex items-center justify-center h-screen w-full">
      <FallingLines
        color="#023A66"
        width="100%"
        visible={true}
        ariaLabel="falling-lines-loading"
      />
    </div>
  );
};

function AppRouters() {
  const [isAcceptedCookie, setIsAcceptCookie] = useState(
    getCookie(VM_PORTAL_COOKIE_NAME)
  );

  const { isChecked, isAuth } = useCheckUser();
  const location = useLocation().pathname;

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isAuth && publicRoutes.includes(location.split("/")[1])) {
      navigate("/");
    }
  }, [isChecked]);

  if (!isChecked) {
    return <Loading />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <div className="flex flex-col min-h-screen">
        <Routes>
          <Route exact path="/set-password/:token" element={<SetPassword />} />
          <Route exact path="/forget-password" element={<ForgetPassword />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/signup/:accountType" element={<SignupPage />} />
          <Route exact path="/login" element={<LoginPage />} />

          <Route
            exact
            path="/"
            element={
              <GuardRoute>
                <ProfilePage />
              </GuardRoute>
            }
          />

          <Route
            exact
            path="/update-password"
            element={
              <GuardRoute>
                <UpdatePassword />
              </GuardRoute>
            }
          />

          <Route exact path={"*"} element={<Navigate replace to="/" />} />
        </Routes>

        <FooterComponent />
        {!isAcceptedCookie && <Cookie setIsAcceptCookie={setIsAcceptCookie} />}
      </div>
    </Suspense>
  );
}

export default AppRouters;
