import React from "react";
import PropTypes from "prop-types";

function Label({ title = "", subTitle = "" }) {
  return (
    <div className="grid gap-[10px] min-w-[250px] sm-max:w-full">
      <div className="text-gray-500 font-medium text-[18px] not-italic">
        {title}
      </div>

      <div className="text-gray-900 font-semibold text-[20px] not-italic">
        {subTitle || "-"}
      </div>
    </div>
  );
}

Label.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

export default Label;
