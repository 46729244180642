import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppProviderState from "context/appContext";
import AppRouters from "routes";
import { AxiosInterceptor } from "api/setupAxios";

function App() {
  return (
    <BrowserRouter>
      <AppProviderState>
        <AxiosInterceptor>
          <AppRouters />
        </AxiosInterceptor>
      </AppProviderState>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
