import { Fragment } from "react";
import PropTypes from "prop-types";
import LabelForm from "pages/signup/form/labelForm";
import { useTranslation } from "react-i18next";
import UploadFile from "components/uploadFile";
import useUploadFiles from "pages/signup/hooks/useUploadFiles";
import { ReactComponent as AttachIcon } from "images/auth/attach.svg";
import { ReactComponent as AddCircleIcon } from "images/auth/add-circle.svg";
import { ReactComponent as TrashIcon } from "images/auth/trash.svg";
import Chips from "components/chips";

import {
  availableDaysOptions,
  availableDaysOptionsMapper,
  timesOptions,
} from "utils/constantValues";
import Select from "components/select";
import Input from "components/input";
import CardCheckbox from "components/cardCheckbox";
import usePersonalProfile from "pages/signup/hooks/usePersonalProfile";
import TimePicker from "components/timePicker";
import useTime from "hooks/useTime";

function PersonalProfile({
  data = {},
  watch = () => {},
  setValue = () => {},
  register = () => {},
  getValues = () => {},
  isVendor = false,
  errors = {},
}) {
  const [t] = useTranslation();
  const {
    sampleOfWorkRef,
    uploadSampleOfFiles,
    isUploading,
    SOWFiles,
    removeSampleOfFile,
  } = useUploadFiles({});
  const {
    availableDays,
    setAvailableDays,
    addAnotherProfile,
    removeProfile,
    profiles,
  } = usePersonalProfile();

  const {
    onMeridiemClick,
    meridiem,
    timeFrom,
    setTimeFrom,
    timeTo,
    setTimeTo,
    convertTimeTo24h,
    isTimeAfter,
  } = useTime();

  const {
    countries = [],
    cities = [],
    nationalities = [],
    timezones = [],
    domains = [],
    subDomains = [],
  } = data;

  const countriesOptions = countries.map((elem) => ({
    ...elem,
    label: elem.name,
    value: elem.id,
  }));

  const citiesOptions = cities.map((elem) => ({
    ...elem,
    label: elem.name,
    value: elem.id,
  }));

  const timezonesOptions = timezones.map((elem) => ({
    ...elem,
    label: elem.name,
    value: elem.id,
  }));

  const nationalitiesOptions = nationalities.map((elem) => ({
    ...elem,
    label: elem.name,
    value: elem.id,
  }));

  const domainsOptions = domains.map((elem) => ({
    ...elem,
    label: elem.title,
    value: elem.id,
  }));

  const subDomainsOptions = subDomains.map((elem) => ({
    ...elem,
    label: elem.title,
    value: elem.id,
  }));

  return (
    <Fragment>
      <LabelForm
        label={t("personalInformationLabel")}
        labelClassName="text-primary font-bold text-[24px] md-max:text-[18px] not-italic pt-[16px] bg-white z-[5]"
      />

      <div className="grid grid-cols-2 md-max:grid-cols-1 gap-[16px] max-w-[843px] sm-max:max-w-full pt-[10px] px-[0px] pb-[30px]  z-[5] bg-white border-b-[1px] border-solid border-default-color">
        <Select
          label={t("country")}
          placeholder={t("placeholderSelect", {
            field: t("country").toLowerCase(),
          })}
          name={"country"}
          value={
            watch("country")
              ? countriesOptions.find((elem) => elem.id === watch("country"))
              : null
          }
          onChange={(e) => {
            setValue("country", e.value);
          }}
          register={register}
          options={countriesOptions}
        />

        <div className="grid grid-cols-2 gap-[16px]">
          <Select
            label={t("city")}
            placeholder={t("placeholderSelect", {
              field: t("city").toLowerCase(),
            })}
            name={"city"}
            isDisabled={cities.length <= 0}
            value={
              watch("city")
                ? citiesOptions.find((elem) => elem.id === watch("city"))
                : null
            }
            onChange={(e) => {
              setValue("city", e.value);
            }}
            register={register}
            options={citiesOptions}
          />

          <Select
            label={t("timeZone")}
            placeholder={t("placeholderSelect", {
              field: t("timeZone").toLowerCase(),
            })}
            name={"timezone"}
            isDisabled={timezones.length <= 0}
            value={
              watch("timezone")
                ? timezonesOptions.find((elem) => elem.id === watch("timezone"))
                : null
            }
            onChange={(e) => {
              setValue("timezone", e.value);
            }}
            register={register}
            options={timezonesOptions}
          />
        </div>

        <Input
          label={t("phoneNumber")}
          placeholder={t("placeholder", {
            field: t("phoneNumber").toLowerCase(),
          })}
          type="text"
          name="phoneNumber"
          register={register}
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName="flex h-[43px] w-full sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid border-default-color bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px] no-arrows-on-input"
        />

        {isVendor ? (
          <Select
            label={t("nationality")}
            placeholder={t("placeholderSelect", {
              field: t("nationality").toLowerCase(),
            })}
            name={"nationalities"}
            isMulti={true}
            closeMenuOnSelect={false}
            value={
              watch("nationalities")
                ? nationalitiesOptions.filter((elem) =>
                    watch("nationalities").includes(elem.id)
                  )
                : null
            }
            onChange={(e) => {
              setValue(
                "nationalities",
                e.map((e) => e.value)
              );
            }}
            register={register}
            options={nationalitiesOptions}
          />
        ) : (
          <>
            <Select
              label={t("domains")}
              placeholder={t("placeholderSelect", {
                field: t("domains").toLowerCase(),
              })}
              name={"domains"}
              closeMenuOnSelect={false}
              isMulti={true}
              value={
                watch("domains")
                  ? domainsOptions.filter((elem) =>
                      watch("domains").includes(elem.id)
                    )
                  : null
              }
              onChange={(e) => {
                setValue(
                  "domains",
                  e.map((e) => e.value)
                );
                setValue("subDomains", []);
              }}
              register={register}
              options={domainsOptions}
            />

            <Select
              label={t("subDomains")}
              placeholder={t("placeholderSelect", {
                field: t("subDomains").toLowerCase(),
              })}
              closeMenuOnSelect={false}
              isMulti={true}
              name={"subDomains"}
              isDisabled={
                subDomains.length <= 0 || watch("domains")?.length <= 0
              }
              value={
                watch("subDomains") && watch("subDomains")?.length > 0
                  ? subDomainsOptions.filter((elem) =>
                      watch("subDomains").includes(elem.id)
                    )
                  : null
              }
              onChange={(e) => {
                setValue(
                  "subDomains",
                  e.map((e) => e.value)
                );
              }}
              register={register}
              options={subDomainsOptions}
            />
          </>
        )}
        <div className={"flex flex-col gap-[10px]"}>
          <div className="text-primary font-semibold text-[16px]">
            {t("availableDaysTitle")}
          </div>

          <div className="flex gap-[5px] items-center">
            {availableDaysOptions.map((title) => {
              return (
                <Fragment key={title}>
                  <CardCheckbox
                    title={title}
                    isActive={availableDays.includes(title)}
                    onClick={() => {
                      const availability = watch("availability");
                      if (availableDays.includes(title)) {
                        //remove
                        const newAvailableDays = availableDays.filter(
                          (elem) => elem !== title
                        );
                        setValue("availability", {
                          ...availability,
                          days: newAvailableDays.map(
                            (day) => availableDaysOptionsMapper[day]
                          ),
                        });
                        setAvailableDays(newAvailableDays);
                      } else {
                        //add
                        const newAvailableDays = [...availableDays, title];
                        setValue("availability", {
                          ...availability,
                          days: newAvailableDays.map(
                            (day) => availableDaysOptionsMapper[day]
                          ),
                        });
                        setAvailableDays(newAvailableDays);
                      }
                    }}
                    hideCheckIcon
                    containerClassName="flex flex-1 items-center justify-center self-stretch py-[12px] px-[10px] w-[56px] cursor-pointer"
                    titleClassName="text-gray-700 font-semibold text-[14px] not-italic"
                  />
                </Fragment>
              );
            })}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-[16px]">
          <TimePicker
            label={t("timeFromTitle")}
            placeholder={`HH:MM - PM/AM`}
            name={"timeFrom"}
            value={
              timeFrom
                ? {
                    ...timeFrom,
                    label: `${timeFrom.label} ${timeFrom.value.split(" ")[1]}`,
                  }
                : null
            }
            onChange={(e) => {
              const availability = watch("availability");
              setValue("availability", {
                ...availability,
                time: {
                  ...availability.time,
                  from: convertTimeTo24h(e.value),
                  to: "",
                },
              });
              setValue("timeTo", "");
              setTimeFrom(e);
              setTimeTo(null);
            }}
            options={timesOptions.map((el) => ({
              ...el,
              value: `${el.value} ${meridiem.from}`,
            }))}
            onMeridiemClick={(chosenMeridiem) =>
              onMeridiemClick(chosenMeridiem, "from")
            }
            meridiem={meridiem.from}
          />

          <TimePicker
            label={t("timeToTitle")}
            placeholder={`HH:MM - PM/AM`}
            name={"timeTo"}
            isRequired={!timeFrom ? false : true}
            register={register}
            registerArgs={{
              required: !timeFrom
                ? false
                : t("errorField", { field: t("timeToTitle") }),
            }}
            errorMessage={
              !timeFrom ? "" : errors.timeTo ? errors.timeTo.message : ""
            }
            isDisabled={!timeFrom}
            value={
              timeTo
                ? {
                    ...timeTo,
                    label: `${timeTo.label} ${timeTo.value.split(" ")[1]}`,
                  }
                : null
            }
            onChange={(e) => {
              const availability = watch("availability");
              setValue("availability", {
                ...availability,
                time: { ...availability.time, to: convertTimeTo24h(e.value) },
              });
              setValue("timeTo", e.value);
              setTimeTo(e);
            }}
            options={timesOptions
              .filter((elem) => {
                if (!timeFrom) {
                  return elem;
                }
                return isTimeAfter(
                  timeFrom.value,
                  `${elem.value} ${meridiem.to}`
                );
              })
              .map((el) => ({
                ...el,
                value: `${el.value} ${meridiem.to}`,
              }))}
            onMeridiemClick={(chosenMeridiem) =>
              onMeridiemClick(chosenMeridiem, "to")
            }
            meridiem={meridiem.to}
          />
        </div>

        {isVendor && (
          <>
            {" "}
            <div className="flex flex-col gap-[10px]">
              {profiles.map((_, index) => {
                return (
                  <Fragment key={`${index}`}>
                    <div className="flex gap-[10px] items-center w-full">
                      <Input
                        name={`${index}`}
                        label={index === 0 ? t("ProfileLinkTitle") : ""}
                        value={
                          watch("vendorAdditionalInfo")?.profile[index] || ""
                        }
                        onChange={(e) => {
                          const { value } = e.target;
                          let vendorAdditionalInfo = getValues(
                            "vendorAdditionalInfo"
                          );

                          if (!Array.isArray(vendorAdditionalInfo?.profile)) {
                            vendorAdditionalInfo = { profile: [] };
                          }

                          const updatedProfile = [
                            ...vendorAdditionalInfo.profile,
                          ];

                          updatedProfile[index] = value;
                          setValue("vendorAdditionalInfo", {
                            ...vendorAdditionalInfo,
                            profile: updatedProfile,
                          });
                        }}
                        placeholder={t("placeHolderType", {
                          title: t("ProfileLinkTitle").toLowerCase(),
                        })}
                        containerClassName="flex flex-col gap-[10px] w-full"
                        labelClassName="text-primary font-semibold text-[16px]"
                        inputClassName="flex h-[43px] w-full sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid border-default-color bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px] "
                      />
                      {index >= 1 && (
                        <TrashIcon
                          className="cursor-pointer"
                          onClick={() => {
                            const vendorAdditionalInfo = getValues(
                              "vendorAdditionalInfo"
                            );

                            const removedProfile =
                              vendorAdditionalInfo.profile.filter(
                                (_, i) => i !== index
                              );
                            setValue("vendorAdditionalInfo", {
                              ...vendorAdditionalInfo,
                              profile: removedProfile,
                            });
                            removeProfile(index);
                          }}
                        />
                      )}
                    </div>

                    {index === profiles.length - 1 && (
                      <div
                        className="flex gap-[8px] cursor-pointer"
                        onClick={() => {
                          let vendorAdditionalInfo = getValues(
                            "vendorAdditionalInfo"
                          );

                          if (!Array.isArray(vendorAdditionalInfo?.profile)) {
                            vendorAdditionalInfo = { profile: [] };
                          }

                          const updatedProfile = [
                            ...vendorAdditionalInfo.profile,
                          ];
                          setValue("vendorAdditionalInfo", {
                            ...vendorAdditionalInfo,
                            profile: [...updatedProfile, ""], // add new string
                          });
                          addAnotherProfile(index);
                        }}
                      >
                        <AddCircleIcon />
                        <div className="text-primary font-bold text-[16px] not-italic">
                          {t("addAnotherProfile")}
                        </div>
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </div>
            <div className="grid  gap-[12px]">
              <UploadFile
                name={"sampleOfWorks"}
                register={register}
                label={t("sampleOfWorkTitle")}
                isMultiFile={true}
                placeHolderLabel={t("attachYourFiles")}
                placeHolderContainerClassName="flex p-[14px] justify-between items-center gap-[8px] self-stretch rounded-[6px] h-[43px] w-full border-[1px] border-dashed border-dark-gray cursor-pointer"
                icon={<AttachIcon />}
                isUploading={isUploading.sampleOfWork}
                getFile={(e, isDrag) =>
                  uploadSampleOfFiles(e, isDrag, setValue)
                }
                labelClassName="text-primary font-semibold text-[16px]"
                ref={sampleOfWorkRef}
              />
              <Chips
                chips={SOWFiles.map((file) => file.name)}
                onClick={(chipLabel, index) =>
                  removeSampleOfFile(chipLabel, setValue)
                }
              />
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
}

PersonalProfile.propTypes = {
  label: PropTypes.string,
  register: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  watch: PropTypes.func,
  data: PropTypes.object,
  getValues: PropTypes.func,
  isVendor: PropTypes.bool,
};

export default PersonalProfile;
