import React, { useId } from "react";
import PropTypes from "prop-types";
import { Oval } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import Input from "components/input";
import { useTranslation } from "react-i18next";
import Select from "components/select";
import RadioButtons from "components/radioButtons";
import { ReactComponent as LockIcon } from "images/profile/lock.svg";

import usePersonalExperience from "./usePersonalExperience";

function EditPersonalExperience({
  userDetails = {},
  isVendor,
  onDiscard = () => {},
  onSave = () => {},
  isLoading = false,
  userDomains = [],
  userSubDomains = [],
}) {
  const [t] = useTranslation();

  const defaultsValues = {
    domains: userDomains?.map((e) => e.id) ?? [],
    subDomains: userSubDomains?.map((e) => e.id) ?? [],
    yearOfExperience: userDetails?.yearOfExperience ?? "",
    isWillingToTravel: userDetails?.isWillingToTravel ?? true,
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultsValues,
  });

  const domainIds = watch("domains");
  const isDomainsError = isVendor && domainIds.length > 5;

  const { domainsOptions, radios, setRadios, subDomainsOptions, subDomains } =
    usePersonalExperience({
      userDetails,
      userDomains,
      userSubDomains,
      domainIds,
    });
  return (
    <form
      className="flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]"
      onSubmit={handleSubmit(onSave)}
    >
      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        {/* ************** PERSONAL EXPERIENCE SECTION => START ***************/}
        <div className="text-primary font-semibold text-[30px] not-italic">
          {t("experienceTitle")}
        </div>
      </div>

      <div className="flex flex-wrap gap-[20px]">
        {/* ************** DOMAINS ***************/}
        <div className="flex gap-[5px] relative">
          <Select
            label={t("domains")}
            placeholder={t("placeholderSelect", {
              field: t("domains").toLowerCase(),
            })}
            name={"domains"}
            isRequired={isDomainsError}
            registerArgs={{
              require: isDomainsError ? t("maxLengthDomainsError") : false,
              validate: (value) => {
                return isVendor && value && value.length > 5 ? false : true;
              },
            }}
            errorMessage={isDomainsError ? t("maxLengthDomainsError") : ""}
            showError={isDomainsError}
            containerClassName="flex flex-col gap-[10px] min-w-[250px] sm-max:w-full"
            closeMenuOnSelect={false}
            isMulti={true}
            isDisabled={userDetails.flags.lockDomain}
            value={
              domainIds && domainIds?.length > 0
                ? domainsOptions.filter((elem) => domainIds.includes(elem.id))
                : null
            }
            onChange={(e) => {
              setValue(
                "domains",
                e.map((e) => e.value)
              );
              setValue("subDomains", []);
            }}
            register={register}
            options={domainsOptions}
          />
          {userDetails.flags.lockDomain && (
            <div className="absolute left-[60px] top-[1px]">
              <LockIcon />
            </div>
          )}
        </div>

        <div className="flex gap-[5px] relative">
          <Select
            label={t("subDomains")}
            placeholder={t("placeholderSelect", {
              field: t("subDomains").toLowerCase(),
            })}
            name={"subDomains"}
            containerClassName="flex flex-col gap-[10px] min-w-[250px] sm-max:w-full"
            closeMenuOnSelect={false}
            isMulti={true}
            isDisabled={
              subDomains.length <= 0 ||
              domainIds?.length <= 0 ||
              userDetails.flags.lockDomain
            }
            value={
              watch("subDomains") && watch("subDomains")?.length > 0
                ? subDomainsOptions.filter((elem) =>
                    watch("subDomains").includes(elem.id)
                  )
                : null
            }
            onChange={(e) => {
              setValue(
                "subDomains",
                e.map((e) => e.value)
              );
            }}
            register={register}
            options={subDomainsOptions}
          />
          {userDetails.flags.lockDomain && (
            <div className="absolute left-[90px] top-[1px]">
              <LockIcon />
            </div>
          )}
        </div>
        {/* ************** YEARS OF EXPERIENCE ***************/}

        {isVendor && (
          <Input
            label={t("yearsOfExperienceTitle")}
            placeholder={t("placeHolderType", {
              title: t("yearsOfExperienceTitle").toLowerCase(),
            })}
            type="number"
            inputMode="numeric"
            name="yearOfExperience"
            register={register}
            labelClassName="text-primary font-semibold text-[16px]"
            inputClassName="flex h-[43px]  min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid border-default-color bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px] "
          />
        )}

        {/* ************** WILLING TO TRAVEL ***************/}
        {isVendor && (
          <RadioButtons
            label={t("willingToTravel")}
            contents={radios}
            containerClassName="flex flex-col flex-1 gap-[16px]"
            radioClassNameContainer="grid grid-cols-2 justify-between w-[250px] sm-max:w-full"
            onClick={(_, index) => {
              setValue("isWillingToTravel", !watch("isWillingToTravel"));

              const updatedContents = radios.map((elem, i) => {
                return { ...elem, isActive: i === index };
              });

              setRadios(updatedContents);
            }}
          />
        )}
      </div>

      <div className="flex items-center justify-end gap-[10px]">
        <button
          type="button"
          onClick={onDiscard}
          disabled={isLoading}
          className="cursor-pointer flex py-[14px] px-[16px] gap-[8px] justify-center items-center rounded-[6px] border-[1px] border-solid border-default-color bg-white h-[46px]"
        >
          <div className="text-base-black font-bold text-[16px] not-italic">
            {t("discard")}
          </div>
        </button>
        <button
          type="submit"
          disabled={isLoading}
          className="cursor-pointer flex py-[14px] px-[16px] gap-[8px] justify-center items-center rounded-[6px] border-[1px] border-solid border-primary bg-primary h-[46px]"
        >
          <Oval
            height={30}
            width={30}
            color="#FFF"
            visible={isLoading}
            ariaLabel="oval-loading"
            secondaryColor="#FFF"
            strokeWidth={5}
            strokeWidthSecondary={3}
          />
          <div className="text-white font-bold text-[16px] not-italic">
            {t("saveChangesCta")}
          </div>
        </button>
      </div>
    </form>
  );
}

EditPersonalExperience.propTypes = {
  userDetails: PropTypes.object,
  isVendor: PropTypes.bool,
  onEditClick: PropTypes.func,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  userDomains: PropTypes.array,
  userSubDomains: PropTypes.array,
};

export default EditPersonalExperience;
