import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Oval } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PersonalDetailsForm from "../vendorForm/personalDetailsForm";

function EditLegalDetails({
  userDetails = {},
  onDiscard = () => {},
  onSave = () => {},
  isLoading = false,
}) {
  const [t] = useTranslation();

  const defaultsValues = {
    gender: userDetails?.extraInformation?.personalDetails?.gender ?? "",
    dateOfBirth:
      userDetails?.extraInformation?.personalDetails?.dateOfBirth ?? "",
    martialStatus:
      userDetails?.extraInformation?.personalDetails?.martialStatus ?? "",
    countryOfBirth:
      userDetails?.extraInformation?.personalDetails?.countryOfBirth ?? "",
    placeOfBirth:
      userDetails?.extraInformation?.personalDetails?.placeOfBirth ?? "",
    socialSecurityNo:
      userDetails?.extraInformation?.personalDetails?.socialSecurityNo ?? "",
    nationalCardNo:
      userDetails?.extraInformation?.personalDetails?.nationalCardNo ?? "",
    passportNo:
      userDetails?.extraInformation?.personalDetails?.passportNo ?? "",
    expiryDate:
      userDetails?.extraInformation?.personalDetails?.expiryDate ?? "",
    countryOfIssue:
      userDetails?.extraInformation?.personalDetails?.countryOfIssue ?? "",
    placeOfIssue:
      userDetails?.extraInformation?.personalDetails?.placeOfIssue ?? "",
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultsValues,
  });

  return (
    <form
      className="flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]"
      onSubmit={handleSubmit(onSave)}
    >
      <PersonalDetailsForm
        register={register}
        setValue={setValue}
        errors={errors}
        watch={watch}
        title={t("legalDetailsTitle")}
        containerClassName="grid gap-[30px] w-full"
      />

      <div className="flex items-center justify-end gap-[10px]">
        <button
          type="button"
          onClick={onDiscard}
          disabled={isLoading}
          className="cursor-pointer flex py-[14px] px-[16px] gap-[8px] justify-center items-center rounded-[6px] border-[1px] border-solid border-default-color bg-white h-[46px]"
        >
          <div className="text-base-black font-bold text-[16px] not-italic">
            {t("discard")}
          </div>
        </button>
        <button
          type="submit"
          disabled={isLoading}
          className="cursor-pointer flex py-[14px] px-[16px] gap-[8px] justify-center items-center rounded-[6px] border-[1px] border-solid border-primary bg-primary h-[46px]"
        >
          <Oval
            height={30}
            width={30}
            color="#FFF"
            visible={isLoading}
            ariaLabel="oval-loading"
            secondaryColor="#FFF"
            strokeWidth={5}
            strokeWidthSecondary={3}
          />
          <div className="text-white font-bold text-[16px] not-italic">
            {t("saveChangesCta")}
          </div>
        </button>
      </div>
    </form>
  );
}

EditLegalDetails.propTypes = {
  userDetails: PropTypes.object,
  onEditClick: PropTypes.func,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default EditLegalDetails;
