import React from "react";

import { ReactComponent as CookieImage } from "images/auth/cookie.svg";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  TARJAMA_PRIVACY_POLICY_LINK,
  VM_PORTAL_COOKIE_NAME,
} from "utils/constantValues";
import { getCookie } from "utils/getCookie";

function Cookie({ setIsAcceptCookie = () => {} }) {
  const [t] = useTranslation();
  const handleCookie = () => {
    document.cookie = `${VM_PORTAL_COOKIE_NAME}=true; expires=Wed, 05 Aug 2032 23:00:00 UTC`;
    setIsAcceptCookie(true);
  };

  return (
    <div
      className={
        getCookie(VM_PORTAL_COOKIE_NAME)
          ? "hidden"
          : "block rounded-[12px] cookie-box-shadow flex md-max:grid p-[24px] items-center gap-[20px] self-stretch bg-light-primary fixed z-[999999999] bottom-8 "
      }
    >
      <CookieImage />
      <div className="flex flex-col gap-[16px] flex-1">
        <div className="text-primary font-semibold text-[20px]">
          {t("cookieTitle")}
        </div>

        <div className="text-gray-500 font-regular text-[14px]">
          {t("cookieMessage")}
          <span
            className="text-primary font-bold cursor-pointer"
            onClick={() => window.open(TARJAMA_PRIVACY_POLICY_LINK, "_blank")}
          >
            {" "}
            {t("privacyNotice")}
          </span>
        </div>
      </div>

      <div
        className="flex height-[34px] py-[12.196px] px-[13.938px] items-center justify-center gap-[6.969px] rounded-[5.227px] border-solid border-secondary bg-primary cursor-pointer"
        onClick={handleCookie}
      >
        <div className="text-white font-bold text-[14px]">{t("cookieCta")}</div>
      </div>
    </div>
  );
}

Cookie.propTypes = {
  setIsAcceptCookie: PropTypes.func,
};

export default Cookie;
