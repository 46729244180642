import { toast } from "react-toastify";

function useNotify() {
  const notifySuccess = (
    message,
    options = {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: {
        borderRadius: 46,
        zIndex: 99999999,
      },
    }
  ) => {
    if (!message) {
      return;
    }
    toast.success(message, options);
  };

  const notifyError = (
    message,
    options = {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: {
        borderRadius: 46,
        zIndex: 99999999,
      },
    }
  ) => {
    if (!message) {
      return;
    }
    toast.error(message, options);
  };

  const notifyInfo = (
    message,
    options = {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: {
        borderRadius: 46,
        zIndex: 99999999,
      },
    }
  ) => {
    if (!message) {
      return;
    }
    toast.info(message, options);
  };

  const notifyWarning = (
    message,
    options = {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: {
        borderRadius: 46,
        zIndex: 99999999,
      },
    }
  ) => {
    if (!message) {
      return;
    }
    toast.warn(message, options);
  };

  return { notifySuccess, notifyError, notifyInfo, notifyWarning };
}

export default useNotify;
