import { useState, useEffect } from "react";
import useNotify from "hooks/useNotify";
import profileApi from "api/profile";
import { scrollIntoView } from "utils/helper";

function useContactDetails({ userDetails = {}, profileCompletionRef = {} }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditContactDetails, setIsEditContactDetails] = useState(false);

  profileCompletionRef.vendorContacts = {
    openEdit: setIsEditContactDetails,
    permanentAddress:
      userDetails?.extraInformation?.contactDetails?.permanentAddress ?? "",
    currentResidenceAddress:
      userDetails?.extraInformation?.contactDetails?.currentResidenceAddress ??
      "",
    country: userDetails?.extraInformation?.contactDetails?.country ?? "",
    phoneNumber:
      userDetails?.extraInformation?.contactDetails?.phoneNumber ?? "",
    homeTelephone:
      userDetails?.extraInformation?.contactDetails?.homeTelephone ?? "",
  };

  const { notifyError, notifySuccess } = useNotify();

  const onEditContactDetails = async (values, setUserData = () => {}) => {
    try {
      setIsLoading(true);

      const payload = { contacts: values };

      await profileApi.updateVendorContactDetails(
        userDetails?.vendorId,
        payload
      );
      setUserData((prevState) => {
        prevState.extraInformation.contactDetails = values;
        return prevState;
      });
      setIsEditContactDetails(false);
      notifySuccess("Has been updated successfully");
      scrollIntoView("contactDetailsScrollId");
    } catch (err) {
      console.error(err);
      notifyError(err?.data?.error ?? err?.message ?? "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  const resetOnUnmount = () => {};
  useEffect(() => {
    return () => {
      resetOnUnmount();
    };
  }, []);

  return {
    isEditContactDetails,
    setIsEditContactDetails,
    onEditContactDetails,
    isContactDetailsLoading: isLoading,
  };
}

export default useContactDetails;
