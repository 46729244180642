import { useState, useEffect } from "react";
import useNotify from "hooks/useNotify";
import profileApi from "api/profile";
import { scrollIntoView } from "utils/helper";

function useQualifications({ userDetails = {}, profileCompletionRef = {} }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditQualifications, setIsEditQualifications] = useState(false);

  profileCompletionRef.qualifications = {
    openEdit: setIsEditQualifications,
    university: userDetails?.extraInformation?.qualifications?.university ?? "",
    qualification:
      userDetails?.extraInformation?.qualifications?.qualification ?? "",
    major: userDetails?.extraInformation?.qualifications?.major ?? "",
  };

  const { notifyError, notifySuccess } = useNotify();

  const onEditQualifications = async (values, setUserData = () => {}) => {
    try {
      setIsLoading(true);

      const payload = { qualifications: values };

      await profileApi.updateVendorQualifications(
        userDetails?.vendorId,
        payload
      );
      setUserData((prevState) => {
        prevState.extraInformation.qualifications = values;
        return prevState;
      });
      setIsEditQualifications(false);
      notifySuccess("Has been updated successfully");
      scrollIntoView("qualificationsScrollId");
    } catch (err) {
      console.error(err);
      notifyError(err?.data?.error ?? err?.message ?? "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  const resetOnUnmount = () => {};
  useEffect(() => {
    return () => {
      resetOnUnmount();
    };
  }, []);

  return {
    isEditQualifications,
    setIsEditQualifications,
    onEditQualifications,
    isQualificationsLoading: isLoading,
  };
}

export default useQualifications;
