/* eslint-disable no-loop-func */
import { useRef, useState, useEffect } from "react";
import { fileToBuffer } from "utils/fileToBuffer";
import axios from "axios";
import uploadApi from "api/uploadFile";
import useNotify from "hooks/useNotify";
import {
  certificationType,
  portfolioType,
  rateCardType,
  resumeType,
  sampleOfWorkType,
  allowedResumeFileTypes,
  multiCVType,
} from "utils/constantValues";
import i18n from "../../../i18n";

function useUploadFiles({
  defaultResumeFile = {},
  defaultSampleOfWorkFiles = [],
  defaultCertificationsFiles = [],
  defaultPortfolioFiles = [],
  defaultRateCard = [],
  defaultMultiCvsFiles = [],
}) {
  const nonMimeTypeFiles = {
    xlm: "application/xlm",
    srt: "text/plain",
  };
  const resumeRef = useRef(null);
  const sampleOfWorkRef = useRef(null);
  const certificationsRef = useRef(null);
  const rateCardRef = useRef(null);
  const multiCvsRef = useRef(null);
  const portfolioRef = useRef(null);

  const { notifyError } = useNotify();

  const [resumeFileName, setResumeFileName] = useState(
    defaultResumeFile?.name ?? ""
  );
  const [rateCardFiles, setRateCardFiles] = useState(defaultRateCard);
  const [multiCvsFiles, setMultiCvsFiles] = useState(defaultMultiCvsFiles);

  const [SOWFiles, setSOWFiles] = useState(defaultSampleOfWorkFiles);
  const [certificationFiles, setCertificationFiles] = useState(
    defaultCertificationsFiles
  );
  const [portfolioFiles, setPortfolioFiles] = useState(defaultPortfolioFiles);

  const [isUploading, setIsUploading] = useState({
    resume: false,
    sampleOfWork: false,
    certifications: false,
    rateCard: false,
    portfolio: false,
    multiCvs: false,
  });

  const uploadResume = async (e, isDrag, setValue = () => {}) => {
    e.preventDefault();
    e.persist();
    try {
      setIsUploading((prevState) => ({ ...prevState, resume: true }));
      setResumeFileName("");
      const fileList = isDrag ? [...e.dataTransfer.files] : [...e.target.files];
      const file = [...fileList][0];
      const name = file?.name ?? "";
      const extension = name.substr(name.lastIndexOf(".") + 1).toLowerCase();
      const mimeType = file?.type ?? nonMimeTypeFiles[extension] ?? "";

      if (!allowedResumeFileTypes.includes(mimeType) && !!name) {
        setValue("resume", "");
        notifyError(i18n.t("cvAllowedFiles"));
        return;
      }

      if (resumeFileName === name) {
        setValue("resume", "");
        notifyError(i18n.t("sameFileName"));
        return;
      }

      if (!file) return;
      const { id, url } = await uploadApi.getFileUrlApi({
        name,
        type: resumeType,
        mimeType,
      });

      const buffer = await fileToBuffer(file);

      await axios.put(url, buffer, {
        headers: {
          "Content-Type": mimeType,
        },
      });

      setValue("resume", id);
      setResumeFileName(name);
    } catch (err) {
      setValue("resume", "");
      console.error(err);
      notifyError(err?.data?.message ?? err.message ?? "Upload failed!");
    } finally {
      setIsUploading((prevState) => ({ ...prevState, resume: false }));
    }
  };

  const removeResumeFile = (fileName, setValue = () => {}) => {
    setValue("resume", "");
    setResumeFileName("");
  };

  const uploadSampleOfFiles = async (e, isDrag, setValue = () => {}) => {
    e.preventDefault();
    e.persist();
    let filesData = SOWFiles;
    try {
      setIsUploading((prevState) => ({ ...prevState, sampleOfWork: true }));

      const files = isDrag ? [...e.dataTransfer.files] : [...e.target.files];

      for await (const file of files) {
        const name = file?.name ?? "";
        const extension = name.substr(name.lastIndexOf(".") + 1).toLowerCase();
        const mimeType = file?.type ?? nonMimeTypeFiles[extension] ?? "";

        if (filesData.some((el) => el.name === name)) {
          notifyError(i18n.t("sameFileName"));
        } else {
          if (!file) return;
          const { id, url } = await uploadApi.getFileUrlApi({
            name,
            type: sampleOfWorkType,
            mimeType,
          });

          const buffer = await fileToBuffer(file);

          await axios.put(url, buffer, {
            headers: {
              "Content-Type": mimeType,
            },
          });

          setSOWFiles((prevState) => {
            const result = [
              ...prevState,
              { fileId: id, name, type: sampleOfWorkType },
            ];
            filesData = result;
            setValue("sampleOfWorks", result);
            return result;
          });
        }
      }
    } catch (err) {
      console.error(err);
      notifyError(err?.data?.message ?? err.message ?? "Upload failed!");
    } finally {
      setIsUploading((prevState) => ({ ...prevState, sampleOfWork: false }));
    }
  };

  const removeSampleOfFile = (fileName, setValue = () => {}) => {
    const filteredFiles = SOWFiles.filter((file) => file.name !== fileName);
    setValue("sampleOfWorks", filteredFiles);
    setSOWFiles(filteredFiles);
  };

  const uploadCertifications = async (e, isDrag, setValue = () => {}) => {
    e.preventDefault();
    e.persist();
    let filesData = certificationFiles;

    try {
      setIsUploading((prevState) => ({ ...prevState, certifications: true }));

      const files = isDrag ? [...e.dataTransfer.files] : [...e.target.files];
      for await (const file of files) {
        const name = file?.name ?? "";
        const extension = name.substr(name.lastIndexOf(".") + 1).toLowerCase();
        const mimeType = file?.type ?? nonMimeTypeFiles[extension] ?? "";

        if (filesData.some((el) => el.name === name)) {
          notifyError(i18n.t("sameFileName"));
        } else {
          if (!file) return;
          const { id, url } = await uploadApi.getFileUrlApi({
            name,
            type: certificationType,
            mimeType,
          });

          const buffer = await fileToBuffer(file);

          await axios.put(url, buffer, {
            headers: {
              "Content-Type": mimeType,
            },
          });

          setCertificationFiles((prevState) => {
            const result = [
              ...prevState,
              { fileId: id, name, type: certificationType },
            ];
            filesData = result;
            setValue("certifications", result);
            return result;
          });
        }
      }
    } catch (err) {
      console.error(err);
      notifyError(err?.data?.message ?? err.message ?? "Upload failed!");
    } finally {
      setIsUploading((prevState) => ({ ...prevState, certifications: false }));
    }
  };

  const removeCertificationFile = (fileName, setValue = () => {}) => {
    const filteredFiles = certificationFiles.filter(
      (file) => file.name !== fileName
    );

    setValue("certifications", filteredFiles);
    setCertificationFiles(filteredFiles);
  };

  const uploadRateCard = async (e, isDrag, setValue = () => {}) => {
    e.preventDefault();
    e.persist();
    let filesData = certificationFiles;

    try {
      setIsUploading((prevState) => ({ ...prevState, rateCard: true }));

      const files = isDrag ? [...e.dataTransfer.files] : [...e.target.files];
      for await (const file of files) {
        const name = file?.name ?? "";
        const extension = name.substr(name.lastIndexOf(".") + 1).toLowerCase();
        const mimeType = file?.type ?? nonMimeTypeFiles[extension] ?? "";

        if (filesData.some((el) => el.name === name)) {
          notifyError(i18n.t("sameFileName"));
        } else {
          if (!file) return;
          const { id, url } = await uploadApi.getFileUrlApi({
            name,
            type: rateCardType,
            mimeType,
          });

          const buffer = await fileToBuffer(file);

          await axios.put(url, buffer, {
            headers: {
              "Content-Type": mimeType,
            },
          });

          setRateCardFiles((prevState) => {
            const result = [
              ...prevState,
              { fileId: id, name, type: rateCardType },
            ];
            filesData = result;
            setValue("rateCard", result);
            return result;
          });
        }
      }
    } catch (err) {
      console.error(err);
      notifyError(err?.data?.message ?? err.message ?? "Upload failed!");
    } finally {
      setIsUploading((prevState) => ({ ...prevState, rateCard: false }));
    }
  };

  const removeRateCardFile = (fileName, setValue = () => {}) => {
    const filteredFiles = rateCardFiles.filter(
      (file) => file.name !== fileName
    );

    setValue("rateCard", filteredFiles);
    setRateCardFiles(filteredFiles);
  };

  const uploadMultiCvs = async (e, isDrag, setValue = () => {}) => {
    e.preventDefault();
    e.persist();
    let filesData = multiCvsFiles;

    try {
      setIsUploading((prevState) => ({ ...prevState, multiCvs: true }));

      const files = isDrag ? [...e.dataTransfer.files] : [...e.target.files];

      for await (const file of files) {
        const name = file?.name ?? "";
        const extension = name.substr(name.lastIndexOf(".") + 1).toLowerCase();
        const mimeType = file?.type ?? nonMimeTypeFiles[extension] ?? "";

        if (filesData.some((el) => el.name === name)) {
          notifyError(i18n.t("sameFileName"));
        } else {
          if (!file) return;
          const { id, url } = await uploadApi.getFileUrlApi({
            name,
            type: multiCVType,
            mimeType,
          });

          const buffer = await fileToBuffer(file);

          await axios.put(url, buffer, {
            headers: {
              "Content-Type": mimeType,
            },
          });

          setMultiCvsFiles((prevState) => {
            const result = [
              ...prevState,
              { fileId: id, name, type: multiCVType },
            ];
            filesData = result;
            setValue("multiCvs", result);
            return result;
          });
        }
      }
    } catch (err) {
      console.error(err);
      notifyError(err?.data?.message ?? err.message ?? "Upload failed!");
    } finally {
      setIsUploading((prevState) => ({ ...prevState, multiCvs: false }));
    }
  };

  const removeMultiCv = (fileName, setValue = () => {}) => {
    const filteredFiles = multiCvsFiles.filter(
      (file) => file.name !== fileName
    );

    setValue("multiCvs", filteredFiles);
    setMultiCvsFiles(filteredFiles);
  };

  const uploadPortfolio = async (e, isDrag, setValue = () => {}) => {
    e.preventDefault();
    e.persist();
    let filesData = portfolioFiles;
    try {
      setIsUploading((prevState) => ({ ...prevState, portfolio: true }));

      const files = isDrag ? [...e.dataTransfer.files] : [...e.target.files];

      for await (const file of files) {
        const name = file?.name ?? "";
        const extension = name.substr(name.lastIndexOf(".") + 1).toLowerCase();
        const mimeType = file?.type ?? nonMimeTypeFiles[extension] ?? "";

        if (filesData.some((el) => el.name === name)) {
          notifyError(i18n.t("sameFileName"));
        } else {
          if (!file) return;
          const { id, url } = await uploadApi.getFileUrlApi({
            name,
            type: portfolioType,
            mimeType,
          });

          const buffer = await fileToBuffer(file);

          await axios.put(url, buffer, {
            headers: {
              "Content-Type": mimeType,
            },
          });

          setPortfolioFiles((prevState) => {
            const result = [
              ...prevState,
              { fileId: id, name, type: portfolioType },
            ];
            filesData = result;
            setValue("portfolio", result);
            return result;
          });
        }
      }
    } catch (err) {
      console.error(err);
      notifyError(err?.data?.message ?? err.message ?? "Upload failed!");
    } finally {
      setIsUploading((prevState) => ({ ...prevState, portfolio: false }));
    }
  };

  const removePortfolioFile = (fileName, setValue = () => {}) => {
    const filteredFiles = portfolioFiles.filter(
      (file) => file.name !== fileName
    );

    setValue("portfolio", filteredFiles);
    setPortfolioFiles(filteredFiles);
  };

  const resetOnUnmount = () => {
    setIsUploading({
      resume: false,
      sampleOfWork: false,
      certifications: false,
    });
    setResumeFileName("");
    setSOWFiles([]);
    setCertificationFiles([]);
    setRateCardFiles("");
    setPortfolioFiles([]);

    setMultiCvsFiles([]);
    resumeRef.current = null;
    sampleOfWorkRef.current = null;
    certificationsRef.current = null;
    rateCardRef.current = null;
    portfolioRef.current = null;
    multiCvsRef.current = null;
  };

  useEffect(() => {
    return () => {
      resetOnUnmount();
    };
  }, []);

  return {
    resumeRef,
    uploadResume,
    sampleOfWorkRef,
    uploadSampleOfFiles,
    certificationsRef,
    uploadCertifications,
    isUploading,
    resumeFileName,
    removeResumeFile,
    SOWFiles,
    removeSampleOfFile,
    certificationFiles,
    removeCertificationFile,
    rateCardRef,
    multiCvsRef,
    uploadRateCard,
    rateCardFiles: rateCardFiles || [],
    removeRateCardFile,
    portfolioRef,
    portfolioFiles,
    uploadPortfolio,
    removePortfolioFile,
    uploadMultiCvs,
    removeMultiCv,
    multiCvsFiles,
    setMultiCvsFiles,
  };
}

export default useUploadFiles;
