import { useState, useEffect } from "react";
import useNotify from "hooks/useNotify";
import { useTranslation } from "react-i18next";
import profileApi from "api/profile";
import { scrollIntoView } from "utils/helper";
import { sampleOfWorkType } from "utils/constantValues";

function useRelevantProfile({ userDetails = {}, profileCompletionRef = {} }) {
  const currentSampleOfWorkIds =
    userDetails?.userFiles
      ?.filter((file) => file.type === sampleOfWorkType)
      ?.map((file) => file.id) ?? [];

  const defaultRelevantProfiles =
    userDetails?.extraInformation?.profile &&
    userDetails?.extraInformation?.profile.length > 0
      ? userDetails?.extraInformation.profile.map((_, i) => i)
      : [0];

  const [t] = useTranslation();
  const { notifyError, notifySuccess } = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditRelevantProfile, setIsEditRelevantProfile] = useState(false);
  const [relevantProfiles, setRelevantProfile] = useState(
    defaultRelevantProfiles
  );

  profileCompletionRef.relevant = {
    openEdit: setIsEditRelevantProfile,
    profile: userDetails?.extraInformation?.profile ?? [],
    sampleOfWork: currentSampleOfWorkIds,
  };

  const removeRelevantProfile = (index) => {
    setRelevantProfile(relevantProfiles.filter((_, i) => i !== index));
  };

  const onEditRelevantProfile = async (values, setUserData = () => {}) => {
    try {
      setIsLoading(true);
      const { vendorAdditionalInfo, newCreated, deleted } = values;
      const deletedIds = deleted?.map((el) => el.fileId) ?? [];

      const payload = {
        profileLinks: vendorAdditionalInfo?.profile?.filter?.(Boolean) ?? [],
        sampleOfWorks: {
          newCreated:
            newCreated
              ?.filter(
                (el) =>
                  !deletedIds.includes(el.fileId) &&
                  !currentSampleOfWorkIds.includes(el.fileId)
              )
              ?.map((el) => el.fileId) ?? [],
          deleted: deletedIds,
        },
      };

      await profileApi.updateRelevantProfile(userDetails?.vendorId, payload);

      const userData = await profileApi.getUserProfileByIdApi(userDetails?.id);
      setUserData(userData);
      setIsEditRelevantProfile(false);
      notifySuccess("Has been updated successfully");
      scrollIntoView("relevantProfileIdScrollId");
    } catch (err) {
      console.error(err);
      notifyError(err?.data?.error ?? err?.message ?? "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  const resetOnUnmount = () => {};
  useEffect(() => {
    return () => {
      resetOnUnmount();
    };
  }, []);

  return {
    isEditRelevantProfile,
    setIsEditRelevantProfile,
    onEditRelevantProfile,
    isRelevantProfileLoading: isLoading,
    relevantProfiles,
    setRelevantProfile,
    removeRelevantProfile,
  };
}

export default useRelevantProfile;
