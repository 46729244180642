import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CardCheckbox from "components/cardCheckbox";
import { ReactComponent as LockIcon } from "images/profile/lock.svg";
import { ReactComponent as EditIcon } from "images/profile/edit-icon.svg";
import useProfileHelper from "pages/profile/useProfileHelper";

function ProfileServices({
  userDetails = {},
  isVendor,
  onEditClick = () => {},
  isShowEditIcon = false,
}) {
  const [t] = useTranslation();
  const { handleServiceRateLabels, displayLabel } = useProfileHelper();
  return (
    <div className="flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]">
      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        <div className="text-primary font-semibold text-[30px] not-italic">
          {isVendor ? t("skills") : t("services")}
        </div>

        {!userDetails.flags.lockService ? (
          isShowEditIcon && (
            <EditIcon className="cursor-pointer" onClick={onEditClick} />
          )
        ) : (
          <LockIcon />
        )}
      </div>

      <div className="flex flex-wrap gap-[10px]">
        {userDetails?.userServices?.length > 0 ? (
          userDetails.userServices.map((elem) => {
            const serviceId = elem.id;
            const userRates = handleServiceRateLabels(
              serviceId,
              userDetails.userRates
            );

            const displayFirstServiceRate = userRates[0];
            return (
              <Fragment key={`${serviceId}`}>
                <CardCheckbox
                  isActive={true}
                  titleClassName="text-primary font-bold text-[16px] not-italic"
                  title={
                    displayFirstServiceRate ? (
                      <div className="text-primary font-bold text-[16px] not-italic">
                        {displayLabel(displayFirstServiceRate, true)}
                        <span className="font-medium">{` - $${
                          displayFirstServiceRate.rate
                        }${t(
                          `serviceRate-${displayFirstServiceRate.rateTitle}`
                        )}`}</span>
                      </div>
                    ) : (
                      elem.title
                    )
                  }
                  hideCheckIcon
                  containerClassName={`flex justify-center items-center p-[12px] rounded-[4px] bg-light-primary h-[36px]`}
                  isActiveClassName="no-className"
                  isShowInfoIcon={userRates.length > 1}
                  tooltipIconId={elem.title}
                  hoverContent={
                    userRates.length > 1 ? (
                      <ul>
                        {userRates.map((serviceRate) => {
                          return (
                            <li
                              key={serviceRate.id}
                              className="text-white text-[14px] font-bold"
                            >
                              {`${displayLabel(serviceRate, false)} $${
                                serviceRate.rate
                              }${t(`serviceRate-${serviceRate.rateTitle}`)}`}
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      ""
                    )
                  }
                />
              </Fragment>
            );
          })
        ) : (
          <div className="flex text-center items-center justify-center">
            <div className="text-gray-900 font-semibold text-[24px] not-italic">
              {"-"}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

ProfileServices.propTypes = {
  userDetails: PropTypes.object,
  isVendor: PropTypes.bool,
  onEditClick: PropTypes.func,
  isShowEditIcon: PropTypes.bool,
};

export default ProfileServices;
