import React from "react";
import { useTranslation } from "react-i18next";
import Input from "components/input";
import PropTypes from "prop-types";

function QualificationsForm({
  register = () => {},
  setValue = () => {},
  errors = {},
  watch = () => {},
  getValues = () => {},
  title = "",
  containerClassName = "flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]",
}) {
  const [t] = useTranslation();

  return (
    <div className={containerClassName}>
      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        <div className="text-primary font-semibold text-[30px] not-italic">
          {title || t("qualificationsTitle")}
        </div>
      </div>

      <div className="flex flex-wrap gap-[20px]">
        <Input
          label={t("university")}
          isRequired={true}
          placeholder={t("placeHolderType", {
            title: t("university").toLowerCase(),
          })}
          name="university"
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("university") }),
          }}
          errorMessage={errors.university ? errors.university.message : ""}
          containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.university ? "border-red" : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />
        <Input
          label={t("qualifications")}
          isRequired={true}
          placeholder={t("placeHolderType", {
            title: t("qualifications").toLowerCase(),
          })}
          name="qualification"
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("qualifications") }),
          }}
          errorMessage={
            errors.qualification ? errors.qualification.message : ""
          }
          containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.qualification ? "border-red" : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />
        <Input
          label={t("major")}
          isRequired={true}
          placeholder={t("placeHolderType", {
            title: t("major").toLowerCase(),
          })}
          name="major"
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("major") }),
          }}
          errorMessage={errors.major ? errors.major.message : ""}
          containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.major ? "border-red" : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />
      </div>
    </div>
  );
}
QualificationsForm.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  watch: PropTypes.func,
  getValues: PropTypes.func,
  title: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default QualificationsForm;
