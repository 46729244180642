import React from "react";
import { useTranslation } from "react-i18next";
import TarjamaIcon from "images/footer/tarjama-icon.png";
import { ReactComponent as LinkedinIcon } from "images/footer/linkedin-icon.svg";
import { ReactComponent as FacebookIcon } from "images/footer/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "images/footer/u_twitter.svg";
import { ReactComponent as InstagramIcon } from "images/footer/u_instagram-alt.svg";
import { ReactComponent as YoutubeIcon } from "images/footer/u_youtube.svg";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const [t] = useTranslation();
  return (
    <div className="w-full bg-footer-color min-h-[421px] py-[50px] px-[100px] lg-max:px-[1rem] flex flex-col gap-[19px]">
      <div className="flex flex-row md-max:flex-col items-center md-max:items-start gap-[140px] md-max:gap-[24px] self-stretch">
        <div className="flex flex-col gap-[20px]">
          <img
            src={TarjamaIcon}
            alt="tarjama-icon"
            className="w-[330px] object-cover"
          />
          <div className="text-dark-gray font-regular text-[16px] not-italic font-normal leading-6 max-w-[627.928px]">
            {t("titleText1")} <br />
            {t("titleText2")}
          </div>
        </div>
        <div className="flex flex-col gap-[30px] flex-1">
          <div className="font-bold text-white text-[28px] max-md:text-[20px] leading-6 not-italic font-normal tracking-tight">
            {t("secondTitle1")}{" "}
            <span className="text-secondary">{t("secondTitle2")}</span>
          </div>
          <div className="flex justify-between gap-[30px] max-md:gap-[10px]">
            {/* First Column */}
            <div className="flex flex-col gap-[16px]">
              <div className="text-gray text-[20px] max-md:text-[16px]  leading-6 not-italic font-regular mb-[14px]">
                {t("companyTitle")}
              </div>
              <div
                className="text-gray text-[16px] max-md:text-[14px] leading-6 not-italic font-normal font-regular cursor-pointer"
                onClick={() =>
                  window.open("https://tarjama.com/company/", "_blank")
                }
              >
                {t("aboutus")}
              </div>
              <div
                className="text-gray text-[16px] max-md:text-[14px] leading-6 not-italic font-normal font-regular cursor-pointer"
                onClick={() =>
                  window.open("https://tarjama.com/our-technologies/", "_blank")
                }
              >
                {t("ourTechnology")}
              </div>
              <div className="text-gray text-[16px] max-md:text-[14px] leading-6 not-italic font-normal font-regular">
                {t("contactus")}
              </div>
              <div
                className="text-gray text-[16px] max-md:text-[14px] leading-6 not-italic font-normal font-regular cursor-pointer"
                onClick={() =>
                  window.open("https://tarjama.com/privacy-policy/", "_blank")
                }
              >
                {t("privacyPolicy")}
              </div>

              <div
                className="text-gray text-[16px] max-md:text-[14px] leading-6 not-italic font-normal font-regular cursor-pointer"
                onClick={() =>
                  window.open("https://tarjama.com/terms/", "_blank")
                }
              >
                {t("termsAndConditions")}
              </div>
            </div>
            {/* End of first column */}

            {/* Second Column */}

            <div className="flex flex-col gap-[16px]">
              <div className="text-gray text-[20px] max-md:text-[16px] leading-6 not-italic font-regular mb-[14px]">
                {t("careers")}
              </div>

              <div
                className="text-gray text-[16px] max-md:text-[14px] leading-6 not-italic font-normal font-regular cursor-pointer"
                onClick={() =>
                  window.open("https://tarjama.com/careers/", "_blank")
                }
              >
                {t("workWithUs")}
              </div>

              <div className="text-gray text-[16px] max-md:text-[14px] leading-6 not-italic font-normal font-regular">
                {t("resources")}
              </div>

              <div
                className="text-gray text-[16px] max-md:text-[14px] leading-6 not-italic font-normal font-regular cursor-pointer"
                onClick={() =>
                  window.open("https://tarjama.com/blog/", "_blank")
                }
              >
                {t("blog")}
              </div>
              <div
                className="text-gray text-[16px] max-md:text-[14px] leading-6 not-italic font-normal font-regular cursor-pointer"
                onClick={() =>
                  window.open(
                    "https://tarjama.com/blog/case-studies/",
                    "_blank"
                  )
                }
              >
                {t("caseStudies")}
              </div>
              <div
                className="text-gray text-[16px] max-md:text-[14px] leading-6 not-italic font-normal font-regular cursor-pointer"
                onClick={() =>
                  window.open("https://tarjama.com/help-center/", "_blank")
                }
              >
                {t("helpCenter")}
              </div>
            </div>
            {/* End of second column */}

            {/* Third Column */}

            <div className="flex flex-col gap-[16px]">
              <div className="text-gray text-[20px] max-md:text-[16px] leading-6 not-italic font-regular mb-[14px]">
                {t("socialMedia")}
              </div>

              <div
                className="flex gap-[8px]  cursor-pointer"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/tarjama",
                    "_blank"
                  )
                }
              >
                <LinkedinIcon />
                <div className="text-gray text-[16px] max-md:text-[14px] leading-6 not-italic font-normal font-regular">
                  {t("linkedIn")}
                </div>
              </div>

              <div
                className="flex gap-[8px]  cursor-pointer"
                onClick={() =>
                  window.open("https://www.facebook.com/TarjamaMENA/", "_blank")
                }
              >
                <FacebookIcon />
                <div className="text-gray text-[16px] max-md:text-[14px] leading-6 not-italic font-normal font-regular">
                  {t("facebook")}
                </div>
              </div>

              <div
                className="flex gap-[8px]  cursor-pointer"
                onClick={() =>
                  window.open("https://twitter.com/TarjamaMENA", "_blank")
                }
              >
                <TwitterIcon />
                <div className="text-gray text-[16px] max-md:text-[14px] leading-6 not-italic font-normal font-regular">
                  {t("twitter")}
                </div>
              </div>

              <div
                className="flex gap-[8px]  cursor-pointer"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/tarjamainsider/",
                    "_blank"
                  )
                }
              >
                <InstagramIcon />
                <div className="text-gray text-[16px] max-md:text-[14px] leading-6 not-italic font-normal font-regular">
                  {t("instagram")}
                </div>
              </div>

              <div
                className="flex gap-[8px]  cursor-pointer"
                onClick={() =>
                  window.open("https://www.youtube.com/c/TarjamaMENA", "_blank")
                }
              >
                <YoutubeIcon />
                <div className="text-gray text-[16px] max-md:text-[14px] leading-6 not-italic font-normal font-regular">
                  {t("youtube")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center text-dark-gray font-regular text-[16px] not-italic font-normal leading-6">{`Copyright Tarjama ${currentYear}. All Rights Reserved.`}</div>
    </div>
  );
}
