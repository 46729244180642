import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ReactComponent as TickCircle } from "images/profile/tick-circle.svg";
import { ReactComponent as ArrowRight } from "images/profile/arrow-right.svg";
import { ReactComponent as LockIcon } from "images/profile/lock.svg";

function ProfileCompletion({
  sections = [],
  onOpenEditSection = () => {},
  allSectionPercentages = 0,
  bounceToSection = () => {},
}) {
  const [t] = useTranslation();

  return (
    <div className="grid w-full ">
      <div className="flex p-[20px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]">
        <div className="grid w-full gap-[20px]">
          <div className="grid w-full gap-[20px] mb-[8px]">
            <div className="flex justify-between items-center self-stretch">
              <div className="text-gray-900 font-semibold text-[24px]">
                {t("profileCompletion")}
              </div>
              <div className="flex p-[10px] justify-center items-center rounded-[4px] bg-light-primary">
                {`${allSectionPercentages}% ${t("completed")}`}
              </div>
            </div>
            <div className="w-full bg-gray-25 rounded-[4px]">
              <div
                style={{ width: `${allSectionPercentages}%` }}
                className="bg-success h-[8px] rounded-[4px]"
              ></div>
            </div>
          </div>

          {sections
            .filter((section) => !section.isHide)
            .map((section) => {
              return (
                <div
                  className={`flex justify-between items-center self-stretch`}
                  key={section?.sectionTitle}
                >
                  <div
                    className="flex items-center gap-[12px] cursor-pointer"
                    onClick={() => {
                      bounceToSection(section);
                    }}
                  >
                    <div className="text-gray-900 font-medium text-[18px]">
                      {section?.sectionTitle}
                    </div>
                    {!section.isDone && (
                      <div className="flex p-[10px] justify-center items-center rounded-[4px] bg-light-primary">
                        {`${section?.percentage ?? 0}%`}
                      </div>
                    )}
                  </div>

                  {section.isDone ? (
                    <TickCircle />
                  ) : section.isLock ? (
                    <LockIcon />
                  ) : section.canOpenEdit ? (
                    <ArrowRight
                      className="cursor-pointer"
                      onClick={() => onOpenEditSection(section)}
                    />
                  ) : null}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

ProfileCompletion.propTypes = {
  sections: PropTypes.array,
  onOpenEditSection: PropTypes.func,
  allSectionPercentages: PropTypes.number,
  bounceToSection: PropTypes.func,
};

export default ProfileCompletion;
