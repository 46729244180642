import axiosInstance from "api/setupAxios";

const profileApi = {
  getUserProfileByIdApi: async (userId) => {
    const { data } = await axiosInstance.get(`/user/${userId}`);
    return data;
  },

  getUserDomainsByIdApi: async (userId) => {
    const domains = await axiosInstance.get(`/user/${userId}/domains`);

    return domains;
  },

  getUserSubDomainsByIdApi: async (userId) => {
    const subDomains = await axiosInstance.get(`/user/${userId}/subDomains`);
    return subDomains;
  },

  updateUserPasswordApi: async (payload = {}) => {
    const data = await axiosInstance.patch("/user/updatePassword", payload);
    return data;
  },
  updatePersonalDetailsApi: async (vendorId, payload = {}) => {
    const { data } = await axiosInstance.patch(
      `/vendor/${vendorId}/personalDetails`,
      payload
    );
    return data;
  },

  updateVendorExperienceApi: async (vendorId, payload = {}) => {
    const { data } = await axiosInstance.patch(
      `/vendor/${vendorId}/experience`,
      payload
    );
    return data;
  },

  updateAgencyExperienceApi: async (agencyId, payload = {}) => {
    const { data } = await axiosInstance.patch(
      `/agency/${agencyId}/experience`,
      payload
    );
    return data;
  },

  updateVendorResume: async (vendorId, payload = {}) => {
    const { data } = await axiosInstance.patch(
      `/vendor/${vendorId}/resumes`,
      payload
    );
    return data;
  },

  updateRelevantProfile: async (vendorId, payload = {}) => {
    const { data } = await axiosInstance.patch(
      `/vendor/${vendorId}/relevantProfiles`,
      payload
    );
    return data;
  },

  updateVendorCertificationApi: async (vendorId, payload = {}) => {
    const { data } = await axiosInstance.patch(
      `/vendor/${vendorId}/certifications`,
      payload
    );
    return data;
  },

  updateAgencyFiles: async (agencyId, payload = {}) => {
    const { data } = await axiosInstance.patch(
      `/agency/${agencyId}/files`,
      payload
    );
    return data;
  },

  updateCompanyDetails: async (agencyId, payload = {}) => {
    const { data } = await axiosInstance.patch(
      `/agency/${agencyId}/companyDetails`,
      payload
    );
    return data;
  },

  updateVendorServices: async (vendorId, payload = {}) => {
    const { data } = await axiosInstance.patch(
      `/vendor/${vendorId}/skills`,
      payload
    );
    return data;
  },

  updateAgencyServices: async (agencyId, payload = {}) => {
    const { data } = await axiosInstance.patch(
      `/agency/${agencyId}/skills`,
      payload
    );
    return data;
  },

  getFileUrlApi: async (fileId) => {
    const { data } = await axiosInstance.get(`/user/file/${fileId}/url`);
    return data;
  },

  updateAdditionalVendorInfo: async (vendorId, payload = {}) => {
    const response = await axiosInstance.patch(
      `/vendor/${vendorId}/additionalInfo`,
      payload
    );
    return response;
  },

  updateAdditionalAgencyInfo: async (agencyId, payload = {}) => {
    const response = await axiosInstance.patch(
      `/agency/${agencyId}/additionalInfo`,
      payload
    );
    return response;
  },

  updateVendorQualifications: async (vendorId, payload = {}) => {
    const response = await axiosInstance.patch(
      `/vendor/${vendorId}/qualifications`,
      payload
    );
    return response;
  },

  updateBankDetails: async (vendorId, payload = {}) => {
    const response = await axiosInstance.patch(
      `/vendor/${vendorId}/bankDetails`,
      payload
    );
    return response;
  },

  updateEmergencyContact: async (vendorId, payload = {}) => {
    const response = await axiosInstance.patch(
      `/vendor/${vendorId}/emergencyContacts`,
      payload
    );
    return response;
  },

  updateAgencyBankDetails: async (agencyId, payload = {}) => {
    const response = await axiosInstance.patch(
      `/agency/${agencyId}/bankDetails`,
      payload
    );
    return response;
  },

  updateLegalDetails: async (vendorId, payload = {}) => {
    const { data } = await axiosInstance.patch(
      `/vendor/${vendorId}/legalDetails`,
      payload
    );
    return data;
  },

  updateVendorContactDetails: async (vendorId, payload = {}) => {
    const response = await axiosInstance.patch(
      `/vendor/${vendorId}/contacts`,
      payload
    );
    return response;
  },

  updateAgencyContactDetails: async (agencyId, payload = {}) => {
    const response = await axiosInstance.patch(
      `/agency/${agencyId}/contacts`,
      payload
    );
    return response;
  },
};

export default profileApi;
