import React from "react";
import { Oval } from "react-loader-spinner";
import BGShutterstock from "images/auth/bg-shutterstock.png";
import { ReactComponent as TVendorIcon } from "images/auth/t-vendors.svg";
import useLogout from "hooks/useLogout";

function Header() {
  const { onLogoutSubmit, isLoading } = useLogout();
  return (
    <div
      className="w-full h-[80px] bg-no-repeat  bg-cover bg-center flex items-center justify-between px-[80px] md-max:px-[1rem]"
      style={{
        backgroundImage: `url(${BGShutterstock})`,
      }}
    >
      <TVendorIcon />

      <button
        className="flex py-[14px] bg-transparent h-[40px] px-[16px] items-center justify-center gap-[8px] rounded-[6px] border-[1px] border-solid border-white cursor-pointer"
        type="button"
        onClick={onLogoutSubmit}
        disabled={isLoading}
      >
        <Oval
          height={30}
          width={30}
          color="#fff"
          visible={isLoading}
          ariaLabel="oval-loading"
          secondaryColor="#fff"
          strokeWidth={5}
          strokeWidthSecondary={3}
        />
        <div className="text-white font-bold text-[16px] not-italic">
          Logout
        </div>
      </button>
    </div>
  );
}

export default Header;
