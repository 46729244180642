import React from "react";
import { useTranslation } from "react-i18next";
import Input from "components/input";
import Select from "components/select";
import PropTypes from "prop-types";
import { useLookupsContext } from "hooks/useContext";

function ContactDetailsForm({
  register = () => {},
  setValue = () => {},
  errors = {},
  watch = () => {},
  getValues = () => {},
  title = "",
  containerClassName = "flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]",
}) {
  const [t] = useTranslation();

  const { countries } = useLookupsContext(false);

  const countriesOptions = countries.map((elem) => ({
    ...elem,
    label: elem.name,
    value: elem.id,
  }));

  return (
    <div className={containerClassName}>
      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        <div className="text-primary font-semibold text-[30px] not-italic">
          {title || t("contactDetailsTitle")}
        </div>
      </div>

      <div className="flex flex-wrap gap-[20px]">
        <div className="grid grid-cols-2 sm-max:grid-cols-1  w-full gap-[16px]">
          <Input
            label={t("permanentAddress")}
            isRequired={true}
            placeholder={t("placeHolderType", {
              title: t("permanentAddress").toLowerCase(),
            })}
            name="permanentAddress"
            register={register}
            registerArgs={{
              required: t("errorField", { field: t("permanentAddress") }),
            }}
            errorMessage={
              errors.permanentAddress ? errors.permanentAddress.message : ""
            }
            containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
            labelClassName="text-primary font-semibold text-[16px]"
            inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
              errors.permanentAddress ? "border-red" : "border-default-color"
            } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
          />

          <Input
            label={t("currentResidenceAddress")}
            isRequired={true}
            placeholder={t("placeHolderType", {
              title: t("currentResidenceAddress").toLowerCase(),
            })}
            name="currentResidenceAddress"
            register={register}
            registerArgs={{
              required: t("errorField", {
                field: t("currentResidenceAddress"),
              }),
            }}
            errorMessage={
              errors.currentResidenceAddress
                ? errors.currentResidenceAddress.message
                : ""
            }
            containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
            labelClassName="text-primary font-semibold text-[16px]"
            inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
              errors.currentResidenceAddress
                ? "border-red"
                : "border-default-color"
            } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
          />
        </div>

        <Select
          label={t("country")}
          placeholder={t("placeholderSelect", {
            field: t("country").toLowerCase(),
          })}
          name={"country"}
          containerClassName="flex flex-1 flex-col gap-[10px] min-w-[250px] sm-max:w-full"
          value={
            watch("country")
              ? countriesOptions.find((elem) => elem.name === watch("country"))
              : null
          }
          onChange={(e) => {
            setValue("country", e.label);
          }}
          isRequired={true}
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("country") }),
          }}
          errorMessage={errors.country ? errors.country.message : ""}
          options={countriesOptions}
        />

        <Input
          label={t("phoneNumber")}
          isRequired={true}
          placeholder={t("phonePlaceholder")}
          name="phoneNumber"
          type="text"
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("phoneNumber") }),
          }}
          errorMessage={errors.phoneNumber ? errors.phoneNumber.message : ""}
          containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.phoneNumber ? "border-red" : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />

        <Input
          label={t("homeTelephone")}
          isRequired={true}
          placeholder={t("phonePlaceholder")}
          name="homeTelephone"
          type="text"
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("homeTelephone") }),
          }}
          errorMessage={
            errors.homeTelephone ? errors.homeTelephone.message : ""
          }
          containerClassName="flex flex-col flex-1 sm-max:grid sm-max:w-full gap-[10px]"
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName={`flex h-[43px] min-w-[250px] sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.homeTelephone ? "border-red" : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />
      </div>
    </div>
  );
}
ContactDetailsForm.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  watch: PropTypes.func,
  getValues: PropTypes.func,
  title: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default ContactDetailsForm;
