import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import authApi from "api/auth";
import useNotify from "hooks/useNotify";
import i18n from "../../i18n";

function useSetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const { notifySuccess, notifyError } = useNotify();
  const onSubmit = async ({ password }) => {
    try {
      setIsLoading(true);
      await authApi.setPassword({
        password,
        token: params.token,
      });
      notifySuccess(i18n.t("passSetted"));
      navigate("/login");
    } catch (err) {
      console.error(err);
      notifyError(
        err?.data?.message ?? err?.message ?? "Something went wrong!"
      );
    } finally {
      setIsLoading(false);
    }
  };
  return { onSubmit, isLoading };
}

export default useSetPassword;
