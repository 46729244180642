import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Oval } from "react-loader-spinner";
import { FallingLines } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import { useLookupsContext } from "hooks/useContext";
import usePointer from "hooks/usePointer";
import Pointer from "components/pointer";

import { ReactComponent as ArrowRightIcon } from "images/auth/arrow-right.svg";

import SignupHeader from "./header";
import VendorForm from "./vendorForm";
import AgencyForm from "./agencyForm";
import useCountFilledFields from "hooks/useCountFilledFields";

function SignUpForm({
  onSubmit = () => {},
  isLoading = false,
  defaultsValues = {},
  isVendor = false,
}) {
  const [t] = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    unregister,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultsValues,
    reValidateMode: "all",
  });

  const {
    countries,
    cities,
    domains,
    nationalities,
    languages,
    services,
    timezones,
    getCities,
    getTimeZones,
    dataToBeLoaded,
    getSubDomains,
    subDomains,
  } = useLookupsContext(isVendor);

  /** i used "useWatch" to rerender the pointer! */
  const data = useWatch({
    control,
    name: [
      "certifications",
      "sampleOfWorks",
      "portfolio",
      "domains",
      "nationalities",
      "vendorAdditionalInfo",
      "rateCard",
      "subDomains",
    ],
  });

  const values = useWatch({
    control,
  });

  const getAllFields = (forum = {}, defaultsValues = {}) => {
    let results = {};
    for (const [key, value] of Object.entries(forum)) {
      if (defaultsValues.hasOwnProperty(key)) {
        results[key] = value;
      }
    }

    return results;
  };

  const { countFilledFields, calcFilledPercentages, handleAllFields } =
    useCountFilledFields(isVendor);

  const handleFields = handleAllFields(getAllFields(values, defaultsValues));

  const numberOfFilledFields = countFilledFields(
    handleFields,
    values["serviceRates"]
  );

  const extraPointsForService = 3;
  const totalFields = handleFields.length + extraPointsForService;

  const percentage = calcFilledPercentages(numberOfFilledFields, totalFields);

  const { startPointRef, startPoint, endPointRef, endPoint } = usePointer(
    dataToBeLoaded,
    data
  );

  const countryId = watch("country");
  const domainIds = watch("domains");

  useEffect(() => {
    if (!!countryId) {
      getCities(countryId);
      getTimeZones(countryId);
    }
  }, [countryId]);

  useEffect(() => {
    if (domainIds?.length > 0) {
      getSubDomains(domainIds);
    }
  }, [domainIds]);

  useEffect(() => {
    if (dataToBeLoaded) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [dataToBeLoaded]);

  if (!dataToBeLoaded) {
    return (
      <div className="flex items-center justify-center h-screen w-full">
        <FallingLines
          color="#023A66"
          width="100%"
          visible={true}
          ariaLabel="falling-lines-loading"
        />
      </div>
    );
  }

  return (
    <React.Fragment>
      <SignupHeader percentage={Math.floor(percentage)} />
      <form
        className="mt-[120px] px-[20%] xl-max:px-[10%] lg-max:px-[2rem] md-max:px-[1rem] flex flex-col my-[60px] relative"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Pointer startPoint={startPoint} endPoint={endPoint} />
        {isVendor ? (
          <VendorForm
            startPointRef={startPointRef}
            endPointRef={endPointRef}
            register={register}
            setValue={setValue}
            errors={errors}
            watch={watch}
            getValues={getValues}
            unregister={unregister}
            data={{
              countries,
              cities,
              domains,
              nationalities,
              services,
              timezones,
              languages,
              getSubDomains,
              subDomains,
            }}
          />
        ) : (
          <AgencyForm
            startPointRef={startPointRef}
            endPointRef={endPointRef}
            register={register}
            setValue={setValue}
            errors={errors}
            watch={watch}
            getValues={getValues}
            unregister={unregister}
            data={{
              countries,
              cities,
              domains,
              nationalities,
              services,
              timezones,
              languages,
              getSubDomains,
              subDomains,
            }}
          />
        )}

        <div className="max-w-[843px] sm-max:max-w-full">
          <button
            type="submit"
            className="flex p-[12px] justify-center ml-auto gap-[8px] rounded-[6px] bg-primary border-[1px] border-solid border-primary w-[110px] cursor-pointer"
            disabled={isLoading}
          >
            <Oval
              height={30}
              width={30}
              color="#fff"
              visible={isLoading}
              ariaLabel="oval-loading"
              secondaryColor="#fff"
              strokeWidth={5}
              strokeWidthSecondary={3}
            />

            <div className="text-white font-bold text-[16px] not-italic">
              {t("submit")}
            </div>
            <ArrowRightIcon />
          </button>
        </div>
      </form>
    </React.Fragment>
  );
}

SignUpForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  defaultsValues: PropTypes.object,
  isVendor: PropTypes.bool,
};

export default SignUpForm;
