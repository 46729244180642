import { useEffect, useRef, useState } from "react";
import useWindowDimensions from "hooks/useWindowDimensions";

function usePointer(isLoaded, data = []) {
  const startPointRef = useRef(null);
  const endPointRef = useRef(null);
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const { width } = useWindowDimensions();

  const [
    certifications,
    sampleOfWorks,
    portfolio,
    domains,
    nationalities,
    vendorAdditionalInfo,
  ] = data;

  const resetOnUnmount = () => {
    startPointRef.current = null;
    endPointRef.current = null;
    setStartPoint(null);
    setEndPoint(null);
  };

  useEffect(() => {
    setStartPoint(startPointRef.current?.getBoundingClientRect());
    setEndPoint(endPointRef.current?.getBoundingClientRect());
  }, [
    width,
    isLoaded,
    certifications,
    sampleOfWorks,
    portfolio,
    domains,
    nationalities,
    vendorAdditionalInfo,
  ]);

  useEffect(() => {
    return () => {
      resetOnUnmount();
    };
  }, []);

  return { startPointRef, startPoint, endPointRef, endPoint };
}

export default usePointer;
