import { useState, useEffect } from "react";
import { multiCVType } from "utils/constantValues";
import useNotify from "hooks/useNotify";
import profileApi from "api/profile";
import { scrollIntoView } from "utils/helper";

function useMultiCVs({ userDetails = {} }) {
  const currentRateCardIds =
    userDetails?.userFiles
      ?.filter((file) => file.type === multiCVType)
      ?.map((file) => file.id) ?? [];

  const { notifyError, notifySuccess } = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMultiCvs, setIsEditMultiCvs] = useState(false);

  const onEditMultiCvs = async (values, setUserData = () => {}) => {
    try {
      setIsLoading(true);
      const agencyId = userDetails?.agencyId;
      const { newCreated, deleted } = values;
      const deletedIds = deleted?.map((el) => el.fileId) ?? [];

      const payload = {
        newCreated:
          newCreated
            ?.filter(
              (el) =>
                !deletedIds.includes(el.fileId) &&
                !currentRateCardIds.includes(el.fileId)
            )
            ?.map((el) => el.fileId) ?? [],
        deleted: deletedIds,
        type: multiCVType,
      };

      await profileApi.updateAgencyFiles(agencyId, payload);
      const userData = await profileApi.getUserProfileByIdApi(userDetails?.id);
      setUserData(userData);
      setIsEditMultiCvs(false);
      notifySuccess("Has been updated successfully");
      scrollIntoView("multiCVsScrollId");
    } catch (err) {
      console.error(err);
      notifyError(err?.data?.error ?? err?.message ?? "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  const resetOnUnmount = () => {};
  useEffect(() => {
    return () => {
      resetOnUnmount();
    };
  }, []);

  return {
    isEditMultiCvs,
    setIsEditMultiCvs,
    onEditMultiCvs,
    isMultiCvsLoading: isLoading,
  };
}

export default useMultiCVs;
