import { useState, useEffect } from "react";
import useNotify from "hooks/useNotify";
import profileApi from "api/profile";
import { scrollIntoView } from "utils/helper";

function useEmergencyContact({ userDetails = {}, profileCompletionRef = {} }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditEmergencyContact, setIsEditEmergencyContact] = useState(false);

  profileCompletionRef.emergencyContact = {
    openEdit: setIsEditEmergencyContact,
    name: userDetails?.extraInformation?.emergencyContactDetails?.name ?? "",
    relationship:
      userDetails?.extraInformation?.emergencyContactDetails?.relationship ??
      "",
    country:
      userDetails?.extraInformation?.emergencyContactDetails?.country ?? "",
    mobileNumber:
      userDetails?.extraInformation?.emergencyContactDetails?.mobileNumber ??
      "",
    homeTelephone:
      userDetails?.extraInformation?.emergencyContactDetails?.homeTelephone ??
      "",
  };

  const { notifyError, notifySuccess } = useNotify();

  const onEditEmergencyContact = async (values, setUserData = () => {}) => {
    try {
      setIsLoading(true);

      const {
        name,
        relationship,
        email,
        emergencyCountry,
        emergencyPhoneNumber,
        emergencyHomeTelephone,
      } = values;

      const payload = {
        emergencyContacts: {
          name,
          relationship,
          email,
          country: emergencyCountry,
          mobileNumber: emergencyPhoneNumber,
          homeTelephone: emergencyHomeTelephone,
        },
      };

      await profileApi.updateEmergencyContact(userDetails?.vendorId, payload);
      setUserData((prevState) => {
        prevState.extraInformation.emergencyContactDetails = {
          name,
          relationship,
          email,
          country: emergencyCountry,
          mobileNumber: emergencyPhoneNumber,
          homeTelephone: emergencyHomeTelephone,
        };
        return prevState;
      });
      setIsEditEmergencyContact(false);
      notifySuccess("Has been updated successfully");
      scrollIntoView("emergencyContactScrollId");
    } catch (err) {
      console.error(err);
      notifyError(err?.data?.error ?? err?.message ?? "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  const resetOnUnmount = () => {};
  useEffect(() => {
    return () => {
      resetOnUnmount();
    };
  }, []);

  return {
    isEditEmergencyContact,
    setIsEditEmergencyContact,
    onEditEmergencyContact,
    isEmergencyContactLoading: isLoading,
  };
}

export default useEmergencyContact;
