import React from "react";
import PropTypes from "prop-types";
import LabelComponent from "pages/profile/common/label";
import { ReactComponent as EditIcon } from "images/profile/edit-icon.svg";
import { useTranslation } from "react-i18next";

function BankDetails({
  userDetails = {},
  onEditClick = () => {},
  isShowEditIcon = false,
}) {
  const [t] = useTranslation();

  return (
    <div className="flex p-[24px] flex-col gap-[30px] self-stretch  rounded-[8px] border-[1px] border-solid border-default-color mt-[10px]">
      <div className="grid grid-cols-custom-grid-label-icon items-center w-full">
        <div className="text-primary font-semibold text-[30px] not-italic">
          {t("financialInformation")}
        </div>
        {isShowEditIcon && (
          <EditIcon className="cursor-pointer" onClick={onEditClick} />
        )}
      </div>

      <div className="border-b-[1px] border-solid border-default-color " />

      <div className="text-primary font-semibold text-[22px] not-italic mb-[-15px]">
        {t("preferredCurrency")}
      </div>
      <div className="flex flex-wrap gap-[20px]">
        <LabelComponent
          title={""}
          subTitle={`${userDetails?.currency?.code ?? "-"}`}
        />
      </div>

      <div className="border-b-[1px] border-solid border-default-color " />

      <div className="text-primary font-semibold text-[22px] not-italic mb-[-15px]">
        {t("bankDetails")}
      </div>
      <div className="flex flex-wrap gap-[20px]">
        <LabelComponent
          title={t("companyName")}
          subTitle={`${userDetails?.bankDetails?.companyName ?? "-"}`}
        />

        <LabelComponent
          title={t("accountHolderName")}
          subTitle={`${userDetails?.bankDetails?.accountName ?? "-"}`}
        />

        <LabelComponent
          title={t("bankName")}
          subTitle={`${userDetails?.bankDetails?.bankName ?? "-"}`}
        />

        <LabelComponent
          title={t("accountNumber")}
          subTitle={`${userDetails?.bankDetails?.accountNumber ?? "-"}`}
        />

        <LabelComponent
          title={t("accountCurrency")}
          subTitle={`${userDetails?.bankDetails?.accountCurrency ?? "-"}`}
        />

        <LabelComponent
          title={t("swiftCode")}
          subTitle={`${userDetails?.bankDetails?.swiftCode ?? "-"}`}
        />
        <LabelComponent
          title={t("iban")}
          subTitle={`${userDetails?.bankDetails?.iban ?? "-"}`}
        />

        <LabelComponent
          title={t("bankAddress")}
          subTitle={`${userDetails?.bankDetails?.bankAddress ?? "-"}`}
        />

        <LabelComponent
          title={t("routingCode")}
          subTitle={`${userDetails?.bankDetails?.routingCode ?? "-"}`}
        />

        <LabelComponent
          title={t("sortCode")}
          subTitle={`${userDetails?.bankDetails?.sortCode ?? "-"}`}
        />
      </div>

      <div className="border-b-[1px] border-solid border-default-color " />
      <div className="text-primary font-semibold text-[22px] not-italic mb-[-15px]">
        {t("cashExpress")}
      </div>
      <div className="flex flex-wrap gap-[20px]">
        <LabelComponent
          title={t("fullName")}
          subTitle={`${userDetails?.bankDetails?.fullName ?? "-"}`}
        />

        <LabelComponent
          title={t("phoneNumber")}
          subTitle={`${
            userDetails?.bankDetails?.cashExpressPhoneNumber ?? "-"
          }`}
        />
      </div>

      <div className="border-b-[1px] border-solid border-default-color " />
      <div className="text-primary font-semibold text-[22px] not-italic mb-[-15px]">
        {t("paypal")}
      </div>
      <div className="flex flex-wrap gap-[20px]">
        <LabelComponent
          title={t("paypalLabel")}
          subTitle={`${userDetails?.bankDetails?.emailOrPhoneNumber ?? "-"}`}
        />
      </div>
    </div>
  );
}

BankDetails.propTypes = {
  userDetails: PropTypes.object,
  onEditClick: PropTypes.func,
  isShowEditIcon: PropTypes.bool,
};

export default BankDetails;
