import { useState, useEffect, useRef } from "react";
import { resumeType } from "utils/constantValues";
import useNotify from "hooks/useNotify";
import { useTranslation } from "react-i18next";
import profileApi from "api/profile";
import { scrollIntoView } from "utils/helper";

function useProfileResume({ userDetails = {}, profileCompletionRef = {} }) {
  const [t] = useTranslation();
  const { notifyError, notifySuccess } = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditProfileResume, setIsEditProfileResume] = useState(false);

  profileCompletionRef.resume = {
    openEdit: setIsEditProfileResume,
    resume:
      userDetails?.userFiles?.find((file) => file.type === resumeType)?.name ??
      "",
  };

  const onEditResume = async (values, setUserData = () => {}) => {
    try {
      setIsLoading(true);

      await profileApi.updateVendorResume(userDetails?.vendorId, {
        resumeId: values.resume.id,
      });

      const filteredResumeFile = userDetails?.userFiles?.filter(
        (file) => file.type !== resumeType
      );

      const oldResume = userDetails?.userFiles?.find(
        (file) => file.type === resumeType
      );
      if (values?.resume?.name) {
        setUserData((prevState) => ({
          ...prevState,
          userFiles: [
            ...filteredResumeFile,
            { ...values.resume, id: oldResume.id },
          ],
        }));
      } else {
        setUserData((prevState) => ({
          ...prevState,
          userFiles: [...filteredResumeFile],
        }));
      }

      setIsEditProfileResume(false);
      notifySuccess("Has been updated successfully");
      scrollIntoView("resumeScrollId");
    } catch (err) {
      console.error(err);
      notifyError(err?.data?.error ?? err?.message ?? "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  const resetOnUnmount = () => {};
  useEffect(() => {
    return () => {
      resetOnUnmount();
    };
  }, []);

  return {
    isEditProfileResume,
    setIsEditProfileResume,
    onEditResume,
    isResumeLoading: isLoading,
  };
}

export default useProfileResume;
