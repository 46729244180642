import { useState } from "react";
import profileApi from "api/profile";
import useNotify from "hooks/useNotify";
import { useAppContext, useLookupsContext } from "hooks/useContext";

function useVendorForm({ vendorId, setUserDetails = () => {} }) {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAppContext();
  const userId = user?.id;

  const { currencies = [] } = useLookupsContext(true);

  const { notifyError, notifySuccess } = useNotify();

  const defaultsValues = {
    //bankDetails
    preferredCurrency: currencies.find((elem) => elem.code === "USD")?.id ?? "",
    accountName: "",
    bankName: "",
    accountType: "",
    accountNumber: "",
    swiftCode: "",
    sortCode: "",
    iban: "",
    paypal: "",
    fullName: "",
    cashExpressPhoneNumber: "",

    //contacts
    permanentAddress: "",
    currentResidenceAddress: "",
    country: "",
    phoneNumber: "",
    homeTelephone: "",

    //emergencyContacts
    name: "",
    relationship: "",
    email: "",
    emergencyCountry: "",
    emergencyHomeTelephone: "",
    emergencyPhoneNumber: "",

    //personalDetails
    gender: "",
    dateOfBirth: "",
    countryOfBirth: "",
    placeOfBirth: "",
    martialStatus: "",
    nationalCardNo: "",
    socialSecurityNo: "",
    passportNo: "",
    expiryDate: "",
    placeOfIssue: "",
    countryOfIssue: "",

    //qualifications
    university: "",
    qualifications: "",
    major: "",
  };
  const onSubmit = async ({
    //bankDetails
    preferredCurrency,
    accountName,
    bankName,
    accountType,
    accountNumber,
    swiftCode,
    sortCode,
    iban,
    paypal,
    fullName,
    cashExpressPhoneNumber,

    permanentAddress,
    currentResidenceAddress,
    country,
    phoneNumber,
    homeTelephone,
    name,
    relationship,
    email,
    emergencyCountry,
    emergencyPhoneNumber,
    emergencyHomeTelephone,

    gender,
    dateOfBirth,
    countryOfBirth,
    placeOfBirth,
    martialStatus,
    nationalCardNo,
    socialSecurityNo,
    passportNo,
    expiryDate,
    placeOfIssue,
    countryOfIssue,

    university,
    qualification,
    major,
  }) => {
    setIsLoading(true);
    const bankDetails = {
      accountName,
      bankName,
      accountType,
      accountNumber,
      swiftCode,
      sortCode,
      iban,
    };

    const paypalDetails = {
      emailOrPhoneNumber: paypal,
    };

    const cashExpress = {
      fullName,
      cashExpressPhoneNumber: cashExpressPhoneNumber,
    };
    const contacts = {
      permanentAddress,
      currentResidenceAddress,
      country,
      phoneNumber,
      homeTelephone,
    };

    const emergencyContacts = {
      name,
      relationship,
      email,
      country: emergencyCountry,
      mobileNumber: emergencyPhoneNumber,
      homeTelephone: emergencyHomeTelephone,
    };

    const personalDetails = {
      gender,
      dateOfBirth,
      countryOfBirth,
      placeOfBirth,
      martialStatus,
      nationalCardNo,
      socialSecurityNo,
      passportNo,
      expiryDate,
      placeOfIssue,
      countryOfIssue,
    };

    const qualifications = {
      university,
      qualification,
      major,
    };

    const data = {
      preferredCurrency,
      bankDetails,
      paypalDetails,
      cashExpress,
      contacts,
      emergencyContacts,
      personalDetails,
      qualifications,
    };

    try {
      await profileApi.updateAdditionalVendorInfo(vendorId, data);
      notifySuccess("Has been updated successfully");
      const userData = await profileApi.getUserProfileByIdApi(userId);
      setTimeout(() => {
        setUserDetails(userData);
        window.scrollTo(0, 0);
      }, 400);
    } catch (err) {
      console.error(err);
      notifyError(err?.data?.error ?? err?.message ?? "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };
  return { onSubmit, isLoading, defaultsValues };
}

export default useVendorForm;
