import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import LabelForm from "pages/signup/form/labelForm";
import UploadFile from "components/uploadFile";
import useUploadFiles from "pages/signup/hooks/useUploadFiles";
import { ReactComponent as AttachIcon } from "images/auth/attach.svg";
import Chips from "components/chips";
function Certifications({ setValue = () => {}, register = () => {} }) {
  const [t] = useTranslation();
  const {
    certificationsRef,
    uploadCertifications,
    isUploading,
    certificationFiles,
    removeCertificationFile,
  } = useUploadFiles({});

  return (
    <Fragment>
      <LabelForm
        label={t("certificationsTitle")}
        labelClassName="text-primary font-bold text-[24px] md-max:text-[18px] not-italic pt-[16px] bg-white z-[3]"
      />
      <div className="grid  gap-[12px] max-w-[843px] sm-max:max-w-full pt-[10px] px-[0px] pb-[30px]  z-[3] bg-white border-b-[1px] border-solid border-default-color">
        <UploadFile
          name={"certifications"}
          register={register}
          placeHolderLabel={t("uploadCertification")}
          placeHolderContainerClassName="flex p-[14px] justify-between items-center gap-[8px] self-stretch rounded-[6px] h-[54px] w-full border-[1px] border-dashed border-dark-gray cursor-pointer"
          icon={<AttachIcon />}
          isUploading={isUploading.certifications}
          isMultiFile={true}
          getFile={(e, isDrag) => uploadCertifications(e, isDrag, setValue)}
          labelClassName="text-primary font-semibold text-[16px]"
          ref={certificationsRef}
        />
        <Chips
          chips={certificationFiles.map((file) => file.name)}
          onClick={(chipLabel, index) =>
            removeCertificationFile(chipLabel, setValue)
          }
        />
      </div>
    </Fragment>
  );
}

Certifications.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.object,
  watch: PropTypes.func,
  getValues: PropTypes.func,
};

export default Certifications;
