export const footer = {
  titleText1: "Tarjama Fz. LLC",
  titleText2:
    "Our passion for both language and technology inspires us to design intelligent tools, products, and services that enable businesses to interact better and faster with their global audiences. We combine AI and human talent to deliver a full suite of language solutions including translation, localization, interpretation, content creation, transcription, subtitling and strategic advisory. With over a decade of expertise, a network of over 35,000 linguists, and dedicated AI teams, we are committed to bringing the world closer together.",
  secondTitle1: "Take a sneak peek at",
  secondTitle2: "our line of business",
  companyTitle: "Company",
  aboutus: "About us",
  ourTechnology: "Our technology",
  contactus: "Contact us",
  privacyPolicy: "Privacy policy",
  termsAndConditions: "Terms & Conditions",
  careers: "Careers",
  workWithUs: "Work with us",
  resources: "Resources",
  blog: "Blog",
  caseStudies: "Case studies",
  helpCenter: "Help center",
  socialMedia: "Social media",
  linkedIn: "Linkedin",
  facebook: "Facebook",
  twitter: "Twitter",
  instagram: "Instagram",
  youtube: "Youtube",
};
