import { useState } from "react";

function useAgencyContacts({ defaultContacts }) {
  const initValues = { name: "", department: "", position: "", email: "" };

  const [agencyContacts, setAgencyContacts] = useState(
    defaultContacts && defaultContacts?.length > 0
      ? defaultContacts
      : [{ ...initValues }]
  );

  const onHandleAgencyContacts = (
    key,
    value,
    index = 0,
    setValue = () => {}
  ) => {
    const currentValue = agencyContacts[index];
    const newValue = { ...currentValue, [key]: value };
    const updateAgencyContacts = agencyContacts.map((contact, i) => {
      if (i === index) {
        return newValue;
      }
      return contact;
    });

    setValue(`${key}-${index}`, value);
    setValue("contacts", updateAgencyContacts);
    setAgencyContacts(updateAgencyContacts);
  };

  const addNewSection = () => {
    setAgencyContacts((prevState) => [...prevState, { ...initValues }]);
  };

  const deleteSection = (index, setValue = () => {}) => {
    const updateAgencyContacts = agencyContacts.filter((_, i) => {
      return i !== index;
    });

    setValue("contacts", updateAgencyContacts);
    setAgencyContacts(updateAgencyContacts);
  };

  return {
    agencyContacts,
    onHandleAgencyContacts,
    addNewSection,
    deleteSection,
  };
}

export default useAgencyContacts;
