import React from "react";
import PropTypes from "prop-types";

export default function TruncatedOption({ label }) {
  return (
    <div title={label} className="truncate">
      {label}
    </div>
  );
}

TruncatedOption.propTypes = {
  label: PropTypes.string,
};
