import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import TruncatedOption from "components/truncatedOption";
import { customStyle } from "styles/reactSelectStyle";

function SelectComponent({
  label = "",
  isRequired = false,
  errorMessage = "",
  name = "",
  register = () => {},
  registerArgs = {},
  containerClassName = "",
  labelClassName = "",
  maxMenuHeight = "8rem",
  isDisabled = false,
  options = [],
  onChange = () => {},
  isMulti = false,
  value = null,
  placeholder = "Search...",
  closeMenuOnSelect = true,
  showError = false,
  isClearable = false,
}) {
  return (
    <div
      className={
        containerClassName ? containerClassName : "flex flex-col gap-[10px]"
      }
    >
      {label && (
        <div
          className={
            labelClassName
              ? labelClassName
              : "text-primary font-semibold text-[16px]"
          }
        >
          {label} {isRequired && <span className="text-red">*</span>}
        </div>
      )}
      <div
        className={`grid w-full ${
          errorMessage &&
          isRequired &&
          (!value || showError || (Array.isArray(value) && value.length <= 0))
            ? "border-red"
            : "border-default-color"
        } border-solid border-[1px] rounded-[4px]`}
      >
        <Select
          name={name}
          {...register(name, { ...registerArgs })}
          value={value ? value : isMulti ? [] : ""}
          maxMenuHeight={maxMenuHeight}
          placeholder={placeholder}
          isClearable={isClearable}
          isDisabled={isDisabled}
          options={options}
          isOptionDisabled={(option) => option.isDisabled}
          onChange={(e) => {
            const event = !Array.isArray(e) && !e ? "" : e;
            // eslint-disable-next-line no-unused-expressions
            typeof onChange === "function" ? onChange(event) : null;
          }}
          isMulti={isMulti}
          closeMenuOnSelect={closeMenuOnSelect}
          formatOptionLabel={({ label }) => <TruncatedOption label={label} />}
          styles={customStyle}
        />
      </div>
      {errorMessage &&
        isRequired &&
        (!value ||
          showError ||
          (Array.isArray(value) && value.length <= 0)) && (
          <span className="text-[12px] text-red font-regular">
            {errorMessage}
          </span>
        )}
    </div>
  );
}

SelectComponent.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  register: PropTypes.func,
  registerArgs: PropTypes.object,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  maxMenuHeight: PropTypes.string,
  isDisabled: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func,
  selectClassName: PropTypes.string,
  isMulti: PropTypes.bool,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  closeMenuOnSelect: PropTypes.bool,
  showError: PropTypes.bool,
  isClearable: PropTypes.bool,
};

export default SelectComponent;
