import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ReactComponent as TrashIcon } from "images/auth/trash.svg";

import { ThreeCircles } from "react-loader-spinner";

function UploadFile(
  {
    label = "",
    icon,
    placeHolderLabel,
    isUploading = false,
    isRequired = false,
    isMultiFile = false,
    getFile = () => {},
    errorMessage = "",
    name = "",
    register = () => {},
    registerArgs = {},
    containerClassName = "",
    labelClassName = "",
    placeHolderContainerClassName = "",
    placeHolderLabelClassName = "",
    fileName = "",
    onRemoveFile = () => {},
    files = [],
    accept = "",
  },
  ref
) {
  let acceptProps = accept ? { accept } : {};
  useEffect(() => {
    // Assign the ref once the input element is rendered
    ref.current = document.getElementById(`uploadFile-${name}`);
  });

  return (
    <div
      className={
        containerClassName ? containerClassName : "flex flex-col gap-[10px]"
      }
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDrop={(e) => {
        e.preventDefault();
        getFile(e, true);
      }}
    >
      {label && (
        <div
          className={
            labelClassName
              ? labelClassName
              : "text-white font-semibold text-[16px] not-italic font-normal"
          }
        >
          {label} {isRequired && <span className="text-red">*</span>}
        </div>
      )}

      <div
        className={
          placeHolderContainerClassName
            ? placeHolderContainerClassName
            : "flex p-[14px] justify-between items-center gap-[8px] self-stretch rounded-[6px] h-[43px] w-[190px] sm-max:w-full border-[1px] border-dashed border-dark-gray cursor-pointer"
        }
      >
        {placeHolderLabel && !isUploading && (
          <div
            className={
              placeHolderLabelClassName
                ? placeHolderLabelClassName
                : "text-dark-gray text-[16px] font-regular not-italic w-full"
            }
            onClick={() => {
              ref.current?.click?.();
            }}
          >
            {fileName ? fileName : placeHolderLabel}
          </div>
        )}

        <ThreeCircles
          height={30}
          width={30}
          color="#023A66"
          visible={isUploading}
          ariaLabel="oval-loading"
          secondaryColor="#023A66"
          strokeWidth={5}
          strokeWidthSecondary={3}
        />

        {icon && !fileName && (
          <div
            className="ml-auto"
            onClick={() => {
              ref.current?.click?.();
            }}
          >
            {icon}
          </div>
        )}
        {fileName && !isMultiFile && !isUploading && (
          <div
            className="ml-auto"
            onClick={() => {
              onRemoveFile(fileName);
            }}
          >
            <TrashIcon />
          </div>
        )}

        <input
          ref={ref}
          name={name}
          id={`uploadFile-${name}`}
          {...acceptProps}
          {...register(name, { ...registerArgs })}
          onInput={(e) => {
            const isDrag = false;
            getFile(e, isDrag);
          }}
          disabled={isUploading}
          type="file"
          multiple={isMultiFile}
          className="invisible absolute z-[-1]"
        />
      </div>

      {errorMessage &&
        isRequired &&
        ((!fileName && !isMultiFile) || (files.length <= 0 && isMultiFile)) && (
          <span className="text-[12px] text-red font-regular">
            {errorMessage}
          </span>
        )}
    </div>
  );
}

UploadFile.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.any,
  placeHolderLabel: PropTypes.string,
  isUploading: PropTypes.bool,
  isRequired: PropTypes.bool,
  isMultiFile: PropTypes.bool,
  getFile: PropTypes.func,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  register: PropTypes.func,
  registerArgs: PropTypes.object,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  placeHolderContainerClassName: PropTypes.string,
  placeHolderLabelClassName: PropTypes.string,
  fileName: PropTypes.string,
  onRemoveFile: PropTypes.func,
  files: PropTypes.array,
  accept: PropTypes.string,
};

export default React.forwardRef(UploadFile);
