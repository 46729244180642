import React from "react";
import { useForm } from "react-hook-form";
import { Oval } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowLeft } from "images/profile/arrow-left.svg";
import { ReactComponent as EyeIcon } from "images/profile/eye.svg";
import { ReactComponent as EyeSlashIcon } from "images/profile/eye-slash.svg";
import { ReactComponent as CheckDone } from "images/profile/check-done.svg";
import InputComponent from "components/input";
import useScrollToTop from "hooks/useScrollToTop";
import useUpdatePassword from "./useUpdatePassword";
import { useAppContext } from "hooks/useContext";
import { useNavigate } from "react-router-dom";

const UpdatePassword = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { user } = useAppContext();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const { onSubmit, isLoading, isPasswordChanged, isEyeOpen, setIsEyeOpen } =
    useUpdatePassword(user?.id, reset);

  useScrollToTop();

  return (
    <div className="grid w-full h-full py-[60px] sm-max:py-[24px] px-[120px] sm-max:px-[2rem]">
      <button
        type="button"
        className="flex py-[14px] px-[16px] gap-[8px] items-center"
        onClick={() => navigate("/")}
      >
        <ArrowLeft />
        <div className="text-gray-500 font-bold text-[16px] not-italic cursor-pointer">
          {t("backToProfile")}
        </div>
      </button>

      {isPasswordChanged ? (
        <div className="w-full md-max:max-w-full h-full flex flex-col gap-[20px] items-center justify-center py-[100px]">
          <CheckDone className="mx-auto" />

          <div className="text-base-black text-center font-bold text-[30px] not-italic">
            {t("passwordChanged")}
          </div>
          <div className="text-gray-500 text-center font-medium text-[16px] not-italic">
            {t("passwordChangedSuccess")}
          </div>
          <button
            type="button"
            onClick={() => navigate("/")}
            className="flex py-[18px] px-[20px] max-w-[418px] justify-center items-center gap-[8px] rounded-[6px] border-[1px] border-solid border-primary bg-primary h-[48px] w-full"
          >
            <div className="text-white font-bold text-[18px] not-italic">
              {t("goToMyProfile")}
            </div>
          </button>
        </div>
      ) : (
        <form
          className="w-full h-full  flex-1  min-h-[calc(100%-50px)] flex items-center justify-center md-max:justify-start"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-full max-w-[418px] md-max:max-w-full h-full flex-shrink-0 flex items-center justify-center">
            <div className="grid w-full items-start gap-[20px] w-full">
              <div className="grid w-full items-start gap-[10px]">
                <div className="text-base-black text-center font-bold text-[30px] not-italic">
                  {t("changePasswordTitle")}
                </div>
                <div className="text-gray-500 text-center font-medium text-[16px] not-italic">
                  {t("changedPasswordSubTitle")}
                </div>
              </div>

              <div className="flex flex-col gap-[18px] self-stretch">
                <div className="grid relative w-full">
                  <InputComponent
                    label={t("currentPassword")}
                    isRequired={true}
                    type={isEyeOpen.oldPassword ? "text" : "password"}
                    name="currentPassword"
                    placeholder={t("currentPasswordPlaceHolder")}
                    labelClassName="text-base-black font-semibold text-[16px] not-italic"
                    inputClassName="flex h-[43px] items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid border-default-color bg-transparent outline-none text-base-black font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic py-[16px] px-[14px]"
                    errorMessage={
                      errors.currentPassword
                        ? errors.currentPassword.message
                        : ""
                    }
                    register={register}
                    registerArgs={{
                      required: t("errorField", {
                        field: t("currentPassword"),
                      }),
                      minLength: {
                        value: 8,
                        message: t("errorMinCharacter", {
                          field: t("currentPassword"),
                          value: 8,
                        }),
                      },
                    }}
                  />
                  <div
                    className="absolute top-[47px] right-[30px] z-[10] cursor-pointer"
                    onClick={() => {
                      setIsEyeOpen((prevState) => ({
                        ...prevState,
                        oldPassword: !prevState.oldPassword,
                      }));
                    }}
                  >
                    {isEyeOpen.oldPassword ? <EyeSlashIcon /> : <EyeIcon />}
                  </div>
                </div>

                <div className="grid relative w-full">
                  <InputComponent
                    label={t("newPasswordTitle")}
                    isRequired={true}
                    type={isEyeOpen.newPassword ? "text" : "password"}
                    name="newPassword"
                    placeholder={t("newPasswordPlaceholder")}
                    labelClassName="text-base-black font-semibold text-[16px] not-italic"
                    inputClassName="flex h-[43px] items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid border-default-color bg-transparent outline-none text-base-black font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic py-[16px] px-[14px]"
                    errorMessage={
                      errors.newPassword ? errors.newPassword.message : ""
                    }
                    register={register}
                    registerArgs={{
                      required: t("errorField", {
                        field: t("newPasswordTitle"),
                      }),
                      minLength: {
                        value: 8,
                        message: t("errorMinCharacter", {
                          field: t("newPasswordTitle"),
                          value: 8,
                        }),
                      },
                    }}
                  />
                  <div
                    className="absolute top-[47px] right-[30px] z-[10] cursor-pointer"
                    onClick={() => {
                      setIsEyeOpen((prevState) => ({
                        ...prevState,
                        newPassword: !prevState.newPassword,
                      }));
                    }}
                  >
                    {isEyeOpen.newPassword ? <EyeSlashIcon /> : <EyeIcon />}
                  </div>
                </div>

                <div className="grid relative w-full">
                  <InputComponent
                    label={t("confirmPassword")}
                    isRequired={true}
                    type={isEyeOpen.confirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    placeholder={t("confirmPasswordPlaceholder")}
                    labelClassName="text-base-black font-semibold text-[16px] not-italic"
                    inputClassName="flex h-[43px] items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid border-default-color bg-transparent outline-none text-base-black font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic py-[16px] px-[14px]"
                    errorMessage={
                      errors.confirmPassword
                        ? errors.confirmPassword.message
                        : ""
                    }
                    register={register}
                    registerArgs={{
                      required: t("errorField", {
                        field: t("confirmPassword"),
                      }),
                      validate: (val) => {
                        if (watch("newPassword") !== val) {
                          return t("confirmPassErrorMsg");
                        }
                      },
                    }}
                  />
                  <div
                    className="absolute top-[47px] right-[30px] z-[10] cursor-pointer"
                    onClick={() => {
                      setIsEyeOpen((prevState) => ({
                        ...prevState,
                        confirmPassword: !prevState.confirmPassword,
                      }));
                    }}
                  >
                    {isEyeOpen.confirmPassword ? <EyeSlashIcon /> : <EyeIcon />}
                  </div>
                </div>

                <button
                  className="flex py-[18px] px-[20px] justify-center items-center gap-[8px] rounded-[6px] border-[1px] border-solid border-primary bg-primary h-[48px]"
                  type="submit"
                  disabled={isLoading}
                >
                  <Oval
                    height={30}
                    width={30}
                    color="#023A66"
                    visible={isLoading}
                    ariaLabel="oval-loading"
                    secondaryColor="#023A66"
                    strokeWidth={5}
                    strokeWidthSecondary={3}
                  />
                  <div className="text-white font-bold text-[18px] not-italic">
                    Save new password
                  </div>
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default UpdatePassword;
