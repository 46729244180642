import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import LabelForm from "pages/signup/form/labelForm";
import { useTranslation } from "react-i18next";
import Select from "components/select";
import Input from "components/input";
import UploadFile from "components/uploadFile";
import useUploadFiles from "pages/signup/hooks/useUploadFiles";
import { ReactComponent as AttachIcon } from "images/auth/attach.svg";
import Chips from "components/chips";

function Experience({
  data = {},
  watch = () => {},
  setValue = () => {},
  register = () => {},
}) {
  const [t] = useTranslation();

  const {
    isUploading,
    rateCardRef,
    uploadRateCard,
    rateCardFiles,
    removeRateCardFile,
    portfolioRef,
    portfolioFiles,
    uploadPortfolio,
    removePortfolioFile,
  } = useUploadFiles({});

  return (
    <Fragment>
      <LabelForm
        label={t("experienceTitle")}
        labelClassName="text-primary font-bold text-[24px] md-max:text-[18px] not-italic pt-[16px] bg-white z-[3]"
      />

      <div className="grid grid-cols-1 gap-[16px] max-w-[843px] sm-max:max-w-full pt-[10px] px-[0px] pb-[30px]  z-[3] bg-white border-b-[1px] border-solid border-default-color">
        <div className="grid grid-cols-2 md-max:grid-cols-1 gap-[16px] max-w-[843px] sm-max:max-w-full">
          <div className="flex flex-col gap-[12px]">
            <UploadFile
              name={"portfolio"}
              register={register}
              label={t("portfolio")}
              isMultiFile={true}
              placeHolderLabel={t("attachYourFiles")}
              placeHolderContainerClassName="flex p-[14px] justify-between items-center gap-[8px] self-stretch rounded-[6px] h-[54px] w-full border-[1px] border-dashed border-dark-gray cursor-pointer"
              icon={<AttachIcon />}
              isUploading={isUploading.portfolio}
              getFile={(e, isDrag) => uploadPortfolio(e, isDrag, setValue)}
              labelClassName="text-primary font-semibold text-[16px]"
              ref={portfolioRef}
            />
            <Chips
              chips={portfolioFiles.map((file) => file.name)}
              onClick={(chipLabel, index) =>
                removePortfolioFile(chipLabel, setValue)
              }
            />
          </div>
          <div className="flex flex-col gap-[12px]">
            <UploadFile
              name={"rateCard"}
              register={register}
              label={t("rateCard")}
              placeHolderLabel={t("attachYourFile")}
              icon={<AttachIcon />}
              isUploading={isUploading.rateCard}
              getFile={(e, isDrag) => uploadRateCard(e, isDrag, setValue)}
              placeHolderContainerClassName="flex p-[14px] justify-between items-center gap-[8px] self-stretch rounded-[6px] h-[54px] w-full border-[1px] border-dashed border-dark-gray cursor-pointer"
              labelClassName="text-primary font-semibold text-[16px]"
              ref={rateCardRef}
              isMultiFile={true}
            />

            <Chips
              chips={rateCardFiles.map((file) => file.name)}
              onClick={(chipLabel, index) =>
                removeRateCardFile(chipLabel, setValue)
              }
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

Experience.propTypes = {
  label: PropTypes.string,
  register: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  watch: PropTypes.func,
  data: PropTypes.object,
  getValues: PropTypes.func,
};

export default Experience;
