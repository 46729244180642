import React, { Fragment, forwardRef } from "react";
import PropTypes from "prop-types";
import LabelForm from "pages/signup/form/labelForm";
import { useTranslation } from "react-i18next";
import Input from "components/input";
import { isValidEmail, validateEmail } from "utils/helper";

function StartJourney(
  { register = () => {}, errors = {}, setValue = () => {}, watch = () => {} },
  ref
) {
  const [t] = useTranslation();

  return (
    <Fragment>
      <LabelForm
        label={t("startJourneyLabel")}
        ref={ref}
        labelClassName="text-primary font-bold text-[24px] md-max:text-[18px] not-italic mb-[50px]"
      />

      <div className="grid grid-cols-3 md-max:grid-cols-1 gap-[16px] max-w-[843px] sm-max:max-w-full pt-[10px] px-[0px] pb-[30px]  z-[3] bg-white border-b-[1px] border-solid border-default-color">
        <Input
          label={t("companyName")}
          isRequired={true}
          placeholder={t("placeHolderType", {
            title: t("companyName").toLowerCase(),
          })}
          name="companyName"
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("companyName") }),
          }}
          errorMessage={
            errors.companyName && !watch("companyName")
              ? errors.companyName.message
              : ""
          }
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName={`flex h-[43px] w-full sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.companyName && !watch("companyName")
              ? "border-red"
              : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />

        <Input
          label={t("representativeName")}
          isRequired={true}
          placeholder={t("placeHolderType", {
            title: t("representativeName").toLowerCase(),
          })}
          name="representativeName"
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("representativeName") }),
          }}
          errorMessage={
            errors.representativeName && !watch("representativeName")
              ? errors.representativeName.message
              : ""
          }
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName={`flex h-[43px] w-full sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.representativeName && !watch("representativeName")
              ? "border-red"
              : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />

        <Input
          label={t("emailTitle")}
          isRequired={true}
          type="email"
          name="email"
          placeholder={t("placeholder", {
            field: t("emailTitle").toLowerCase(),
          })}
          errorMessage={
            errors.email && !isValidEmail(watch("email"))
              ? watch("email") && !isValidEmail(watch("email"))
                ? t("invalidEmail")
                : t("errorField", { field: t("emailTitle") })
              : ""
          }
          register={register}
          registerArgs={{
            required: t("errorField", { field: t("emailTitle") }),
            validate: validateEmail,
          }}
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName={`flex h-[43px] w-full sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid ${
            errors.email && !isValidEmail(watch("email"))
              ? "border-red"
              : "border-default-color"
          } bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px]`}
        />
        <Input
          label={t("companyWebsite")}
          placeholder={t("companyWebsitePlaceholder")}
          name="companyWebsite"
          register={register}
          labelClassName="text-primary font-semibold text-[16px]"
          inputClassName="flex h-[43px] w-full sm-max:w-full items-center gap-[8px] self-stretch rounded-[6px] border-[1px] border-solid border-default-color bg-transparent outline-none text-dark-gray font-regular text-[16px] not-italic  placeholder:font-regular placeholder:text-[16px] placeholder:not-italic placeholder:font-normal py-[16px] px-[14px] "
        />
      </div>
    </Fragment>
  );
}

StartJourney.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  watch: PropTypes.func,
};

export default forwardRef(StartJourney);
